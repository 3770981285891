interface NumballProps {
  count: number;
  alternateBackground?: boolean;
  testID?: string;
}

const Numball: React.FC<NumballProps> = ({
  count,
  alternateBackground = false,
  testID = 'numball',
}) => (
  <div
    className={`flex flex-col h-[25px] items-center justify-center px-2 ${
      alternateBackground ? 'bg-gray-opacity-15' : 'bg-clc-blue'
    } bg-clc-blue rounded-[5px]`}
    data-testid={testID}
  >
    <div
      className={`flex items-center text-xs leading-[15px] font-semibold ${
        alternateBackground ? 'text-dark-gray' : 'text-white'
      }`}
    >
      {count}
    </div>
  </div>
);

export default Numball;
