import { ReactComponent as BodyBackSvg } from 'assets/images/human-body/body-back.svg';
import { ReactComponent as BodyFrontSvg } from 'assets/images/human-body/body-front.svg';
import { ReactComponent as HeadFrontSvg } from 'assets/images/human-body/head-front.svg';
import { ReactComponent as HeadBackSvg } from 'assets/images/human-body/head-back.svg';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dot, SvgImageTag } from './interfaces';

interface BodyDotPlacerProps {
  type: SvgImageTag;
  dots: Dot[];
  hideNotSelectedDots?: boolean;
  onDotSelected: (id: number) => void;
}

const SvgFabric = {
  [SvgImageTag.BODY_FRONT_SVG]: <BodyFrontSvg />,
  [SvgImageTag.BODY_BACK_SVG]: <BodyBackSvg />,
  [SvgImageTag.HEAD_FRONT_SVG]: <HeadFrontSvg />,
  [SvgImageTag.HEAD_BACK_SVG]: <HeadBackSvg />,
};

const ORIGINAL_HEAD_IMAGE_SVG_WIDTH = 120;
const ORIGINAL_BODY_IMAGE_SVG_WIDTH = 230;
const RADIX = 10;
const DOT_WIDTH_FACTOR = 2;

const widthBySvg = {
  [SvgImageTag.BODY_FRONT_SVG]: ORIGINAL_BODY_IMAGE_SVG_WIDTH,
  [SvgImageTag.BODY_BACK_SVG]: ORIGINAL_BODY_IMAGE_SVG_WIDTH,
  [SvgImageTag.HEAD_FRONT_SVG]: ORIGINAL_HEAD_IMAGE_SVG_WIDTH,
  [SvgImageTag.HEAD_BACK_SVG]: ORIGINAL_HEAD_IMAGE_SVG_WIDTH,
};

export const DotPlacer: React.FC<BodyDotPlacerProps> = ({
  type,
  dots,
  hideNotSelectedDots = false,
  onDotSelected,
}) => {
  const [scalingFactor, setScalingFactor] = useState(1);
  const svgContainerRef = useRef<HTMLDivElement>(null);

  const getOriginalSvgWidth = useMemo(
    () => widthBySvg[type] || ORIGINAL_BODY_IMAGE_SVG_WIDTH,
    [type],
  );

  const getDotXPosition = (dot: Dot) => {
    if (
      type === SvgImageTag.HEAD_FRONT_SVG ||
      type === SvgImageTag.HEAD_BACK_SVG
    ) {
      return dot.headPoints?.x ?? '0';
    }
    return dot.bodyPoints?.x ?? '0';
  };

  const getDotYPosition = (dot: Dot) => {
    if (
      type === SvgImageTag.HEAD_FRONT_SVG ||
      type === SvgImageTag.HEAD_BACK_SVG
    ) {
      return dot.headPoints?.y ?? '0';
    }
    return dot.bodyPoints?.y ?? '0';
  };

  useEffect(() => {
    const svgElement = svgContainerRef.current?.querySelector('svg');

    const updateScalingFactor = () => {
      if (svgElement) {
        const svgRect = svgElement.getBoundingClientRect();
        setScalingFactor(svgRect.width / getOriginalSvgWidth);
      }
    };

    const resizeObserver = new ResizeObserver(updateScalingFactor);
    if (svgElement) {
      resizeObserver.observe(svgElement);
    }

    return () => {
      if (svgElement) {
        resizeObserver.unobserve(svgElement);
      }
    };
  }, [getOriginalSvgWidth]);

  const calculatePosition = (value: string) =>
    `${(parseInt(value, RADIX) + DOT_WIDTH_FACTOR) * scalingFactor}px`;

  const toggleDot = (id: number): void => {
    onDotSelected(id);
  };

  return (
    <div ref={svgContainerRef} className="relative h-auto mt-3 self-center">
      {SvgFabric[type]}
      {dots.map((dot) => {
        if (hideNotSelectedDots && !dot.selected) return null;
        return (
          <div
            key={`${dot.id}-${type}`}
            className={`absolute w-1.5 h-1.5 rounded-full cursor-pointer ${
              dot.selected ? 'bg-orange-body-dot' : 'bg-blue-body-dot'
            }`}
            style={{
              left: calculatePosition(getDotXPosition(dot)),
              top: calculatePosition(getDotYPosition(dot)),
            }}
            onClick={() => toggleDot(dot.id)}
            data-testid={`body-dot-${dot.strapiKey}`}
          />
        );
      })}
    </div>
  );
};

export default DotPlacer;
