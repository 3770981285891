import { useEffect } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/iconClose.svg';
import { WarningIcon } from 'assets/icons/WarningIcon';
import { useIsMobile } from 'hooks/useIsMobile';
import { ModalButtonProps } from './modal.interfaces';
import ButtonComponent from '../button/buttonComponent';
import IconComponent from 'components/iconComponent';
import { twMerge } from 'tailwind-merge';
import { MODAL_CONTENT_TO_DOWNLOAD_ID } from 'utilities/constants';

interface ModalProps {
  icon?: React.ReactNode;
  title?: string;
  buttons?: ModalButtonProps[];
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  disableMobileGrow?: boolean;
  contentPaddingX?: string;
  contentPaddingY?: string;
  modalDesktopWidth?: string;
  modalDesktopHeight?: string;
  contentContainerGap?: string;
  type?: 'default' | 'warning' | 'warning-with-header';
  disableWebClose?: boolean;
  disableMobileClose?: boolean;
  titleContainerMinHeight?: string;
  enableOverlapOfModals?: boolean;
  leftModalComponent?: JSX.Element;
  contentContainerDefaultBackground?: string;
  contentContainerDefaultBorder?: string;
  disableOverflow?: boolean;
  modalContainerCustomClass?: string;
  titleCustomClass?: string;
  mobileRowButtons?: boolean;
  hFull?: boolean;
  closeIconColor?: string;
  customContentContainerClass?: string;
  testID?: string;
}

const Modal: React.FC<ModalProps> = ({
  icon,
  title,
  buttons,
  children,
  isOpen,
  onClose,
  disableEscapeKeyDown = false,
  disableBackdropClick = false,
  disableMobileGrow = false,
  contentPaddingX = 'px-[38px]',
  contentPaddingY = 'py-10',
  modalDesktopWidth = 'desktop:max-w-[960px]',
  modalDesktopHeight = 'desktop:h-[auto]',
  contentContainerGap = 'gap-[30px]',
  type = 'default',
  disableWebClose = false,
  disableMobileClose = false,
  titleContainerMinHeight = 'desktop:min-h-[77px]',
  enableOverlapOfModals = false,
  leftModalComponent = null,
  contentContainerDefaultBackground = 'bg-base-content',
  contentContainerDefaultBorder = '',
  disableOverflow = false,
  modalContainerCustomClass = '',
  titleCustomClass = '',
  mobileRowButtons = false,
  hFull = false,
  closeIconColor = 'stroke-med-gray',
  customContentContainerClass = '',
  testID,
}) => {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const pointerClass = disableBackdropClick
    ? 'cursor-not-allowed'
    : 'cursor-pointer';

  const handleModalKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (disableEscapeKeyDown && event.key === 'Escape') {
      event.stopPropagation();
    }
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disableBackdropClick) {
      event.stopPropagation();
    }
  };

  const handleBackdropClickModified = (
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    const target = event.target as HTMLDivElement;

    if (target.id === 'backdrop' && onClose) {
      event.stopPropagation();
      onClose();
    }
  };

  let backDropClass;
  let modalContainerClass;
  let titleContainerClass;
  let titleClass;
  let contentContainerClass;
  let buttonsContainerClass;
  let textButtonsContainer;

  switch (type) {
    case 'warning':
      backDropClass = 'desktop:max-w-[480px]';
      modalContainerClass = 'bg-negative gap-5 py-5 desktop:rounded-b-lg';
      titleContainerClass = 'flex flex-col-reverse items-center gap-5';
      titleClass =
        'flex flex-row items-center font-bold text-clc-red px-5 gap-4 uppercase';
      contentContainerClass =
        'bg-negative font-medium text-clc-red items-center px-5 flex flex-col desktop:px-5 desktop:grow';
      'bg-negative font-medium text-clc-red items-center px-5 flex flex-col desktop:grow';
      buttonsContainerClass = 'px-0 py-0 justify-center w-40 mx-auto';
      textButtonsContainer =
        'flex flex-col desktop:flex-row-reverse px-[67.5px] desktop:px-[45px] gap-5 justify-between';
      break;
    case 'warning-with-header':
      backDropClass = 'desktop:max-w-[480px]';
      modalContainerClass = 'bg-negative gap-5 pb-5 desktop:rounded-b-lg';
      titleContainerClass = `bg-white justify-center flex min-h-[60px] ${titleContainerMinHeight}`;
      titleClass =
        'flex flex-row items-center font-bold text-clc-red px-5 gap-4 uppercase';
      contentContainerClass =
        'bg-negative font-medium text-clc-red items-center px-5 flex flex-col desktop:grow';
      buttonsContainerClass = 'px-0 py-0 justify-center w-40 mx-auto';
      textButtonsContainer =
        'flex flex-col px-[67.5px] gap-5 justify-between desktop:flex-row-reverse desktop:px-[45px]';
      break;
    default:
      backDropClass = `${modalDesktopWidth} desktop:rounded-lg`;
      modalContainerClass =
        'bg-white desktop:rounded-b-lg desktop:rounded-t-none';
      titleContainerClass = `flex min-h-[60px] ${titleContainerMinHeight}`;
      titleClass =
        'flex flex-row items-center text-h3 font-semibold text-[#262626] pl-[38px] pr-14 pt-[19px] pb-[10px] desktop:text-h2 desktop:pl-[45px] desktop:pr-[62px] desktop:pt-[23px] desktop:pb-4 gap-4 uppercase';
      contentContainerClass = `${contentContainerDefaultBackground} ${contentPaddingX} ${contentPaddingY} flex flex-col ${
        !disableMobileGrow && 'grow desktop:px-[45px]'
      } ${contentContainerGap} ${contentContainerDefaultBorder} ${customContentContainerClass}`;
      buttonsContainerClass = twMerge(
        `flex flex-col ${
          mobileRowButtons ? 'flex-row-reverse' : ''
        }  desktop:flex-row-reverse gap-5 desktop:gap-[30px]]`,
      );
      textButtonsContainer =
        'flex flex-col desktop:flex-row-reverse px-[67.5px] py-10 desktop:py-[17.5px] gap-5 justify-between desktop:px-[45px]';
      break;
  }
  const textButtons =
    buttons?.filter((button) => button.type !== 'icon' && !button.floatLeft) ||
    [];
  const leftButtons = buttons?.filter((button) => button.floatLeft) || [];
  const iconButtons = buttons?.filter((button) => button.type === 'icon') || [];

  return (
    <div
      onClick={(e) => handleBackdropClickModified(e)}
      id="backdrop"
      className={`animate-modal-backdrop-open w-fit desktop:w-auto fixed inset-0 overflow-y-auto ${
        enableOverlapOfModals ? 'z-[99999]' : 'z-50'
      } bg-black-blur backdrop-blur ${
        disableOverflow ? 'overflow-hidden' : 'overflow-auto'
      }`}
    >
      <div
        className={`${backDropClass} flex flex-col mx-auto w-screen ${modalDesktopHeight}`}
        onKeyDown={handleModalKeyDown}
        onClick={handleBackdropClick}
      >
        <div
          className={`flex flex-row ${
            hFull ? 'h-full' : ''
          } animate-modal-open`}
        >
          {leftModalComponent}
          <div
            className={`grow relative flex flex-col min-h-screen desktop:min-h-fit ${modalDesktopHeight} ${modalContainerClass} shadow-lg ${modalContainerCustomClass}`}
            data-testid={testID}
          >
            <div className={titleContainerClass}>
              {title && (
                <h3 className={`${titleClass} ${titleCustomClass}`}>
                  {icon && icon} {title}
                </h3>
              )}
              {type === 'default' ? (
                (disableMobileClose && isMobile) ||
                (disableWebClose && !isMobile) ? (
                  <></>
                ) : (
                  <CloseIcon
                    className={`absolute ${closeIconColor} top-2.5 right-[5px] desktop:top-[15px] desktop:right-[15px] ${pointerClass}`}
                    onClick={onClose}
                    data-testid="close-modal-button"
                  />
                )
              ) : type === 'warning-with-header' ? (
                <CloseIcon
                  className={`desktop:h-6 absolute stroke-med-gray top-2.5 right-[5px] desktop:top-[1px] desktop:right-3 ${pointerClass}`}
                  onClick={onClose}
                  data-testid="close-modal-button"
                />
              ) : (
                <IconComponent
                  width="w-12"
                  height="h-12"
                  icon={WarningIcon}
                  fill="fill-alert-negative"
                />
              )}
            </div>
            <div
              className={contentContainerClass}
              id={MODAL_CONTENT_TO_DOWNLOAD_ID}
            >
              {type === 'warning-with-header' && (
                <IconComponent
                  width="w-12"
                  height="h-12"
                  icon={WarningIcon}
                  fill="fill-alert-negative"
                />
              )}

              {children}
            </div>
            {textButtons.length > 0 && (
              <div className={textButtonsContainer}>
                {textButtons.length > 0 && (
                  <div className={buttonsContainerClass}>
                    {textButtons.map((button, index) => (
                      <ButtonComponent key={index} {...button}>
                        {button.label}
                      </ButtonComponent>
                    ))}
                  </div>
                )}

                {iconButtons.length > 0 && (
                  <div className="flex flex-row gap-[5px]">
                    {iconButtons.map((button, index) => (
                      <ButtonComponent key={index} {...button}>
                        {button.label}
                      </ButtonComponent>
                    ))}
                  </div>
                )}

                {leftButtons.length > 0 && (
                  <div className={buttonsContainerClass}>
                    {leftButtons.map((button, index) => (
                      <ButtonComponent key={index} {...button}>
                        {button.label}
                      </ButtonComponent>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
