import { useState } from 'react';

interface ValidationRule {
  validator: (value: string, formData?: Record<string, string>) => boolean;
  message: string;
}

export interface ValidationRules {
  [fieldName: string]: ValidationRule[];
}

export interface Errors {
  [fieldName: string]: string | null;
}

const useFormValidation = (
  validationRules: ValidationRules,
  formData?: Record<string, string>,
) => {
  const [errors, setErrors] = useState<Errors>({});

  const validateField = (fieldName: string, value: string) => {
    const rules = validationRules[fieldName];
    let errorMessage: string | null = null;

    for (const rule of rules) {
      if (!rule.validator(value, formData)) {
        errorMessage = rule.message;
        break;
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  };

  const validateForm = (formData: Record<string, string>) => {
    const newErrors: Errors = {};

    for (const fieldName in validationRules) {
      const value = formData[fieldName];
      const rules = validationRules[fieldName];

      for (const rule of rules) {
        if (!rule.validator(value, formData)) {
          newErrors[fieldName] = rule.message;
          break;
        }
      }

      if (!newErrors[fieldName]) {
        newErrors[fieldName] = null;
      }
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === null);
  };

  const getIsFormValid = (formData: Record<string, string>) => {
    const newErrors: Errors = {};

    for (const fieldName in validationRules) {
      const value = formData[fieldName];
      const rules = validationRules[fieldName];

      for (const rule of rules) {
        if (!rule.validator(value, formData)) {
          newErrors[fieldName] = rule.message;
          break;
        }
      }

      if (!newErrors[fieldName]) {
        newErrors[fieldName] = null;
      }
    }

    return Object.values(newErrors).every((error) => error === null);
  };

  return { errors, validateField, validateForm, getIsFormValid };
};

export default useFormValidation;
