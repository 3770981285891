import { useCallback, useEffect, useRef, useState } from 'react';
import { useModalParams } from 'components/modal/useModalManager';
import { ScanQr } from '../components/ScanQr';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { NewDermScoreScan } from '../components/newDermScoreScan';
import { ResizePhoto } from '../components/resizePhoto';
import { DermScoreResults } from '../components/dermScoreResults';
import { useNavigate } from 'react-router-dom';
import { Scan } from 'app/dashboard/interfaces/scan.interfaces';

import {
  Order_By,
  GetSkintellingentTestByUserIdQuery,
  Skintelligent_Tests,
} from 'graphql/generated/hasura';
import {
  formatMMDDYYYYtoYYYYMMDD,
  getOrderMethodForScanResults,
  mapFhirSkintelligentTest,
  populateScanResult,
} from 'utilities/functions';
import { MY_DERMSCORE_NEW_SCAN } from 'utilities/routes';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import {
  GetFhirSkintellingentTestsQuery,
  useGetFhirSkintellingentTestsLazyQuery,
} from 'graphql/generated/remote-schema-hasura';
import ButtonComponent from 'components/button/buttonComponent';
import ScansRecentResults from '../components/ScanRecentResults';
import DermScoreHistory from '../components/DermScoreHistory';
import Loader from 'components/loaderComponent';
import DermscoreGraphCard from './DermscoreGraphCard';
import { useAuth } from 'auth/context/AuthContext';
import { useChannelSignal, SIGNAL_CHANNELS } from 'hooks/useChannelSignal';
import { AnalyzingPhoto } from '../components/analyzingPhoto';
import EmptyStateComponent from 'components/EmptyStateComponent';

const NUMBER_OF_ROWS = 10;

export const MyDermscorePage = () => {
  const navigate = useNavigate();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_DERMSCORE,
  });
  const { isOpen: isScanQrModalOpen } = useModalParams('scan-qr');
  const { isOpen: isNewDermscorScanModalOpen } = useModalParams('new-scan');
  const { isOpen: isResizePhotoModalOpen } = useModalParams('resize-photo');
  const { isOpen: isDermscoreResultModalOpen } = useModalParams('scan-results');
  const { isOpen: isAnalyzingPhotoModalOpen } = useModalParams('analize-photo');

  const [scans, setScans] = useState<Scan[]>([]);
  const [scansCount, setScansCount] = useState<number>(0);
  const [recentScans, setRecentScans] = useState<Scan[]>([]);
  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();
  const [orderBy, setOrderBy] = useState<Record_Order_By>({
    skintelligent_tests_test: {
      created_at: Order_By.Desc,
    },
  });
  const refetchRequested = useRef(false);

  const [
    fhirGetSkintelligentTests,
    { loading: fhirLoadingSkintelligentTests, refetch },
  ] = useGetFhirSkintellingentTestsLazyQuery({
    variables: {
      limit: NUMBER_OF_ROWS,
      orderBy: getOrderMethodForScanResults(JSON.stringify(orderBy)),
    },

    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      populateScanData(mapFhirSkintelligentTests(data));
    },
  });

  const mapFhirSkintelligentTests = (
    data: GetFhirSkintellingentTestsQuery,
  ): GetSkintellingentTestByUserIdQuery => {
    const mappedFhirData =
      data.getFHIRSkintelligentTests.skintelligent_tests.map((test) => {
        return mapFhirSkintelligentTest(test);
      });

    return {
      skintelligent_tests: mappedFhirData,
      skintelligent_tests_aggregate: {
        aggregate: {
          count: data.getFHIRSkintelligentTests.total,
        },
      },
    } as unknown as GetSkintellingentTestByUserIdQuery;
  };

  useEffect(() => {
    fhirGetSkintelligentTests();
  }, [fhirGetSkintelligentTests]);

  const populateScanData = async (
    scans: GetSkintellingentTestByUserIdQuery,
    isLoadMore = false,
  ) => {
    if (scans.skintelligent_tests.length) {
      const populatedScans: Scan[] = [];
      for (const scan of scans.skintelligent_tests) {
        const populatedScan = populateScanResult(scan as Skintelligent_Tests);
        populatedScans.push(populatedScan);
      }
      if (isLoadMore) {
        setScans((scans) => [...scans, ...populatedScans]);
      } else {
        setScans(populatedScans);
      }
      setScansCount(scans?.skintelligent_tests_aggregate.aggregate?.count || 0);
    } else {
      setScans([]);
      setScansCount(0);
    }
  };

  const handleLoadMore = () => {
    if (scans) {
      fhirGetSkintelligentTests({
        variables: {
          from: fromDate
            ? `${formatMMDDYYYYtoYYYYMMDD(fromDate)}T00:00:00Z`
            : null,
          to: toDate ? `${formatMMDDYYYYtoYYYYMMDD(toDate)}T23:59:59Z` : null,
          limit: scans.length + NUMBER_OF_ROWS,
          orderBy: getOrderMethodForScanResults(JSON.stringify(orderBy)),
        },
        onCompleted: (data) => {
          populateScanData(mapFhirSkintelligentTests(data));
        },
        fetchPolicy: 'network-only',
      });
    }
  };

  const handleRefetch = useCallback(() => {
    fhirGetSkintelligentTests({
      variables: {
        from: fromDate
          ? `${formatMMDDYYYYtoYYYYMMDD(fromDate)}T00:00:00Z`
          : null,
        to: toDate ? `${formatMMDDYYYYtoYYYYMMDD(toDate)}T23:59:59Z` : null,
        limit: NUMBER_OF_ROWS,
        orderBy: getOrderMethodForScanResults(JSON.stringify(orderBy)),
      },
      onCompleted: (data) => {
        populateScanData(mapFhirSkintelligentTests(data));
      },
      fetchPolicy: 'network-only',
    });
  }, [fromDate, toDate, orderBy, fhirGetSkintelligentTests]);

  const handleOnFromDateChange = (date: string | undefined) => {
    if (toDate && date && new Date(date).getTime() > new Date(toDate).getTime())
      setToDate(undefined);
    setFromDate(date);
  };

  const handleOnToDateChange = (date: string | undefined) => {
    if (
      fromDate &&
      date &&
      new Date(date).getTime() < new Date(fromDate).getTime()
    )
      setFromDate(undefined);
    setToDate(date);
  };

  useEffect(() => {
    if (refetchRequested.current) {
      handleRefetch();
    }
  }, [handleRefetch]);

  useEffect(() => {
    refetchRequested.current = true;
    handleRefetch();
  }, [fromDate, toDate, orderBy, handleRefetch]);

  if (!recentScans.length && scans.length) {
    setRecentScans(scans.slice(0, 3));
  }

  const { user } = useAuth();

  useChannelSignal(
    (first) => {
      if (!first) refetch();
    },
    SIGNAL_CHANNELS.TESTKIT,
    user?.uuid,
  );

  // If scans are loading the table should handle it (not all the page)
  if (loading || !locale) return <Loader />;

  return (
    <>
      {isNewDermscorScanModalOpen && <NewDermScoreScan />}
      {isScanQrModalOpen && <ScanQr />}
      {isResizePhotoModalOpen && <ResizePhoto />}
      {isDermscoreResultModalOpen && <DermScoreResults />}
      {isAnalyzingPhotoModalOpen && <AnalyzingPhoto />}
      {!recentScans.length ? (
        <div className="flex px-7 grow justify-center items-center desktop:min-h-[300px]">
          <EmptyStateComponent
            ctaButton={
              <ButtonComponent
                className="text-sm rounded mt-4 desktop:mt-8"
                onClick={() => navigate(MY_DERMSCORE_NEW_SCAN)}
              >
                {locale?.createNewScan}
              </ButtonComponent>
            }
            title={locale?.noResultsTitle}
            text={locale?.noResults}
          />
        </div>
      ) : (
        <div className="px-7 pt-[30px] desktop:pt-0">
          <div className="flex flex-col desktop:flex-row w-full justify-between items-center p-0 gap-[30px] desktop:mb-[34px]">
            <div className="flex flex-row  w-full desktop:w-auto justify-between items-center gap-[7px]">
              <h1 className="text-left text-h1 text-dark-gray font-medium">
                {locale?.myDermscore}
              </h1>
            </div>
            <div className="flex flex-row w-full desktop:w-auto">
              <ButtonComponent onClick={() => navigate(MY_DERMSCORE_NEW_SCAN)}>
                {locale?.createNewScan}
              </ButtonComponent>
            </div>
          </div>
          <div className="flex flex-col px-5 pt-5 pb-10 my-[30px] desktop:p-[30px] desktop:mb-[30px] gap-5 bg-white rounded-10 overflow-x-auto">
            <h3 className="text-h3 text-dark-gray font-semibold">
              {locale?.recentResults}
            </h3>
            <hr />
            <ScansRecentResults scans={recentScans} />
          </div>
          <DermscoreGraphCard />

          <DermScoreHistory
            onLoadMore={handleLoadMore}
            onFromDateChange={(date) =>
              handleOnFromDateChange(date || undefined)
            }
            onToDateChange={(date) => handleOnToDateChange(date || undefined)}
            onMobileSortChange={(nextSort) => setOrderBy(nextSort)}
            onColumnSortChange={(nextSort) => setOrderBy(nextSort)}
            loading={fhirLoadingSkintelligentTests}
            scans={scans}
            total={scansCount}
            fromDate={fromDate || ''}
            toDate={toDate || ''}
            orderBy={orderBy}
          />
        </div>
      )}
    </>
  );
};
