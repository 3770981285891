import React, { FC } from 'react';
import QRCode from 'react-qr-code';

import ButtonComponent from '../../../components/button/buttonComponent';

import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as MediaLibraryIcon } from 'assets/icons/mediaLibraryIcon.svg';

interface IUploadingTypesComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locale: any;
  qrCode: string;
  setIsShowUploadDetails: (value: boolean) => void;
  hasMedia: boolean;
  onShowLibraryModal: () => void;
}

const UploadingTypesComponent: FC<IUploadingTypesComponent> = ({
  locale,
  qrCode,
  setIsShowUploadDetails,
  hasMedia,
  onShowLibraryModal,
}) => {
  return (
    <div
      className="flex flex-col gap-3 justify-center"
      data-testid="upload-symptom-photo-type"
    >
      <div className="text-base font-medium text-[#666666]">
        <p>{locale?.addProblemAreaPhotoDescription}</p>
      </div>

      <QRCode size={300} className="self-center my-[18px]" value={qrCode} />

      <div className="text-base font-medium text-[#666666] text-center px-20 mb-[18px]">
        <p>
          {hasMedia
            ? locale?.youCanUploadPhotosFromDeviceOrLibrary
            : locale?.youCanUploadPhotosFromDevice}
        </p>
      </div>
      <div className="flex items-center justify-center gap-5">
        <ButtonComponent
          type="outlined"
          Icon={UploadIcon}
          iconPosition="left"
          onClick={() => setIsShowUploadDetails(true)}
        >
          <span className="uppercase text-sm">
            {locale?.uploadFromDeviceBtn}
          </span>
        </ButtonComponent>

        {hasMedia && (
          <ButtonComponent
            type="outlined"
            Icon={MediaLibraryIcon}
            iconPosition="left"
            onClick={onShowLibraryModal}
          >
            <span className="uppercase text-sm">
              {locale?.selectLibraryBtn}
            </span>
          </ButtonComponent>
        )}
      </div>
    </div>
  );
};

export default UploadingTypesComponent;
