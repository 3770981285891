import React, { FC, useState } from 'react';

import CarouselArrow from '../../../components/carouselArrow';
import CarouselDots from '../../../components/carouselDotsComponent';
import InputComponent from '../../../components/inputComponent';
import Spinner from '../../../components/Spinner';

import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { SharedMedia } from '../../my-patients/interfaces/interfaces';

interface IMediaLibraryCarousel {
  media: SharedMedia[][];
  selectedMedia: string;
  setSelectedMedia: (selected: string) => void;
}

const MediaLibraryCarousel: FC<IMediaLibraryCarousel> = ({
  media,
  selectedMedia,
  setSelectedMedia,
}) => {
  const [selectedScanIndex, setSelectedScanIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const changeMediaIndex = (direction: string) => {
    setIsLoading(true);

    setTimeout(() => {
      setSelectedScanIndex((index) => {
        return direction === 'decrement'
          ? (index - 1 + media.length) % media.length
          : (index + 1) % media.length;
      });
      setIsLoading(false);
    }, 500);
  };

  const onRadioClick = (selected: string) => () => {
    setSelectedMedia(selected);
  };

  return (
    <div>
      {media.length > 0 && (
        <>
          {isLoading ? (
            <div className="min-h-[338px] my-5 flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <ul
              className={`flex flex-wrap items-start gap-4 my-5 ${
                media.length > 1 ? 'min-h-[338px]' : ''
              }`}
            >
              {media[selectedScanIndex]?.map(
                (item: { id: string; img: string }) => (
                  <li
                    key={item.id}
                    onClick={() => setSelectedMedia(item.id)}
                    className={`relative cursor-pointer ${
                      selectedMedia === item.id
                        ? 'border-2 border-alert-positive'
                        : ''
                    }`}
                  >
                    <div className="absolute top-1 left-1">
                      <InputComponent
                        key={item.id}
                        type="radio"
                        radioInputProps={{
                          radioLabelTextWeight: 'font-semibold text-med-gray',
                          radioError: null,
                          radioInputValue: item.id,
                          radioInputCheckedValue: selectedMedia,
                          onRadioClick: onRadioClick(item.id),
                        }}
                        testID="problem-photo-radio"
                      />
                    </div>
                    <img
                      src={item?.img}
                      alt="media"
                      className="w-40 h-40 object-cover"
                    />
                  </li>
                ),
              )}
            </ul>
          )}

          {media.length > 1 && (
            <div className="flex flex-row items-center justify-around w-full mb-5">
              <CarouselArrow
                onClick={() => changeMediaIndex('decrement')}
                icon={ChevronLeftIcon}
                label="Prev"
              />
              <CarouselDots
                carouselLength={media.length}
                selectedDotIndex={selectedScanIndex}
              />
              <CarouselArrow
                onClick={() => changeMediaIndex('increment')}
                icon={ChevronRightIcon}
                label="Next"
                iconPosition="right"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MediaLibraryCarousel;
