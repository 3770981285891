import React, { FC } from 'react';

import ButtonComponent from '../../../components/button/buttonComponent';
import useHover from '../../../hooks/useHover';

import { ReactComponent as TrashIcon } from 'assets/icons/trashIcon.svg';

interface ICroppedImageComponent {
  croppedImage: string;
  setFile: (file: File | null) => void;
  setCroppedImage?: (croppedImage: string) => void;
}

const CroppedImageComponent: FC<ICroppedImageComponent> = ({
  croppedImage,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCroppedImage = () => {},
  setFile,
}) => {
  const [isHovered, hoverProps] = useHover<HTMLDivElement>();

  return (
    <div {...hoverProps} className="h-60 w-60 relative mx-auto my-5">
      <img
        src={croppedImage}
        alt="Selected image"
        className="absolute t-0 l-0 object-cover rounded-md h-full w-full"
      />
      {isHovered && (
        <div className="absolute cursor-pointer t-0 l-0 rounded-xl h-full w-60 flex justify-center items-center p-3 bg-black bg-opacity-40">
          <ButtonComponent
            Icon={TrashIcon}
            type="icon"
            paddingX="px-2.5"
            paddingY="py-2.5"
            className="text-clc-blue"
            onClick={() => {
              setCroppedImage('');
              setFile(null);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CroppedImageComponent;
