import { usePasswordResetEmail } from '../../../firebase/hooks';
import ButtonComponent from 'components/button/buttonComponent';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AUTH_LOGIN,
  AUTH_SEND_RESET_PASSWORD_EMAIL,
} from '../../../utilities/routes';

export const ExpiredResetPassword = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.EXPIRED_PASSWORD_EMAIL,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || '';
  const [, handlePasswordResetEmail, isLoading] = usePasswordResetEmail(true);

  const handleResendResetPasswordEmail = async () => {
    await handlePasswordResetEmail(email);

    navigate(`${AUTH_SEND_RESET_PASSWORD_EMAIL}?email=${email}`, {
      replace: true,
      state: {
        email,
      },
    });
  };

  const handleLogin = () => navigate(AUTH_LOGIN);

  if (loading && !locale) return null;

  return (
    <>
      <div className="flex flex-col items-center desktop:justify-center px-[20px] py-[30px] desktop:px-[60px] desktop:py-[40px] gap-[30px] rounded-10 bg-white">
        <div className="flex flex-col items-start desktop:items-center p-0 gap-[20px] desktop:gap-[10px]">
          <p className=" text-h2 desktop:text-h1 text-center font-medium text-charcoal-gray w-full">
            {locale?.expiredTitle}
          </p>
          <p className="text-base font-normal text-center text-med-gray max-w-[550px]">
            {locale?.requestNewLinkText}{' '}
            <span className="text-gray-950 font-semibold">{email}</span>
          </p>
        </div>
        <div className="flex flex-col items-center gap-5 w-full">
          <ButtonComponent
            testID="resend-button"
            type="submit"
            paddingX="px-5"
            paddingY="py-[9.5px]"
            className="uppercase text-sm desktop:w-[190px] rounded"
            containerClassName="font-bold "
            onClick={handleResendResetPasswordEmail}
            disabled={isLoading}
          >
            {locale?.requestNewLinkButton}
          </ButtonComponent>

          <ButtonComponent
            testID="login-button"
            onClick={handleLogin}
            type="underline"
            disabled={isLoading}
            noUpperCase
            className="text-clc-blue text-sm font-bold  uppercase w-auto"
          >
            {locale?.returnToLogin}
          </ButtonComponent>
        </div>
      </div>
    </>
  );
};
