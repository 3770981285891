import ButtonComponent from 'components/button/buttonComponent';
import Avatar from 'components/avatarComponent';
import Loader from 'components/loaderComponent';
import { useGetPage } from 'hooks/useGetPage';
import { pageIds } from 'utilities/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  GetFhirProviderByCodexIdQuery,
  useGetFhirProviderByCodexIdQuery,
} from 'graphql/generated/remote-schema-hasura';
import { ReactComponent as BackIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrowDown.svg';
import { ReactComponent as MeesagingIcon } from 'assets/icons/message.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/videocamera.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';

import { useState } from 'react';
import { parseServiceType } from 'app/my-appointments/components/select-a-provider/ProviderCardProps';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';
import { useGetSignedUrlFromStorage } from 'hooks/useGetSignedUrlFromStorage';
import { MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT } from 'utilities/routes';

export const ProviderBio = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const providerId = location.state?.providerId;
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });
  const [avatar, setAvatar] = useState<string>();
  const [providerInfo, setProviderInfo] =
    useState<GetFhirProviderByCodexIdQuery>();

  const signedProfileUrl = useGetSignedUrlFromStorage(
    avatar ?? '',
    providerId ?? '',
  );

  const { loading: providerLoadig } = useGetFhirProviderByCodexIdQuery({
    variables: {
      codexProviderId: providerId,
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setProviderInfo(data);
      const parts =
        data.getFHIRProviderbyCodexIdArgument.providers[0]?.providers?.SENSITIVE_profile_picture_id?.split(
          '/',
        ) || [];
      const pictureId = parts[parts.length - 1];
      setAvatar(pictureId);
    },
  });

  const onBackClick = () => {
    navigate(-1);
  };

  if (loading || !locale || providerLoadig) return <Loader />;

  const handleScheduleAnAppointment = () => {
    navigate(MY_APPOINTMENTS_SCHEDULE_AN_APPOINTMENT, {
      state: {
        codexProviderId: providerId,
        calendarId:
          providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers
            ?.acuityCalendarId,
        ownerId:
          providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers
            ?.acuityOwnerId,
      },
    });
  };

  const serviceOfferings =
    providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers?.serviceOfferings.map(
      (serviceOffering) => ({
        price: serviceOffering.price,
        serviceType: parseServiceType(serviceOffering.serviceType),
      }),
    );

  const videoPrice =
    serviceOfferings?.find(
      (offer) => offer.serviceType === AppointmentTypeEnum.Video,
    )?.price ?? null;
  const chatPrice =
    serviceOfferings?.find(
      (offer) => offer.serviceType === AppointmentTypeEnum.Chat,
    )?.price ?? null;
  const messagingPrice =
    serviceOfferings?.find(
      (offer) => offer.serviceType === AppointmentTypeEnum.Email,
    )?.price ?? null;

  const sensitiveAboutText =
    providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers
      ?.SENSITIVE_about || '';

  const formattedText = sensitiveAboutText.split('\n').map((item, index) => (
    <div key={index}>
      {item}
      <br />
    </div>
  ));

  return (
    <div className="px-7 pt-[30px] desktop:pt-0">
      <div className="flex flex-col gap-[30px]">
        <div className="flex w-[80px] justify-start items-start">
          <ButtonComponent
            type="underline"
            Icon={BackIcon}
            iconPosition="left"
            onClick={onBackClick}
          >
            {locale?.providerBio?.back}
          </ButtonComponent>
        </div>
        <div className="flex flex-col items-center desktop:flex-row gap-[30px]">
          <div className="flex w-36 justify-center">
            <Avatar size="md" imageUrl={signedProfileUrl} />
          </div>
          <div
            className="flex w-full flex-col items-center desktop:items-start gap-2 "
            data-testid="provider-bio-page"
          >
            <p
              className="text-dark-gray font-medium text-h1"
              data-testid="provider-name"
            >
              {
                providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]
                  ?.providers?.SENSITIVE_firstname
              }{' '}
              {
                providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]
                  ?.providers?.SENSITIVE_lastname
              }
            </p>
            <div className="flex flex-col desktop:flex-row items-center gap-3">
              {providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers?.SENSITIVE_credentials?.titles.map(
                (title) => (
                  <p key={title} className="text-med-gray font-normal text-h6">
                    {title}
                  </p>
                ),
              )}
            </div>
            <div className="flex flex-row items-center gap-[10px]">
              {providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers?.SENSITIVE_credentials?.certifications.map(
                (specialty, key) => (
                  <div key={key} className="bg-white rounded-[30px] py-1 px-3">
                    <p className="text-dark-gray text-h7 font-semibold text-center">
                      {specialty}
                    </p>
                  </div>
                ),
              )}
            </div>
            <div className="flex w-full desktop:w-auto flex-col desktop:flex-row justify-center desktop:gap-[30px] gap-4">
              <p className="text-dark-gray font-bold text-h7">
                {locale?.providerBio?.rate}
              </p>
              <div className="flex flex-col desktop:flex-row justify-center desktop:gap-[30px] gap-4">
                {videoPrice && (
                  <p className="text-med-gray text-h6 font-normal flex flex-row items-center gap-2">
                    <span>
                      <VideoIcon />
                    </span>
                    {locale?.card?.video} (${videoPrice})
                  </p>
                )}
                {chatPrice && (
                  <p className="text-med-gray text-h6 font-normal flex flex-row items-center gap-2">
                    <span>
                      <ChatIcon />
                    </span>
                    {locale?.card?.chat} (${chatPrice})
                  </p>
                )}
                {messagingPrice && (
                  <p className="text-med-gray text-h6 font-normal flex flex-row items-center gap-2">
                    <span>
                      <MeesagingIcon />
                    </span>
                    {locale?.card?.messaging} (${messagingPrice})
                  </p>
                )}
              </div>
            </div>
          </div>
          <ButtonComponent
            className="self-baseline"
            onClick={handleScheduleAnAppointment}
            testID="schedule-appointment-button"
          >
            {locale?.scheduleAppointment}
          </ButtonComponent>
        </div>
        <div className="flex flex-col desktop:flex-row gap-[30px]">
          <div className="flex flex-col desktop:w-2/3 px-5 pt-5 pb-[30px] desktop:p-[30px] gap-8 bg-white rounded-10">
            <p className="text-dark-gray font-semibold text-h4">
              {locale?.providerBio?.about}
            </p>
            <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
            <p className="text-med-gray font-normal text-h6 capitalize">
              {formattedText}
            </p>
            <hr className="hidden flex-row w-full items-center h-px bg-black-blur" />
            <div className="hidden w-[100px]">
              <ButtonComponent
                type="underline"
                Icon={ArrowDown}
                iconPosition="right"
              >
                {locale?.providerBio?.seeMore}
              </ButtonComponent>
            </div>
          </div>
          <div className="flex flex-col desktop:w-1/3 px-5 pt-5 pb-[30px] desktop:p-[30px] gap-8 bg-white rounded-10">
            <p className="text-dark-gray font-semibold text-h4">
              {locale?.providerBio?.profile}
            </p>
            <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
            <div>
              <p className="text-dark-gray font-bold text-h7">
                {locale?.providerBio?.gender}
              </p>
              <p className="text-med-gray font-normal text-h6 capitalize">
                {
                  providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]
                    ?.providers?.SENSITIVE_gender
                }
              </p>
            </div>
            <div>
              <p className="text-dark-gray font-bold text-h7">
                {locale?.providerBio?.languages}
              </p>
              <ul className="list-disc ml-4">
                {providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers?.SENSITIVE_languages_of_communication.map(
                  (language, key) => (
                    <li
                      key={key}
                      className="text-med-gray font-normal text-h6 capitalize"
                    >
                      {language}
                    </li>
                  ),
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col desktop:flex-row gap-[30px]">
          <div className="flex flex-col desktop:w-1/3 px-5 pt-5 pb-[30px] desktop:p-[30px] gap-8 bg-white rounded-10">
            <p className="text-dark-gray font-semibold text-h4">
              {locale?.providerBio?.contact}
            </p>
            <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
            <div>
              <p className="text-dark-gray font-bold text-h7 uppercase">
                {
                  providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]
                    ?.providers?.SENSITIVE_office_information?.officeName
                }
              </p>
              {providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]
                ?.providers?.SENSITIVE_office_information && (
                <>
                  <p className="text-med-gray font-normal text-h6">
                    {`${providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers?.SENSITIVE_office_information?.address1}, ${providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers?.SENSITIVE_office_information?.city}, ${providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers?.SENSITIVE_office_information?.state} ${providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers?.SENSITIVE_office_information?.zipCode}`}
                  </p>
                  <p className="text-med-gray font-normal text-h6">
                    {locale?.providerBio?.phone}:{' '}
                    {
                      providerInfo?.getFHIRProviderbyCodexIdArgument
                        .providers[0]?.providers?.SENSITIVE_office_information
                        ?.phone
                    }
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-col desktop:w-2/3 px-5 pt-5 pb-[30px] desktop:p-[30px] gap-8 bg-white rounded-10">
            <p className="text-dark-gray font-semibold text-h4">
              {locale?.providerBio?.education}
            </p>
            <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
            {providerInfo?.getFHIRProviderbyCodexIdArgument.providers[0]?.providers?.SENSITIVE_education.map(
              (education, key) => (
                <div key={key}>
                  <p className="text-dark-gray font-bold text-h7 uppercase">
                    {education.medicalSchool}
                  </p>
                  <ul className="list-disc ml-6">
                    <li className="text-med-gray font-normal text-h6">
                      {education.undergraduate} {education.residency}
                    </li>
                  </ul>
                </div>
              ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
