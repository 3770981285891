import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';

const NoNotifications: React.FC = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.NOTIFICATIONS,
  });

  if (loading && !locale) return null;

  return (
    <div data-testid="empty-notifications">
      <div className="flex flex-col items-start gap-2.5 text-base text-dark-gray font-medium">
        {locale.noNotifications}
      </div>
      <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
    </div>
  );
};

export default NoNotifications;
