import React from 'react';
import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { CardProps } from '../interfaces/testKits.interfaces';
import { twMerge } from 'tailwind-merge';

export const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  children,
  buttonCTA,
  buttonText,
  borderSubtitle = false,
  customYPadding = 'py-2.5',
  className,
}) => {
  const borderBackgroundContainerClasses =
    'px-2 bg-black bg-opacity-5 rounded-[5px] pt-0';
  const borderBackgroundClasses = 'text-charcoal-gray text-xs font-semibold';
  return (
    <div
      className={`${className} h-full bg-white flex flex-col p-5 desktop:p-[30px] gap-5 rounded-10`}
    >
      <div className="flex flex-col gap-2.5 desktop:gap-5 flex-grow">
        <div className="w-full flex items-start desktop:gap-[10px] pb-3 border border-transparent border-b-gray-opacity-15">
          <div className="justify-start items-center gap-2.5 inline-flex">
            <h3 className="text-charcoal-gray text-xl font-semibold leading-[25px] tracking-wide">
              {title}
            </h3>
            <div
              className={twMerge(
                'pt-1.5 justify-start items-start flex',
                borderSubtitle ? borderBackgroundContainerClasses : '',
              )}
            >
              <div
                className={twMerge(
                  'hidden desktop:block text-base font-normal text-med-gray leading-snug',
                  borderSubtitle ? borderBackgroundClasses : '',
                )}
              >
                {subtitle}
              </div>
            </div>
          </div>
          <div
            className={twMerge(
              'my-1',
              borderSubtitle && borderBackgroundContainerClasses,
            )}
          >
            <span
              className={twMerge(
                'desktop:hidden text-med-gray text-base font-normal leading-snug',
                borderSubtitle && borderBackgroundClasses,
              )}
            >
              {subtitle}
            </span>
          </div>
          <div className="hidden desktop:block self-stretch h-px bg-black bg-opacity-20" />
        </div>
        <div className={`flex flex-col ${customYPadding} gap-5 flex-grow`}>
          {children}
        </div>
      </div>
      {buttonText && (
        <div className="border-t border-black border-opacity-20 flex-col justify-center items-center desktop:items-start inline-flex min-h-[45px]">
          <ButtonComponent
            type="underline"
            Icon={ChevronRightIcon}
            iconPosition="right"
            iconWidth="w-2.5"
            iconHeight="h-[18px]"
            onClick={buttonCTA}
            className="font-bold text-sm"
          >
            {buttonText}
          </ButtonComponent>
        </div>
      )}
    </div>
  );
};
