import React, { useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as Description } from 'assets/icons/body-description.svg';
import { Dot, ImageObject } from '../interfaces';
import CustomTooltip from 'components/CustomTooltip';

interface ICheckboxList {
  dots: Dot[];
  medias: Map<string, string | ImageObject>;
  onCheckboxChange: (id: number) => void;
  onBodyPartClick?: (id: number) => void;
}

interface CheckboxInput {
  displayCheckboxChildrenInput?: boolean;
  checked?: boolean | undefined;
  checkboxLabel?: string;
  name?: string;
  checkboxInputLabel?: string;
  checkboxInputTestId?: string;
  bottomMargin?: boolean;
  enableVersatileClick?: boolean;
  isAlternativeCheckboxColor?: boolean;
  checkboxLabelClassName?: string;
  onCheckboxChange: (checked: boolean) => void;
  onCheckboxChildrenInputChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

const Checkbox: React.FC<CheckboxInput> = ({
  checked,
  isAlternativeCheckboxColor,
  checkboxLabel,
  checkboxLabelClassName,
  name,
  onCheckboxChange,
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const getBgToDisplay = () => {
    if (checked && isAlternativeCheckboxColor) return 'bg-clc-blue';
    return 'bg-white';
  };
  const baseClasses =
    'flex shrink-0 h-7 w-7 text-clc-blue rounded-5 border input-border__gray appearance-none';
  const checkClass = checked
    ? 'bg-center bg-no-repeat !bg-checkbox-alternative'
    : '';

  const handleCheckboxChange = () => {
    onCheckboxChange(true);
  };

  return (
    <>
      <div className="flex group cursor-pointer">
        <input
          ref={checkboxRef}
          id={name}
          name={name}
          type="checkbox"
          className={twMerge(
            `${baseClasses} ${checkClass} cursor-pointer bg-center group-hover:border-transparent group-hover:bg-checkbox-hover ${getBgToDisplay()}`,
          )}
          onChange={handleCheckboxChange}
          data-testid={checkboxLabel}
        />

        {checkboxLabel && (
          <label
            htmlFor={name}
            className={twMerge(
              'flex test items-center group-hover:text-clc-blue ml-2.5 text-med-gray cursor-pointer',
              checkboxLabelClassName,
              checked && 'text-charcoal-gray',
            )}
          >
            {checkboxLabel}
          </label>
        )}
      </div>
    </>
  );
};

const DescriptionTooltip = ({
  description,
  id,
}: {
  description: string;
  id: string;
}) => {
  return (
    <>
      <span data-tooltip-id={id}>
        <Description className="ml-2" />
      </span>
      <CustomTooltip
        noArrow
        place="left-start"
        content={description}
        id={id}
        className="p-2 !text-med-gray !bg-white !font-semibold border max-w-[440px] !opacity-100 z-10"
      />
    </>
  );
};

const CheckboxList: React.FC<ICheckboxList> = ({
  dots,
  medias,
  onCheckboxChange,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onBodyPartClick = () => {},
}) => {
  return (
    <>
      {dots.map((dot) => {
        const { description } =
          (medias.get(String(dot.location)) as ImageObject) || {};

        return (
          <div className="flex items-center" key={dot.id}>
            {dot.selected ? (
              <div
                className="flex items-center cursor-pointer"
                onClick={() => onBodyPartClick(dot.id)}
              >
                <img
                  src={(medias.get(String(dot.id)) as ImageObject)?.image}
                  alt={`photo for ${dot.location}`}
                  className="w-7 h-7 mr-2 rounded"
                />
                <span className="text-med-gray text-sm self-center">
                  {dot.location}
                </span>
              </div>
            ) : (
              <Checkbox
                name={dot.id.toString()}
                checked={dot.selected}
                isAlternativeCheckboxColor
                checkboxLabelClassName="ml-2 text-med-gray text-sm self-center"
                checkboxLabel={dot.location}
                onCheckboxChange={() => onCheckboxChange(dot.id)}
              />
            )}

            {description && (
              <DescriptionTooltip
                description={description}
                id={String(dot.id)}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default CheckboxList;
