import { usePasswordResetEmail } from '../../../firebase/hooks';
import Alert from 'components/alertComponent';
import ButtonComponent from 'components/button/buttonComponent';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useLocation, useNavigate } from 'react-router-dom';
import { AUTH_LOGIN } from '../../../utilities/routes';
import { useTimer } from 'hooks/useTimer';
import { SECONDS_IN_MIN } from 'utilities/constants/time';

const formatCounter = (counter: number) => {
  const farmattedCounter = `0${counter}`.slice(-2);
  return `00:${farmattedCounter}`;
};

export const SendResetPasswordEmail = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.RESET_PASSWORD_EMAIL,
  });

  const {
    timeLeft,
    temporalyDisableResendActions,
    setTimeLeft,
    setTemporalyDisableResendActions,
  } = useTimer();

  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || '';

  const [errorMsg, handlePasswordResetEmail, isLoading] =
    usePasswordResetEmail(true);
  const handleResendResetPasswordEmail = async () => {
    setTemporalyDisableResendActions(true);
    setTimeLeft(SECONDS_IN_MIN);
    await handlePasswordResetEmail(email);
  };

  const handleLogin = () => navigate(AUTH_LOGIN);

  if (loading && !locale) return null;

  return (
    <>
      <div className="flex flex-row w-full mb-[10px]">
        {errorMsg ? (
          <Alert type="warning" text={errorMsg} />
        ) : (
          <Alert
            type="positive"
            testID="success-alert"
            text={
              <>
                {locale?.resetEmailSent}{' '}
                <span className="text-gray-500">{email}</span>
              </>
            }
          />
        )}
      </div>
      <div className="flex flex-col items-center desktop:justify-center px-[20px] py-[30px] desktop:px-[60px] desktop:py-[40px] gap-[30px] rounded-10 bg-white">
        <div className="flex flex-col items-start desktop:items-center p-0 gap-[20px] desktop:gap-[30px]">
          <p className="text-base font-medium text-center text-med-gray">
            {locale?.checkSpamFolder}
          </p>
        </div>
        <div className="flex flex-col items-center gap-5 w-full">
          <ButtonComponent
            testID="login-button"
            type="submit"
            paddingX="px-5"
            paddingY="py-[9.5px]"
            className="uppercase text-sm desktop:w-[240px]"
            containerClassName="font-bold "
            onClick={handleLogin}
          >
            {locale?.loginButton}
          </ButtonComponent>

          {temporalyDisableResendActions && !isLoading ? (
            <p className="text-base text-med-gray">
              {locale?.resendAvailableIn}{' '}
              <span className="text-gray-950 font-bold inline-flex w-[46px] justify-center">
                {formatCounter(timeLeft)}
              </span>
            </p>
          ) : (
            <div className="flex justify-center w-full desktop:w-auto">
              <p className="text-center text-base font-medium text-med-gray mr-2">
                {locale?.emailNotReceived}
              </p>
              <ButtonComponent
                testID="resend-button"
                onClick={handleResendResetPasswordEmail}
                type="underline"
                disabled={isLoading}
                noUpperCase
                className="text-clc-blue text-base font-bold uppercase w-auto"
              >
                {locale.resendButton}
              </ButtonComponent>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
