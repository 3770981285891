import Avatar from 'components/avatarComponent';
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as WhiteCheckedProfileIcon } from 'assets/icons/white-checked-profile.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video-icon.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as EmailGrayIcon } from 'assets/icons/email-gray.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useGetPage } from 'hooks/useGetPage';
import { PARAM_MODALS_IDENTIFIERS, pageIds } from 'utilities/constants';
import {
  AppointmentTypeEnum,
  useGetProviderPatientSignedUrlMutation,
} from 'graphql/generated/hasura';
import { PatientsRelatedToProvider } from '../interfaces/patient.interface';
import AppointmentDetailModalProvider from 'components/appointmentDetailModal/AppointmentDetailModalProvider';
import { useNavigate } from 'react-router-dom';
import {
  MY_PATIENTS,
  MY_PATIENTS_BIO_INFO,
  MY_PATIENTS_BIO_INFO_APPOINTMENT_HISTORY,
  MY_PATIENTS_BIO_INFO_DERMSCORE_HISTORY,
  MY_PATIENTS_BIO_INFO_SHARED_MEDIA,
  MY_PATIENTS_BIO_INFO_TEST_KIT_RESULTS,
} from 'utilities/routes';
import ButtonComponent from 'components/button/buttonComponent';
import useDropdown from 'components/dropdown/useDropdown';

const NUMBER_OF_APPOINTMENTS_TO_SHOW = 3;
const DROPDOWN_BASE_ID = 'appointments';

interface Props {
  className: string;
  patient: PatientsRelatedToProvider;
}

type RelatedInformation =
  | 'appointmentHistory'
  | 'dermscores'
  | 'testKitsResults'
  | 'sharedMediaText';

const MyPatientCard: React.FC<Props> = ({ className, patient }) => {
  const navigate = useNavigate();
  const [showProviderAppointmentModal, setShowProviderAppointmentModal] =
    useState<boolean>(false);

  const [patientsProfilePictureUrl, setPatientsProfilePictureUrl] =
    useState<string>('');

  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PATIENTS,
  });

  const {
    openDropdownId,
    handleDropdownClick,
    registerDropdownRef,
    handleHoverOut,
  } = useDropdown();

  const [getProviderPatientFileUrlFromStorage] =
    useGetProviderPatientSignedUrlMutation({});

  const renderAppointmentIconBasedOnType = (type: AppointmentTypeEnum) => {
    switch (type) {
      case AppointmentTypeEnum.Video:
        return <VideoIcon />;

      case AppointmentTypeEnum.Chat:
        return <ChatIcon />;

      case AppointmentTypeEnum.Email:
        return <EmailGrayIcon />;

      default:
        return <VideoIcon />;
    }
  };

  const onPatientNameClick = () =>
    navigate(`?${PARAM_MODALS_IDENTIFIERS.NEW_PATIENTS_REQUEST}=true`, {
      state: {
        id: patient.id,
        patientData: {
          age: patient.age.toString(),
          gender: patient.SENSITIVE_gender,
          genderIdentity: patient.SENSITIVE_self_identity_gender,
          height: `${patient.heightFt} ${locale.feet} ${patient.heightIn} ${locale.inches}`,
          hips: `${patient.waist} ${locale.inches}`,
          name: `${patient.SENSITIVE_firstname} ${patient.SENSITIVE_lastname}`,
          nationality: patient.SENSITIVE_etnicity,
          profilePicture: patientsProfilePictureUrl,
          waist: `${patient.waist} ${locale.inches}`,
        },
      },
    });
  const onAppointmentClick = (appoinmentCodexId: string) => {
    navigate(
      `?${PARAM_MODALS_IDENTIFIERS.APPOINTMENT_ID}=${appoinmentCodexId}`,
    );
    setShowProviderAppointmentModal(true);
  };
  const onRelatedInformationClick = (item: RelatedInformation) => {
    let patientDetailTab = '';
    switch (item) {
      case 'appointmentHistory':
        patientDetailTab = MY_PATIENTS_BIO_INFO_APPOINTMENT_HISTORY;
        break;
      case 'dermscores':
        patientDetailTab = MY_PATIENTS_BIO_INFO_DERMSCORE_HISTORY;
        break;
      case 'testKitsResults':
        patientDetailTab = MY_PATIENTS_BIO_INFO_TEST_KIT_RESULTS;
        break;
      case 'sharedMediaText':
        patientDetailTab = MY_PATIENTS_BIO_INFO_SHARED_MEDIA;
        break;
      default:
        patientDetailTab = MY_PATIENTS_BIO_INFO;
        break;
    }
    navigate(
      `${MY_PATIENTS}/${patient.id}/${MY_PATIENTS_BIO_INFO}/${patientDetailTab}`,
      { state: { patientId: patient.id } },
    );
  };

  const appointmentsToShow = useMemo(() => {
    if (Number(patient.appointments?.length) > NUMBER_OF_APPOINTMENTS_TO_SHOW) {
      return (
        patient.appointments?.slice(0, NUMBER_OF_APPOINTMENTS_TO_SHOW - 1) ?? []
      );
    }

    return patient.appointments ?? [];
  }, [patient]);

  const appointmentsToShowInDropDown = useMemo(() => {
    if (
      Number(patient.appointments?.length) <
      NUMBER_OF_APPOINTMENTS_TO_SHOW + 1
    ) {
      return [];
    }

    return (
      patient.appointments?.slice(NUMBER_OF_APPOINTMENTS_TO_SHOW - 1) ?? []
    );
  }, [patient]);

  const numberOfCollapsedAppointments = useMemo(() => {
    if (
      Number(patient.appointments?.length) <
      NUMBER_OF_APPOINTMENTS_TO_SHOW + 1
    ) {
      return 0;
    }

    return (
      Number(patient.appointments?.length) - NUMBER_OF_APPOINTMENTS_TO_SHOW + 1
    );
  }, [patient]);

  const collapsedAppointmentsLabel = useMemo(() => {
    const isPlural = numberOfCollapsedAppointments > 1;

    return `+${numberOfCollapsedAppointments} ${locale?.appointment}${
      isPlural ? 'S' : ''
    }`;
  }, [numberOfCollapsedAppointments, locale]);

  const dropdownId = useMemo(() => {
    return `${DROPDOWN_BASE_ID}-${patient.id}`;
  }, [patient]);

  useEffect(() => {
    const getPatientProfilePictureLink = async (id: string) => {
      const image = await getProviderPatientFileUrlFromStorage({
        variables: {
          patientCodexId: patient.id,
          fileId: id,
        },
      });
      setPatientsProfilePictureUrl(
        image.data?.GetProviderPatientSignedUrl?.url || '',
      );
    };
    if (patient.SENSITIVE_profile_picture_id) {
      getPatientProfilePictureLink(patient.SENSITIVE_profile_picture_id).catch(
        (error) =>
          console.error(
            `Something went wrong while trying to get the patient's profile picture: ${error}`,
          ),
      );
    }
  }, [
    patient.id,
    patient.SENSITIVE_profile_picture_id,
    getProviderPatientFileUrlFromStorage,
  ]);

  if (localeLoading || !locale) return null;

  return (
    <div className={`flex flex-col bg-white p-5 ${className}`}>
      {showProviderAppointmentModal && <AppointmentDetailModalProvider />}
      <div className="flex items-center gap-2.5">
        <Avatar size="sm_lg" imageUrl={patientsProfilePictureUrl} />
        <div className="flex flex-col">
          <div className="flex items-center">
            <p
              className="font-semibold text-h5 text-charcoal-gray underline hover:cursor-pointer"
              onClick={onPatientNameClick}
            >
              {patient.SENSITIVE_firstname} {patient.SENSITIVE_lastname}
            </p>
            {patient.kyc_approved && (
              <WhiteCheckedProfileIcon className="w-[14px] h-[14px] ml-1" />
            )}
          </div>
          <p className="font-medium text-med-gray text-h6">
            {patient.SENSITIVE_gender}, {patient.age} {locale.yearsOld}{' '}
          </p>
          <p className="font-medium text-med-gray text-h6">
            {patient.city}, {patient.state}
          </p>
        </div>
      </div>

      <div className="flex mt-[10px] flex-col self-start desktop:mt-5 w-full">
        <p className="font-bold text-charcoal-gray text-sm">
          {locale.upcomingAppointment}
        </p>
        {patient.appointments && patient.appointments.length > 0 ? (
          <ul className="flex flex-col mt-[6px] desktop:[&>li]:mt-0 h-[75px] gap-[5px]">
            {appointmentsToShow.map((appointment, index) => (
              <li key={index} className="flex items-center ml-[3px]">
                {renderAppointmentIconBasedOnType(appointment.appointmentType)}
                <span
                  className="ml-[5px] text-clc-blue underline font-bold text-sm hover:cursor-pointer"
                  onClick={() =>
                    onAppointmentClick(appointment.appointmentCodexId)
                  }
                >
                  {appointment.start}
                </span>
              </li>
            ))}
            {!!numberOfCollapsedAppointments && (
              <li
                ref={(ref) => registerDropdownRef(dropdownId, ref)}
                key={appointmentsToShow.length + 1}
                className="relative"
              >
                <div className="flex items-center ml-[3px]">
                  <div className="invisible">
                    <VideoIcon />
                  </div>
                  <span
                    onClick={() => handleDropdownClick(dropdownId)}
                    className="ml-[5px] text-clc-blue underline font-bold text-sm hover:cursor-pointer"
                  >
                    {collapsedAppointmentsLabel}
                  </span>
                </div>

                {openDropdownId === dropdownId && (
                  <div className="p-5 w-[calc(100%+40px)] z-50 flex flex-col items-start absolute top-[25px] -left-[20px] bg-white rounded-large shadow-container max-h-[193px] overflow-y-auto">
                    <CloseIcon
                      className="absolute right-[20px] hover:cursor-pointer"
                      onClick={handleHoverOut}
                    />
                    <ul className="flex flex-col mt-[6px] desktop:[&>li]:mt-0 gap-[5px]">
                      {appointmentsToShowInDropDown.map(
                        (appointment, index) => (
                          <li
                            key={index}
                            className="flex items-center ml-[3px]"
                          >
                            {renderAppointmentIconBasedOnType(
                              appointment.appointmentType,
                            )}
                            <span
                              className="ml-[5px] text-clc-blue underline font-bold text-sm hover:cursor-pointer"
                              onClick={() => {
                                onAppointmentClick(
                                  appointment.appointmentCodexId,
                                );
                                handleHoverOut();
                              }}
                            >
                              {appointment.start}
                            </span>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </li>
            )}
          </ul>
        ) : (
          <p className="text-sm font-medium text-med-gray py-[30px]">
            {locale?.noUpcomingAppointments}
          </p>
        )}
      </div>

      <div className="text-clc-blue flex flex-col mt-[10px] desktop:mt-5 gap-[5px]">
        <ButtonComponent
          type="underline"
          className="text-sm font-bold"
          onClick={() => onRelatedInformationClick('appointmentHistory')}
        >
          {locale?.allAppointments}
        </ButtonComponent>
        <ButtonComponent
          type="outlined"
          className="text-sm font-bold mt-2"
          onClick={() => onRelatedInformationClick('dermscores')}
        >
          {locale.dermscores}
        </ButtonComponent>
        <ButtonComponent
          type="outlined"
          className="text-sm font-bold"
          onClick={() => onRelatedInformationClick('testKitsResults')}
        >
          {locale.testKitsResults}
        </ButtonComponent>
        <ButtonComponent
          type="outlined"
          className="text-sm font-bold"
          onClick={() => onRelatedInformationClick('sharedMediaText')}
        >
          {locale.sharedMediaText}
        </ButtonComponent>
      </div>
    </div>
  );
};

export default MyPatientCard;
