import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Arrow } from 'assets/icons/blue-arrow-top.svg';
import {
  componentIds,
  ISO_CODE_TO_EXCLUDE,
  STATES_TO_EXCLUDE,
} from 'utilities/constants';
import ButtonComponent from 'components/button/buttonComponent';
import CustomTooltip from 'components/CustomTooltip';
import Select, {
  components,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import {
  DropdownIndicatorSingle,
  ISingleValueLabel,
  NullIndicatorSeparator,
} from 'components/dropdown/ReactSelectComponents';
import { State } from 'country-state-city';
import { useGetComponent } from 'hooks/useGetComponent';
import useOutsideClick from 'components/datePicker/hooks/useOutsideClick';

interface ISelectStateProviderProps {
  defaultState?: string;
}

const SingleValue = (props: SingleValueProps<ISingleValueLabel>) => {
  return (
    <components.SingleValue
      {...props}
      innerProps={{
        className: 'text-sm !text-neutral-800',
      }}
    >
      {props.children}
    </components.SingleValue>
  );
};

const Option = (props: OptionProps<ISingleValueLabel>) => {
  return (
    <components.Option {...props} className="!text-sm !text-neutral-800">
      {props.children}
    </components.Option>
  );
};

const SelectStateProvider: React.FC<ISelectStateProviderProps> = ({
  defaultState,
}) => {
  const { loading: componentLocaleLoading, data } = useGetComponent({
    locale: 'en',
    componentId: componentIds.USA_STATES,
  });
  const [opened, setOpened] = useState<boolean>(false);

  const optionsRef = useRef<HTMLDivElement>(null);
  const closeOptions = useCallback(() => setOpened(false), [setOpened]);
  const handleOptionsClick = useCallback(
    () => setOpened((opened) => !opened),
    [setOpened],
  );

  useOutsideClick(optionsRef, closeOptions, opened);

  const [options, setOptions] = useState<ISingleValueLabel[]>([]);
  const [defaultStateName, setDefaultState] = useState<
    ISingleValueLabel | undefined
  >();

  useEffect(() => {
    const states = State.getStatesOfCountry('US')
      .filter((state) => !state.isoCode.includes(ISO_CODE_TO_EXCLUDE))
      .filter((state) => !STATES_TO_EXCLUDE.includes(state.name))
      .map((state) => ({
        value: state.isoCode,
        label: `${state.name}, ${state.isoCode}`,
      }));

    setOptions(states);
    setDefaultState(states.find(({ value }) => defaultState === value));
  }, [defaultState]);

  if (!data || componentLocaleLoading) {
    return null;
  }

  return (
    <div className="relative inline-block" ref={optionsRef}>
      <ButtonComponent
        Icon={Arrow}
        type="underline"
        className="uppercase  !text-h4 font-semibold text-clc-blue"
        fill={opened ? 'rotate-0' : 'rotate-180'}
        iconPosition="right"
        onClick={handleOptionsClick}
      >
        {defaultStateName?.label}
      </ButtonComponent>
      {opened && (
        <div className="absolute bg-white p-5 w-[400px] transform -translate-y-[1px] rounded-md border border-gray-opacity-15">
          <div
            className="flex flex-col"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <p className="font-medium text-med-gray-3 text-base mb-2">
              {data.description}
            </p>
            <div className="flex items-center justify-between mb-1">
              <div className="text-base font-semibold text-neutral-800">
                {data.dropdownLabel}
              </div>
              <div className="flex">
                <ButtonComponent type="underline">
                  {data.useDefault}
                </ButtonComponent>
                <Info
                  data-tooltip-id={'location'}
                  className="w-5 h-5 fill-med-gray-3 ml-2"
                />
                <CustomTooltip
                  id={'location'}
                  content={data.tooltipContent}
                  place="right"
                  className="max-w-[190px] !text-base !rounded-md font-medium"
                />
              </div>
            </div>
            <Select
              options={options}
              isSearchable
              defaultValue={defaultStateName}
              components={{
                DropdownIndicator: DropdownIndicatorSingle,
                IndicatorSeparator: NullIndicatorSeparator,
                SingleValue,
                Option,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectStateProvider;
