import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as Phone } from 'assets/icons/phone.svg';
import { ReactComponent as Picture } from 'assets/icons/mediaLibraryIcon.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencilIcon.svg';
import Modal from 'components/modal/modalComponent';
import { useGetGenericActions } from '../../../hooks/useGetGenericActions';
import { genericActionsIds, pageIds } from '../../../utilities/constants';
import { ModalButtonProps } from '../../../components/modal/modal.interfaces';
import { useGetPage } from '../../../hooks/useGetPage';
import { Dot, ImageObject } from './BodySymtomsLocation/interfaces';
import InputComponent from '../../../components/inputComponent';
import Dropzone from '../../../components/Dropzone';
import { useUploadFileToStorageMutation } from '../../../graphql/generated/hasura';
import { twMerge } from 'tailwind-merge';
import ButtonComponent from '../../../components/button/buttonComponent';
import CroppedImageComponent from '../appointments/CroppedImageComponent';

interface IModalSymptomDetails {
  dot: Dot | null;
  setDot: React.Dispatch<React.SetStateAction<Dot | null>>;
  handleDeleteSymptom: () => void;
  mediaPerBodyLocation: Map<string, string | ImageObject>;
}

const initial = {
  image: '',
  mediaId: '',
};

const ModalSymptomDetails: React.FC<IModalSymptomDetails> = ({
  dot,
  mediaPerBodyLocation,
  setDot,
  handleDeleteSymptom,
}) => {
  const [formModal, setForm] = useState<ImageObject>(initial);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [uploadFile, { loading: isMediaSaving }] =
    useUploadFileToStorageMutation({});

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.EDIT_SYMPTOM_MODAL,
  });

  const onClose = () => {
    setDot(null);
    setIsEdit(false);
  };

  const onEdit = () => setIsEdit(true);

  const handleOnTextAreaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const description = event.target.value;
      setForm((prev) => ({ ...prev, description }));
    },
    [],
  );

  const handleImageUpload = useCallback(
    async (files: File[]) => {
      const file = files[0];
      const buffer = await file.arrayBuffer();
      const result = await uploadFile({
        variables: {
          file: {
            mimetype: file.type,
            originalname: file.name,
            buffer: Array.from(new Uint8Array(buffer)),
          },
        },
      });
      const url = result.data?.UploadFileToStorage?.file?.url;
      const mediaId = result.data?.UploadFileToStorage?.file?.fileId;

      if (!url || !mediaId) return;
      setForm((prev) => ({ ...prev, image: url, mediaId }));
    },
    [uploadFile],
  );

  const onDeleteFile = () => {
    setForm((prev) => ({ ...prev, image: '', mediaId: '' }));
  };

  const handleUpdate = () => {
    mediaPerBodyLocation.set(String(dot?.id), formModal);
    return onClose();
  };

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.CANCEL,
        genericActionsIds.EDIT,
        genericActionsIds.CLOSE,
        genericActionsIds.SAVE,
      ],
    });

  const Buttons: ModalButtonProps[] = [
    {
      label: !isEdit
        ? genericAction?.[genericActionsIds.CLOSE]?.close
        : genericAction?.[genericActionsIds.SAVE]?.save,
      onClick: !isEdit ? onClose : handleUpdate,
    },
    {
      label: !isEdit
        ? genericAction?.[genericActionsIds.EDIT]?.edit
        : genericAction?.[genericActionsIds.CANCEL]?.cancel,
      onClick: !isEdit ? onEdit : onClose,
      type: 'underline',
      Icon: !isEdit ? PencilIcon : undefined,
      iconPosition: 'left',
    },
    ...(!isEdit
      ? [
          {
            label: locale?.deleteDetails,
            type: 'underline',
            Icon: TrashIcon,
            floatLeft: true,
            fill: 'fill-clc-blue',
            iconPosition: 'left' as const,
            className: 'ml-auto',
            onClick: handleDeleteSymptom,
          },
        ]
      : []),
  ];

  useEffect(() => {
    setForm(mediaPerBodyLocation.get(String(dot?.id)) as ImageObject);
  }, [dot, mediaPerBodyLocation]);

  if (
    (loading && !locale) ||
    (genericActionLoading && !genericAction) ||
    !dot
  ) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title={`${dot.location} details`}
      buttons={Buttons}
      titleCustomClass="normal-case desktop:!text-h3"
      modalDesktopWidth="desktop:max-w-[720px]"
    >
      <div className="flex flex-col gap-[30px] desktop:py-2">
        {!isEdit ? (
          <div className={twMerge('gap-6', isMediaSaving && 'opacity-50')}>
            <div className="max-w-[500px] mx-auto rounded-lg overflow-hidden mb-2.5">
              <img
                src={formModal?.image}
                alt="scan"
                className="w-full h-full object-cover"
              />
            </div>

            <div>
              <p>{formModal?.description}</p>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <h2 className="text-h1 font-medium">{dot.location}</h2>
            <div className="text-base font-medium text-[#666666] my-2.5">
              <p>{locale?.changePhotoDescription}</p>
            </div>

            <div className="w-full">
              {formModal?.image ? (
                <CroppedImageComponent
                  croppedImage={formModal?.image}
                  setFile={onDeleteFile}
                />
              ) : (
                <>
                  <Dropzone
                    onDrop={handleImageUpload}
                    label={locale.selectFile}
                    supportText={locale.supportedFormat}
                    btnText={locale.selectFileBtn}
                    disabled={isMediaSaving}
                  />

                  <div className="flex justify-center items-center flex-wrap gap-5 my-4">
                    <ButtonComponent
                      type="outlined-transparent"
                      className="w-full"
                      containerClassName="w-full"
                      iconPosition="right"
                      Icon={Phone}
                    >
                      {locale.takePhotoBtn}
                    </ButtonComponent>

                    <ButtonComponent
                      type="outlined-transparent"
                      className="w-full"
                      containerClassName="w-full"
                      iconPosition="right"
                      Icon={Picture}
                    >
                      {locale.selectLibraryBtn}
                    </ButtonComponent>
                  </div>
                </>
              )}
            </div>

            <div className="mt-4 desktop:mt-0">
              <InputComponent
                value={formModal?.description}
                type="text-area"
                maxLengthValue={500}
                textAreaProps={{
                  height: 'h-52',
                  textAreaTitle: locale?.descriptionLabel,
                  containerHeight: 'h-full',
                  onTextAreaChange: handleOnTextAreaChange,
                  name: 'appointmentNotes',
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalSymptomDetails;
