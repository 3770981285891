import { BodySymptomsPlacerProps, Dot } from './interfaces';
import InputComponent from 'components/inputComponent';
import { componentIds } from 'utilities/constants';
import { useGetComponent } from 'hooks/useGetComponent';
import { useMemo, useState } from 'react';
import HeadBodySection from './bodySections/HeadBodySection';
import FrontBodySection from './bodySections/FrontBodySection';
import BackBodySection from './bodySections/BackBodySection';
import ModalSymptomDetails from '../ModalSymptomDetails';

const BodySymptomsPlacer: React.FC<BodySymptomsPlacerProps> = ({
  noForm = false,
  backDots,
  frontDots,
  setBackDots,
  setFrontDots,
  mediaPerBodyLocation,
  onClick,
}) => {
  const [experiencingSymptoms, setExperiencingSymptoms] = useState(null);
  const [selectedDot, setDot] = useState<Dot | null>(null);

  const dotsArray = useMemo(
    () => [...frontDots, ...backDots],
    [frontDots, backDots],
  );

  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENT_PREWORK,
  });
  const keys = useMemo(
    () => ({
      ...(locale?.frontCheckboxes || {}),
      ...(locale?.backCheckboxes || {}),
    }),
    [locale],
  );

  const toggleFrontDot = (id: number): void => {
    const dot = frontDots.find((dot) => dot.id === id);
    if (dot?.selected) {
      setDot(dot);
    }
    const newFrontDots = frontDots.map((dot) => ({
      ...dot,
      selected: dot.id === id || dot.selected,
    }));
    setFrontDots(newFrontDots);
    setExperiencingSymptoms(null);
  };
  const toggleBackDot = (id: number): void => {
    const dot = backDots.find((dot) => dot.id === id);
    if (dot?.selected) {
      setDot(dot);
    }
    const newBackDots = backDots.map((dot) => ({
      ...dot,
      selected: dot.id === id || dot.selected,
    }));
    setBackDots(newBackDots);
    setExperiencingSymptoms(null);
  };

  const toggleOnAllDots = (): void => {
    setFrontDots((dots) =>
      dots.map((dot) => ({
        ...dot,
        selected: true,
      })),
    );
    setBackDots((dots) =>
      dots.map((dot) => ({
        ...dot,
        selected: true,
      })),
    );
  };

  const toggleOffAllDots = (): void => {
    const newFrontDots = frontDots.map((dot) => ({
      ...dot,
      selected: false,
    }));
    const newBackDots = backDots.map((dot) => ({
      ...dot,
      selected: false,
    }));
    setFrontDots(newFrontDots);
    setBackDots(newBackDots);
  };

  const handleDeleteSymptom = (): void => {
    const newFrontDots = frontDots.map((dot) => ({
      ...dot,
      selected: selectedDot?.id === dot.id ? false : dot.selected,
    }));
    const newBackDots = backDots.map((dot) => ({
      ...dot,
      selected: selectedDot?.id === dot.id ? false : dot.selected,
    }));
    setFrontDots(newFrontDots);
    setBackDots(newBackDots);
    setDot(null);
  };

  const handleBodyParsing = (
    direction: 'front' | 'back',
    bodyId: number,
    isDetails = false,
  ) => {
    const dot = dotsArray.find((dot) => dot.id === bodyId);
    if (!dot) return;
    const label = keys[dot.strapiKey];

    if (!onClick || !label || isDetails) {
      if (direction === 'back') {
        toggleBackDot(bodyId);
      } else {
        toggleFrontDot(bodyId);
      }
      return;
    }
    onClick(label, direction, bodyId);
  };

  const FormComponent = useMemo<React.FC<{ children: React.ReactNode }>>(() => {
    if (noForm) {
      return ({ children }) => <>{children}</>;
    }
    return ({ children }) => (
      <form className="flex flex-col items-center gap-2.5 p-8 rounded-lg bg-white w-4/5">
        {children}
      </form>
    );
  }, [noForm]);

  if (loading || !locale) return null;
  return (
    <>
      <ModalSymptomDetails
        mediaPerBodyLocation={mediaPerBodyLocation}
        dot={selectedDot}
        setDot={setDot}
        handleDeleteSymptom={handleDeleteSymptom}
      />
      <FormComponent>
        <div className="flex flex-col desktop:flex-row items-center desktop:items-start w-full">
          <div className="mb-4 desktop:mb-0 w-full">
            <h3 className="font-semibold text-h6">
              {locale?.whereExperiencingSymptoms}{' '}
              <span className="text-sm text-med-gray">
                {locale?.selectAllThatApply}
              </span>
            </h3>
            <ul className="mt-2.5">
              <li>
                <InputComponent
                  type="radio"
                  name="experiencingSymptomsNoneOfTheBelow"
                  radioInputProps={{
                    radioInputLabel: locale?.noneOfTheBelow,
                    radioInputValue: locale?.noneOfTheBelow,
                    radioInputCheckedValue:
                      experiencingSymptoms === locale?.noneOfTheBelow
                        ? locale?.noneOfTheBelow
                        : undefined,
                    radioError: null,
                    onRadioClick: () => {
                      setExperiencingSymptoms(locale?.noneOfTheBelow);
                      toggleOffAllDots();
                    },
                  }}
                  testID={`appointment-prework-radio-${locale?.noneOfTheBelow}`}
                />
              </li>
              <li className="hidden">
                <InputComponent
                  type="radio"
                  name="experiencingSymptomsAllOfTheBelow"
                  radioInputProps={{
                    radioInputLabel: locale?.allOfTheBelow,
                    radioInputValue: locale?.allOfTheBelow,
                    radioInputCheckedValue:
                      experiencingSymptoms === locale?.allOfTheBelow
                        ? locale?.allOfTheBelow
                        : undefined,
                    radioError: null,
                    onRadioClick: () => {
                      setExperiencingSymptoms(locale?.allOfTheBelow);
                      toggleOnAllDots();
                    },
                  }}
                  testID={`appointment-prework-radio-${locale?.allOfTheBelow}`}
                />
              </li>
            </ul>
            <HeadBodySection
              backDots={backDots}
              frontDots={frontDots}
              handleBodyParsing={handleBodyParsing}
              mediaPerBodyLocation={mediaPerBodyLocation}
            />
            <FrontBodySection
              dots={frontDots}
              handleBodyParsing={handleBodyParsing}
              mediaPerBodyLocation={mediaPerBodyLocation}
            />
            <BackBodySection
              dots={backDots}
              handleBodyParsing={handleBodyParsing}
              mediaPerBodyLocation={mediaPerBodyLocation}
            />
          </div>
        </div>
      </FormComponent>
    </>
  );
};

export default BodySymptomsPlacer;
