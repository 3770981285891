import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as UpArrowIcon } from 'assets/icons/upArrow.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIcon.svg';
import React, { useEffect, useState } from 'react';
import InputComponent from 'components/inputComponent';
import MyPatientCard from '../components/MyPatientCard';
import { useIsMobile } from 'hooks/useIsMobile';
import { useGetPage } from 'hooks/useGetPage';
import { PARAM_MODALS_IDENTIFIERS, pageIds } from 'utilities/constants';
import { interpolateVariables } from 'utilities/functions';
import { useGetPatientsRelatedToProvider } from '../hooks/useGetPatientsRelatedToProvider';
import Loader from 'components/loaderComponent';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  MY_PATIENTS,
  MY_PATIENTS_BIO_INFO,
  MY_PATIENTS_BIO_INFO_PATIENT_INFO,
} from 'utilities/routes';
import { useLocation } from 'react-router-dom';
import { useModalParams } from 'components/modal/useModalManager';
import { NewPatientRequestModal } from 'app/dashboard/components/provider/newPatientRequest/NewPatientRequestModal';

const MyPatients: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const patientSearchFromDashboard = location?.state?.searchBarValue;
  const [findFilter, setFindFilter] = useState<string>(
    patientSearchFromDashboard ?? '',
  );
  const [latestSearchCriteria, setLatestSearchCriteria] = useState<string>('');
  const [sortFilterOptions, setSortFilterOptions] = useState<string[]>([]);
  const [selectedSortBy, setSelectedSortBy] = useState<string>('');
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(true);
  const isMobile = useIsMobile();

  const { isOpen: isNewPatientRequestOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.NEW_PATIENTS_REQUEST,
  );

  const handleUpdateResults = () => {
    setLatestSearchCriteria(findFilter);
    handleRefetch();
  };

  const handleResetFilters = () => {
    setLatestSearchCriteria('');
    setSelectedSortBy('');
    setFindFilter('');
    handleRefetch('');
  };

  const handleSeeMore = () => {
    handleFetchMore();
  };
  const onFindChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFindFilter(e.target.value);
  };
  const onSortByChange = (value: string) => {
    setSelectedSortBy(value);
  };
  const toggleFilterOpen = () => setIsFiltersOpen((prev) => !prev);

  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_PATIENTS,
  });

  const {
    relatedPatients,
    total,
    relatedPatientsLoading,
    handleFetchMore,
    handleRefetch,
  } = useGetPatientsRelatedToProvider({
    searchCriteria: findFilter,
    sort: selectedSortBy,
  });

  useEffect(() => {
    if (locale && !localeLoading) {
      setSelectedSortBy(locale?.dateNewest);
      setSortFilterOptions([locale?.dateNewest, locale?.dateOldest]);
    }
  }, [locale, localeLoading]);

  useEffect(() => {
    const locationCode = searchParams.get('code');
    const stateCode = searchParams.get('state');

    if (locationCode !== null) {
      const locationCode = location.search.split('?code=');

      const processedUrl = `${MY_PATIENTS}/${stateCode}/${MY_PATIENTS_BIO_INFO}/${MY_PATIENTS_BIO_INFO_PATIENT_INFO}?virtual-pharmacies-fullscript=true`;

      navigate(processedUrl, {
        state: {
          code: locationCode,
        },
      });
    }
  });

  useEffect(() => {
    if (patientSearchFromDashboard) {
      handleRefetch(patientSearchFromDashboard);
    }
    // eslint-disable-next-line
  }, []);

  const calculateGridClasses = (currIndex: number): string => {
    // Determine if it's the last item in the row
    const isLastInRow = (currIndex + 1) % 4 === 0;

    // Determine if it's the last item in the array
    const isLastItemInArray = currIndex === relatedPatients.length - 1;

    // Determine if it's the first row
    const isFirstRow = currIndex < 4;

    // Build class names based on position in the grid
    const classNames = [
      `${isMobile ? 'boder-r-0' : 'border-r'}`,
      'border-graph-border-color',
      isLastInRow ? 'border-r-0' : '',
      isFirstRow ? 'border-y' : 'border-t-0 border-b',
      isLastItemInArray
        ? (relatedPatients.length - 1 + 1) % 4 === 0
          ? 'border-r-0'
          : 'border-r'
        : '',
    ].join(' ');

    return classNames;
  };

  if (localeLoading || !locale) return null;

  return (
    <div className="flex flex-col p-7 desktop:p-0">
      {isNewPatientRequestOpen && <NewPatientRequestModal />}
      <h1 className="font-medium text-h1 text-charcoal-gray">
        {locale.myPatients}
      </h1>
      <div className="flex flex-col bg-white p-5 rounded-[10px] mt-[30px] desktop:p-[30px]">
        <div className="flex rounded-[10px] flex-col bg-base-content px-5 py-2.5 desktop:px-[30px] desktop:py-5">
          <div className="flex flex-row w-full justify-between items-center">
            <label className="text-h5 font-semibold text-charcoal-gray ">
              {locale.findAndFilter}
            </label>
            <UpArrowIcon
              className={`self-center transition-transform duration-300 hover:cursor-pointer ${
                !isFiltersOpen && 'rotate-180'
              }`}
              width={12}
              height={12}
              onClick={toggleFilterOpen}
            />
          </div>
          {isFiltersOpen && (
            <>
              <hr className="mt-3 desktop:mt-5" />
              <div className="flex flex-col mt-6 desktop:mt-5 justify-between desktop:flex-row">
                <div className="flex flex-col desktop:items-center desktop:w-[70%] desktop:flex-row">
                  <label className="font-semibold text-base text-charcoal-gray">
                    {locale.find}
                  </label>
                  <div className="flex mt-2.5 items-center w-full desktop:mt-0 desktop:ml-[5px]">
                    <InputComponent
                      name="find"
                      type="text"
                      noMarginBottom
                      decoratorLeft
                      placeholder={locale.searchPlacerholder}
                      value={findFilter}
                      decorator={<SearchIcon />}
                      onChange={onFindChange}
                    />
                  </div>
                </div>

                <div className="flex mt-6 flex-col desktop:items-center desktop:flex-row desktop:mt-0">
                  <label>{locale.sortBy}</label>
                  <div className="w-full mt-2.5 desktop:w-[200px] desktop:ml-[5px] desktop:mt-0">
                    <InputComponent
                      name="sortby"
                      type="select"
                      noMarginBottom
                      value={selectedSortBy}
                      selectInputProps={{
                        fullWidth: true,
                        selectValues: sortFilterOptions,
                        onSelectChange: (e) => onSortByChange(e.target.value),
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className="mt-5" />
              <div className="flex justify-end items-center">
                <ButtonComponent
                  className="text-sm mt-5 desktop:w-[180px]"
                  type="underline"
                  showSpinner={relatedPatientsLoading}
                  disabled={relatedPatientsLoading}
                  onClick={handleResetFilters}
                >
                  {locale?.resetFilters}
                </ButtonComponent>
                <ButtonComponent
                  className="text-sm mt-5 desktop:w-[180px]"
                  type="outlined"
                  showSpinner={relatedPatientsLoading}
                  disabled={relatedPatientsLoading}
                  onClick={handleUpdateResults}
                >
                  {locale.updateResults}
                </ButtonComponent>
              </div>
            </>
          )}
        </div>
        {relatedPatientsLoading ? (
          <div className="mt-4">
            <Loader />
          </div>
        ) : (
          <>
            {relatedPatients.length ? (
              <>
                <p className="font-medium mt-[30px] text-h6 desktop:mt-10">
                  {interpolateVariables(locale.showingNOfM, {
                    NN: relatedPatients.length.toString() || '0',
                    MM: total.toString() || '0',
                  })}
                </p>
                <div className="mt-2.5 grid grid-cols-1 desktop:grid-cols-4">
                  {relatedPatients.map((patient, index) => {
                    const classNames = calculateGridClasses(index);
                    return (
                      <MyPatientCard
                        key={index}
                        className={classNames}
                        patient={patient}
                      />
                    );
                  })}
                </div>
                {relatedPatients.length < total && (
                  <div className="flex w-full justify-center items-center desktop:justify-start mt-6">
                    <div
                      className="flex items-center hover:cursor-pointer"
                      onClick={handleSeeMore}
                    >
                      <p className="text-clc-blue font-bold text-sm underline">
                        {locale.seeMore}
                      </p>
                      <ArrowDownIcon className="ml-3" />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="mt-[30px] text-alert-yellow py-3 bg-yellow flex text-h6 font-semibold flex-col rounded-[10px] px-5 desktop:flex-row items-center">
                <InfoIcon className="fill-alert-yellow w-10 h-10 dekstop:w-5 desktop:h-5" />
                <p className="mt-[15px] desktop:mt-0 desktop:ml-[15px]">
                  {latestSearchCriteria
                    ? interpolateVariables(locale.noRelatedPatientsFound, {
                        search_criteria: latestSearchCriteria,
                      })
                    : locale.youDontHavePatients}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MyPatients;
