import { useGetGenericActions } from 'hooks/useGetGenericActions';
import {
  PARAM_MODALS_IDENTIFIERS,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import { ModalButtonProps } from '../modal/modal.interfaces';
import Modal from '../modal/modalComponent';
import { useGetPage } from 'hooks/useGetPage';
import PreWorkSection from './components/PreworkSection';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetAppointmentByCodexAppointmentIdQuery,
  useGetProviderInfoByProviderCodexIdQuery,
  useGetProviderPatientInfoLazyQuery,
} from 'graphql/generated/remote-schema-hasura';

import Loader from 'components/loaderComponent';
import AppointmentInfoProvider from './components/AppointmentInfoProvider';
import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import {
  appointmentTypeFromServiceType,
  extractUUIDFromResURL,
  formatDateForAppointment,
  fromFhirAppointmentTypeToLocal,
  patientBioRouteByPatientId,
} from 'utilities/functions';
import { useContext, useRef } from 'react';
import { SharedMediaModalLocationState } from 'app/my-patients/interfaces/interfaces';
import { useGetAndStoreMediaSignedUrl } from 'hooks/useGetAndStoreMediaSignedUrl';
import { useGetSignedUrlFromStorageByAdminMutation } from 'graphql/generated/hasura';
import { AuthContext } from 'auth/context/AuthContext';

const appointmentIdQueryParam = 'appointmentId';

const AppointmentDetailModalProvider = () => {
  const navigate = useNavigate();
  const { user: loggedUser } = useContext(AuthContext);

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.CANCEL,
        genericActionsIds.SUBMIT,
        genericActionsIds.CLOSE,
      ],
    });
  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.APPOINTMENT_DETAILS,
  });

  const location = useLocation();
  const appointmentIdByState = location.state?.appointmentId as string;

  // For testing purposes appointmentId can be sent by query param
  // This way the modal is fully testable without having provider appointments screen done
  const searchParams = new URLSearchParams(location.search);
  const appointmentIdByQueryParam = searchParams.get(appointmentIdQueryParam);
  const appointmentId = appointmentIdByQueryParam || appointmentIdByState;
  const mediaMap = useRef<Map<string, string>>(new Map());
  const getMedia = useGetAndStoreMediaSignedUrl();

  const [
    getProviderPatientInfo,
    { loading: patientLoading, data: patientData },
  ] = useGetProviderPatientInfoLazyQuery();

  const { data: appointmentData, loading: appointmentLoading } =
    useGetAppointmentByCodexAppointmentIdQuery({
      variables: { appointmentCodexId: appointmentId },
      skip: !appointmentId,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.getFHIRAppointmentByCodexId.appointment?.codexPatientId) {
          getProviderPatientInfo({
            variables: {
              patientCodexId:
                data.getFHIRAppointmentByCodexId.appointment.codexPatientId,
            },
          });
        }
        if (data.getFHIRAppointmentByCodexId.appointment?.media?.length) {
          for (const { bodySite, fileId } of data.getFHIRAppointmentByCodexId
            .appointment.media) {
            if (bodySite && fileId) {
              mediaMap.current.set(bodySite, fileId);
            }
          }
        }
      },
    });

  const { loading: providerDataLoading, data: providerData } =
    useGetProviderInfoByProviderCodexIdQuery({
      variables: {
        providerCodexId: loggedUser?.uuid || '',
      },
      skip: !loggedUser?.uuid,
    });

  const [getSignedUrlFromStorageByAdmin] =
    useGetSignedUrlFromStorageByAdminMutation({});

  const handleClose = () => navigate(-1);

  if (
    localeLoading ||
    genericActionLoading ||
    !locale ||
    !genericAction ||
    !appointmentId
  )
    return null;

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      disabled: false,
      onClick: handleClose,
      testID: 'appointment-details-close-button',
    },
  ];

  const dataLoading = appointmentLoading || patientLoading;
  const patient = patientData?.getFHIRPatientbyCodexIDParameter.users[0];
  const appointment = appointmentData?.getFHIRAppointmentByCodexId.appointment;
  const patientFullName = `${patient?.SENSITIVE_firstname || ''} ${
    patient?.SENSITIVE_lastname || ''
  }`;
  const serviceOffering =
    providerData?.getFHIRProviderbyId.providers[0]?.serviceOfferings?.find(
      (service) =>
        appointmentTypeFromServiceType(service.serviceType) ===
        appointment?.appointmentType,
    );

  const goToPatientBioInfo = () =>
    navigate(patientBioRouteByPatientId(appointment?.codexPatientId || ''));

  const onMediaLocationClick = async (location: string) => {
    if (!appointment) return;
    const currentLocationMediaId = mediaMap.current.get(location);
    if (currentLocationMediaId) {
      const media = appointment.media?.find(
        ({ fileId }) => fileId === currentLocationMediaId,
      );
      const mediaUrl = await getMedia(
        currentLocationMediaId,
        appointment.codexPatientId,
      );
      if (!mediaUrl) return;
      const modalState: SharedMediaModalLocationState = {
        mediaDate: new Date(appointment.start || 0),
        mediaSrc: mediaUrl,
        providerName: patientFullName,
        appointmentType: fromFhirAppointmentTypeToLocal(
          appointment.appointmentType || undefined,
        ),
        bodySite: location,
        description: media?.description || undefined,
      };
      navigate(`.?${PARAM_MODALS_IDENTIFIERS.SHARED_MEDIA_MODAL_ID}=true`, {
        state: modalState,
      });
    }
  };

  const onViewReportClick = async () => {
    if (appointment?.providerPdfId) {
      const fileId = extractUUIDFromResURL(appointment.providerPdfId);
      const { data } = await getSignedUrlFromStorageByAdmin({
        variables: {
          fileId: fileId,
          userId: appointment.codexPatientId,
        },
      });
      if (data?.GetSignUrlFromStorageByAdmin?.url) {
        window.open(data?.GetSignUrlFromStorageByAdmin?.url, '_blank');
      }
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={handleClose}
      buttons={Buttons}
      title={locale.appointmentDetails}
      testID="appointment-detail-modal"
    >
      {dataLoading || !appointment || providerDataLoading ? (
        <Loader />
      ) : (
        <div className="container mx-auto">
          {/* Alert component must come here when the backend ticket is taken */}

          {patient && (
            <AppointmentInfoProvider
              datetime={formatDateForAppointment(
                new Date(appointment.start || ''),
              )}
              name={patientFullName}
              appointmentType={appointment.appointmentType}
              pictureOwnerCodexId={appointment.codexPatientId}
              profilePictureUrl={patient.SENSITIVE_profile_picture_id}
              locale={locale}
              appointmentPrice={serviceOffering?.price}
            />
          )}
          <div className="row mt-5 desktop:mt-8">
            <div className="flex justify-between items-center border-b border-gray-300 mb-4 pb-4">
              <span className="transition duration-600 ease-in-out  text-lg font-semibold">
                {locale.appointmentPrework}
              </span>
            </div>
            <div className="overflow-hidden transition-all ease-in-out duration-600">
              <div className="content mb-5">
                <PreWorkSection
                  onMediaLocationClick={onMediaLocationClick}
                  appointmentData={appointment}
                  locale={locale}
                />
                <div className="flex desktop:flex-col items-start">
                  <ButtonComponent
                    onClick={goToPatientBioInfo}
                    type="underline"
                    Icon={ChevronRightIcon}
                  >
                    {locale.viewPatientProfile}
                  </ButtonComponent>
                  {appointment?.providerPdfId && (
                    <ButtonComponent
                      onClick={onViewReportClick}
                      type="underline"
                      className="mt-4"
                      Icon={ChevronRightIcon}
                    >
                      {locale.viewReport}
                    </ButtonComponent>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AppointmentDetailModalProvider;
