import React, { useCallback, useRef } from 'react';
import { ReactComponent as More } from 'assets/icons/more-options.svg';
import useOutsideClick from '../datePicker/hooks/useOutsideClick';
import ButtonComponent from '../button/buttonComponent';
import { twMerge } from 'tailwind-merge';

interface IOptionProps {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: string;
  onClick?: () => void;
}

interface IOptionModalProps {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  options: IOptionProps[];
}

const OptionsModal: React.FC<IOptionModalProps> = ({
  opened,
  setOpened,
  options,
}) => {
  const optionsRef = useRef<HTMLDivElement>(null);
  const closeOptions = useCallback(() => setOpened(false), [setOpened]);
  const handleOptionsClick = useCallback(
    () => setOpened((opened) => !opened),
    [setOpened],
  );

  useOutsideClick(optionsRef, closeOptions, opened);

  return (
    <div className="relative inline-block" ref={optionsRef}>
      <ButtonComponent
        Icon={More}
        type="outlined"
        className={twMerge(
          'desktop:px-1.5 group  font-bold text-med-gray-3 hover:text-clc-blue active:text-clc-blue border-med-gray-3 border hover:bg-white active:bg-white',
          opened &&
            'border-b-transparent text-clc-blue rounded-b-[0px] z-10 relative',
        )}
        fill={
          opened ? 'fill-clc-blue' : 'fill-med-gray-3 group-hover:fill-clc-blue'
        }
        stroke="stroke-transparent"
        iconPosition="left"
        onClick={handleOptionsClick}
      >
        <span className="desktop:hidden">More</span>
      </ButtonComponent>

      {opened && (
        <div className="absolute w-[200px] transform -translate-y-[1px] rounded-md rounded-tl-[0px] border border-med-gray-3 overflow-hidden">
          {!!options.length &&
            options.map(({ Icon, label, onClick }) => {
              const handleClick = (
                event: React.MouseEvent<HTMLButtonElement>,
              ) => {
                event.preventDefault();
                event.stopPropagation();
                onClick && onClick();
              };
              return (
                <button
                  key={label}
                  onClick={handleClick}
                  className="p-3 flex items-center w-full bg-white"
                >
                  {!!Icon && (
                    <span className="mr-2">
                      <Icon className="w-6" />
                    </span>
                  )}
                  <span className="text-base font-medium text-med-gray-3">
                    {label}
                  </span>
                </button>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default OptionsModal;
