import { PointTooltipProps } from '@nivo/line';

export const PointTooltip: React.FC<PointTooltipProps> = ({ point }) => {
  const pointDate = point.data.x as Date;
  const month = pointDate.getMonth();
  const day = pointDate.getDate();
  const year = pointDate.getFullYear();
  return (
    <div className="flex flex-col gap-0 bg-white rounded-1 px-2 py-2.5">
      <span className="text-sm font-semibold text-med-gray">
        {month}/{day}/{year}
      </span>
      <span className="text-lg font-semibold text-clc-black">
        {point.data.yFormatted}
      </span>
    </div>
  );
};
