import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { componentIds, genericActionsIds } from 'utilities/constants';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import {
  downloadPdf,
  formatDateToCustomFormat,
  printPdf,
} from 'utilities/functions';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import Modal from 'components/modal/modalComponent';
import Thumbnail from 'assets/images/thumbnail.svg';
import {
  SharedMediaModalLocationState,
  SharedMediaModalProps,
} from '../interfaces/interfaces';
import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { useGetComponent } from 'hooks/useGetComponent';
import { appointmentRenderInfo } from 'app/my-appointments/interfaces';
import Loader from 'components/loaderComponent';
import useLoadModalAppointmentInfo from 'app/my-account/pages/my-media-library/hooks/useLoadModalAppointmentInfo';
import { useGoToAppointmentDetails } from 'hooks/useGoToAppointmentDetails';

export const SharedMediaModal: React.FC<SharedMediaModalProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { data: allLocales, loading: allLocalesLoading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.MY_PATIENTS_MODALS,
  });
  const locale = allLocales?.mediaModal;
  const goToAppointmentDetails = useGoToAppointmentDetails();

  const {
    mediaDate,
    mediaSrc,
    providerName: providerNameByParam,
    appointmentType: appointmentTypeByParam,
    bodySite,
    appointmentCodexId,
    mediaTitle,
    description,
  } = (location.state as SharedMediaModalLocationState) || {};

  const handleAppointmentDetails = () =>
    appointmentCodexId && goToAppointmentDetails(appointmentCodexId);

  const {
    loading: appointmentDataLoading,
    appointmentTypeByState,
    providerNameByState,
  } = useLoadModalAppointmentInfo(
    appointmentCodexId,
    appointmentTypeByParam,
    providerNameByParam,
  );

  const providerName = providerNameByState ?? providerNameByParam;
  const appointmentType = appointmentTypeByState ?? appointmentTypeByParam;

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CLOSE, genericActionsIds.DOWNLOAD],
    });

  const handleClose = () => {
    navigate(-1);
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CLOSE].close,
      onClick: handleClose,
    },
  ];

  const handleOnDownload = async () => {
    if (!mediaSrc) return;
    downloadPdf(mediaSrc, bodySite || mediaTitle || locale.unknownSource, true);
  };

  const handleOnPrint = async () => {
    if (!mediaSrc) return;
    printPdf(mediaSrc);
  };

  const AppointmentIcon =
    appointmentType !== undefined &&
    appointmentRenderInfo[appointmentType]?.icon;

  if (genericActionLoading || allLocalesLoading) return null;

  return (
    <Modal
      isOpen={true}
      title={locale?.modalTitle}
      buttons={Buttons}
      onClose={handleClose}
      contentPaddingX="grow px-0 desktop:px-[30px]"
      contentPaddingY="py-0 desktop:py-[30px]"
      disableMobileGrow
      contentContainerDefaultBackground="bg-white"
      contentContainerDefaultBorder="border-b-2 border-base-content"
      leftModalComponent={
        <div className="hidden desktop:flex bg-neutral-500 overflow-hidden w-full min-h-[600px] bg-cover bg-center justify-center items-center">
          {isLoading && (
            <img
              className="w-full object-cover"
              src={Thumbnail}
              alt="Default Image"
            />
          )}
          <img
            className={`w-full object-contain ${isLoading ? 'hidden' : ''}`}
            src={mediaSrc}
            onLoad={handleImageLoad}
            alt="Scan result"
          />
        </div>
      }
    >
      <div className="flex flex-col grow">
        <div className="desktop:hidden flex bg-neutral-500 overflow-hidden w-full h-[350px] bg-cover bg-center justify-center items-center">
          {isLoading && (
            <img
              className="w-full object-cover"
              src={Thumbnail}
              alt="Default Image"
            />
          )}
          <img
            className={`w-full object-contain ${isLoading ? 'hidden' : ''}`}
            src={mediaSrc}
            onLoad={handleImageLoad}
            alt="Scan result"
          />
        </div>
        {appointmentDataLoading ? (
          <Loader />
        ) : (
          <div className="flex grow flex-col gap-2.5 desktop:gap-1.5 min-w-full w-max px-7 py-5 desktop:px-0 desktop:py-0">
            <div className="text-zinc-800 text-lg font-semibold  leading-snug tracking-wide">
              {bodySite || mediaTitle || locale.unknownSource}
            </div>
            <div className="text-neutral-500 text-base font-normal leading-snug">
              {formatDateToCustomFormat(mediaDate?.toISOString())}
            </div>
            <div className="py-2.5 justify-start items-center gap-2.5 inline-flex">
              {AppointmentIcon && (
                <div className="justify-center items-center w-6 h-6 flex">
                  <AppointmentIcon />
                </div>
              )}
              <div className="grow shrink basis-0 text-zinc-800 text-base font-bold leading-snug">
                {providerName}
              </div>
            </div>
            {description && (
              <div className="py-2.5 justify-start items-center gap-2.5 inline-flex">
                <p className="font-medium text-base text-med-gray">
                  {description}
                </p>
              </div>
            )}
            {appointmentCodexId && (
              <ul className="p-2.5 list-inside list-disc">
                <li
                  className="text-blue-700 text-base font-bold underline leading-snug cursor-pointer"
                  onClick={handleAppointmentDetails}
                >
                  {locale.appointmentDetails}
                </li>
              </ul>
            )}
            <div className="flex flex-row grow justify-around items-end" />
            <div className="flex flex-row justify-around desktop:justify-start items-center">
              <ButtonComponent
                type="icon"
                Icon={FileDownloadIcon}
                onClick={handleOnDownload}
              />
              <ButtonComponent
                type="icon"
                Icon={PrintIcon}
                onClick={handleOnPrint}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
