import ButtonComponent from 'components/button/buttonComponent';
import { useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as DownArrow } from 'assets/icons/arrowDown.svg';
import DNATestKitFilters from '../components/DNATestKitFilters';
import DNATestKitTable from '../components/DNATestKitTable';
import Loader from 'components/loaderComponent';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import { TestKitsTableDataWithPatientInfo } from '../interfaces/testKits.interfaces';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useParams } from 'react-router-dom';
import {
  DnaVisitOrderByCriterias,
  useGetProviderPatientDetailsQuery,
  Hasura_Test_Types_Enum,
  useGetFhirDnaVisitAndZrtTestsLazyQuery,
  GetFhirDnaVisitAndZrtTestsQuery,
  DiagnosticReportOrderByInput,
} from 'graphql/generated/remote-schema-hasura';

import {
  Dnavisit_Test_Statuses_Enum,
  InputMaybe,
  OrderByDirections,
  Order_By,
  Test_Types_Enum,
} from 'graphql/generated/hasura';
import {
  extractUUIDFromResURL,
  formatDateToCustomFormat,
  formatMMDDYYYYtoYYYYMMDD,
} from 'utilities/functions';
import {
  MapFhirZrtToDnaVisitTestState,
  MapHasuraToFhirDnaVisitTestType,
} from 'app/manage-test-kits/hooks/constants';
import { SIGNAL_CHANNELS, useChannelSignal } from 'hooks/useChannelSignal';
import { useAuth } from 'auth/context/AuthContext';

const NUMBER_OF_ROWS = 10;

enum Order_Field_DNA_Visit_Tests {
  provider = 'ProviderName',
  date = 'CreatedAt',
  testID = 'SapphirosBarcode',
  'test type' = 'TestType',
}

type Dna_Visit_Test_Keys_Type = 'date' | 'provider' | 'test type' | 'testID';

export const TestKitResults: React.FC = () => {
  const { patientId } = useParams();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.TEST_KIT_RESULTS_PROVIDER_TAB,
  });

  const [isContentVisible, setIsContentVisible] = useState(false);
  const [userDataTests, setUserDataTests] = useState<
    TestKitsTableDataWithPatientInfo[]
  >([]);
  const [providerOptions, setProvidersOptions] = useState<string[]>([]);
  const [testTotal, setTestTotal] = useState<number>(0);
  const [providerName, setProviderName] = useState<string | null>(null);
  const [viewByType, setViewByType] = useState<string | null>(null);
  const [find, setFind] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [sortByMobile, setSortByMobile] = useState(locale?.mobileDateNewest);
  const [orderBy, setOrderBy] = useState<Record_Order_By>({});

  const isFirstRun = useRef(true);
  const getOrderMethodForDNATestResults = useCallback(
    (orderBy: Record_Order_By) => {
      for (const order in orderBy) {
        return {
          field:
            DnaVisitOrderByCriterias[
              Order_Field_DNA_Visit_Tests[order as Dna_Visit_Test_Keys_Type]
            ],
          direction:
            Order_By.Asc === orderBy[order]
              ? OrderByDirections.Asc
              : OrderByDirections.Desc,
        };
      }
    },
    [],
  );

  const viewTypeToTestTypesEnum = useCallback(
    (type: string | null): Test_Types_Enum | undefined => {
      switch (type) {
        case locale?.dnaSkinTestSelectOption:
          return Test_Types_Enum.DnaSkin;
        case locale?.dnaVitaminTestSelectOption:
          return Test_Types_Enum.DnaVitamin;
        case locale?.zrtHeavyMetalsTestSelectOption:
          return Test_Types_Enum.ZrtHeavyMetals;
        case locale?.zrtHormoneTestSelectOption:
          return Test_Types_Enum.ZrtHormone;
        case locale?.zrtNeurotransmittersTestSelectOption:
          return Test_Types_Enum.ZrtNeurotransmitters;
        case locale?.zrtInflamatoryTestSelectOption:
          return Test_Types_Enum.ZrtInflammatory;
        case locale?.all:
        case null:
          return undefined;

        default:
          console.error('Unexpected type was received', type);
          return;
      }
    },
    [locale],
  );

  const showHasuraTestTypesByLocale = useCallback(
    (type: Hasura_Test_Types_Enum) => {
      switch (type) {
        case Hasura_Test_Types_Enum.DnaSkin:
          return locale?.dnaSkinTestSelectOption;
        case Hasura_Test_Types_Enum.DnaVitamin:
          return locale?.dnaVitaminTestSelectOption;
        case Hasura_Test_Types_Enum.ZrtHeavyMetals:
          return locale?.zrtHeavyMetalsTestSelectOption;
        case Hasura_Test_Types_Enum.ZrtHormone:
          return locale?.zrtHormoneTestSelectOption;
        case Hasura_Test_Types_Enum.ZrtNeurotransmitters:
          return locale?.zrtNeurotransmittersTestSelectOption;
        case Hasura_Test_Types_Enum.ZrtInflammatory:
          return locale?.zrtInflamatoryTestSelectOption;

        default:
          console.error(`Unexpected type was received: ${type}`);
          return;
      }
    },
    [locale],
  );

  const populateDnaDataTestData = (
    testKitData: GetFhirDnaVisitAndZrtTestsQuery,
  ) => {
    const populatedTests: TestKitsTableDataWithPatientInfo[] = [];
    const existingProviders: string[] = [];
    if (testKitData.getFHIRDnaAndZrtTests.dna_tests.length > 0) {
      for (const test of testKitData.getFHIRDnaAndZrtTests.dna_tests) {
        const populatedDnaDataTest: TestKitsTableDataWithPatientInfo = {
          date: formatDateToCustomFormat(test.created_at),
          'test type': showHasuraTestTypesByLocale(test.test_type),
          provider: test.provider_name || '',
          status: test.state as unknown as Dnavisit_Test_Statuses_Enum,
          testID: test.sapphiros_barcode,
          patientPdfId: test.patient_pdf_id || '',
          swabCode: test.sapphiros_barcode,
          patientName: `${patientDetails?.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_firstname} ${patientDetails?.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_lastname}`,
          patientProfileImageSrc:
            patientDetails?.getFHIRPatientbyCodexIDParameter.users[0]
              .SENSITIVE_profile_picture_id || '',
          id: test.diagnosticReportId,
          providerCodexId: test.provider_codex_id || '',
          fhirId: test.diagnosticReportId,
        };
        populatedTests.push(populatedDnaDataTest);
        if (test.provider_name) {
          existingProviders.push(test.provider_name);
        }
      }
    }

    if (testKitData.getFHIRDnaAndZrtTests.zrt_tests.length > 0) {
      for (const test of testKitData.getFHIRDnaAndZrtTests.zrt_tests) {
        const populatedZrtTest: TestKitsTableDataWithPatientInfo = {
          date: formatDateToCustomFormat(test.created_at),
          'test type': showHasuraTestTypesByLocale(test.test_type),
          provider: test.provider_name || '',
          status:
            MapFhirZrtToDnaVisitTestState.get(test.state) ||
            Dnavisit_Test_Statuses_Enum.KitNotReceived,
          testID: test.swab_code,
          patientPdfId: extractUUIDFromResURL(test.patient_pdf_id || ''),
          patientVisitSummaryPdfId: null,
          swabCode: test.swab_code,
          patientName: `${patientDetails?.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_firstname} ${patientDetails?.getFHIRPatientbyCodexIDParameter.users[0].SENSITIVE_lastname}`,
          patientProfileImageSrc:
            patientDetails?.getFHIRPatientbyCodexIDParameter.users[0]
              .SENSITIVE_profile_picture_id || '',
          id: test.fhirId,
          fhirId: test.fhirId,
          providerCodexId: test.provider_codex_id || '',
        };
        populatedTests.push(populatedZrtTest);
        if (test.provider_name) {
          existingProviders.push(test.provider_name);
        }
      }
    }
    const uniqueProviders = [
      ...new Set([...providerOptions, ...existingProviders]),
    ];
    setProvidersOptions(uniqueProviders);
    setUserDataTests(populatedTests);
  };

  const { data: patientDetails } = useGetProviderPatientDetailsQuery({
    variables: {
      patientCodexId: patientId || '',
    },
  });

  const [getFhirTests, { loading: loadingFhirTests, refetch, fetchMore }] =
    useGetFhirDnaVisitAndZrtTestsLazyQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        limit: 10,
        patientCodexId: patientId || '',
      },
      onCompleted: (data) => {
        if (data.getFHIRDnaAndZrtTests) {
          populateDnaDataTestData(data);
          setTestTotal(data.getFHIRDnaAndZrtTests.total);
        }
      },
    });

  useEffect(() => {
    if (isFirstRun.current && locale) {
      isFirstRun.current = false;
      getFhirTests({
        variables: {
          limit: 10,
        },
      });
    }
  }, [locale, getFhirTests]);

  const toggleContentVisibility = () => setIsContentVisible(!isContentVisible);
  const handleOnFromDateChange = (date: string) => {
    if (toDate && date && new Date(date).getTime() > new Date(toDate).getTime())
      setToDate('');
    setFromDate(date);
  };

  const handleOnToDateChange = (date: string) => {
    if (
      fromDate &&
      date &&
      new Date(date).getTime() < new Date(fromDate).getTime()
    )
      setFromDate('');
    setToDate(date);
  };

  const handleOnUpdateFilters = useCallback(() => {
    const tempTestTypeEnum = viewTypeToTestTypesEnum(viewByType);

    const testType:
      | InputMaybe<Hasura_Test_Types_Enum | Hasura_Test_Types_Enum[]>
      | undefined = tempTestTypeEnum
      ? (MapHasuraToFhirDnaVisitTestType.get(
          tempTestTypeEnum,
        ) as Hasura_Test_Types_Enum) ?? null
      : null;

    refetch({
      from: fromDate ? `${formatMMDDYYYYtoYYYYMMDD(fromDate)}T00:00:00Z` : null,
      to: toDate ? `${formatMMDDYYYYtoYYYYMMDD(toDate)}T23:59:59Z` : null,
      patientCodexId: patientId || '',
      providerName: providerName,
      find: find,
      testType,
      limit: NUMBER_OF_ROWS,
      orderBy: getOrderMethodForDNATestResults(
        orderBy,
      ) as unknown as DiagnosticReportOrderByInput,
    });
  }, [
    fromDate,
    toDate,
    orderBy,
    patientId,
    providerName,
    find,
    viewByType,
    refetch,
    getOrderMethodForDNATestResults,
    viewTypeToTestTypesEnum,
  ]);

  const handleLoadMore = useCallback(() => {
    if (userDataTests) {
      const tempDNATestTypeEnum = viewTypeToTestTypesEnum(viewByType);

      fetchMore({
        variables: {
          offset: userDataTests.length,
          codexPatientId: patientId || '',
          limit: userDataTests.length + NUMBER_OF_ROWS,
          from: fromDate
            ? `${formatMMDDYYYYtoYYYYMMDD(fromDate)}T00:00:00Z`
            : null,
          to: toDate ? `${formatMMDDYYYYtoYYYYMMDD(toDate)}T23:59:59Z` : null,
          orderby: getOrderMethodForDNATestResults(orderBy),
          find: find,
          testType: tempDNATestTypeEnum
            ? (MapHasuraToFhirDnaVisitTestType.get(
                tempDNATestTypeEnum,
              ) as Hasura_Test_Types_Enum)
            : null,
          providerName: providerName,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          return !fetchMoreResult
            ? prev
            : {
                ...prev,
                getFHIRDnaAndZrtTests: {
                  total: fetchMoreResult.getFHIRDnaAndZrtTests.total,
                  dna_tests: [
                    ...fetchMoreResult.getFHIRDnaAndZrtTests.dna_tests,
                  ],
                  zrt_tests: [
                    ...fetchMoreResult.getFHIRDnaAndZrtTests.zrt_tests,
                  ],
                },
              };
        },
      });
    }
  }, [
    userDataTests,
    fromDate,
    toDate,
    orderBy,
    patientId,
    providerName,
    find,
    viewByType,
    fetchMore,
    getOrderMethodForDNATestResults,
    viewTypeToTestTypesEnum,
  ]);

  const onColumnSortClicked = (orderBy: Record_Order_By) => {
    setOrderBy(orderBy);
    handleOnUpdateFilters();
  };

  const { user } = useAuth();

  useChannelSignal(
    (first) => {
      if (!first) refetch();
    },
    SIGNAL_CHANNELS.TESTKIT,
    user?.uuid,
  );

  if (loading || !locale || loadingFhirTests) return <Loader />;

  return (
    <div className="rounded-10 bg-white p-5 mt-[30px] desktop:p-[30px] m-5">
      <div className="flex flex-col desktop:flex-row desktop:items-center">
        <h4 className="text-h4 text-dark-gray font-semibold">
          {locale?.history}
        </h4>
        <p className="text-med-gray text-h6 desktop:ml-2.5">
          {locale?.showing} {userDataTests.length} {locale?.of} {testTotal}
        </p>
      </div>
      <DNATestKitFilters
        isContentVisible={isContentVisible}
        providerName={providerName}
        fromDate={fromDate}
        toDate={toDate}
        viewByType={viewByType}
        find={find}
        sortByMobile={sortByMobile}
        providers={providerOptions}
        onToggleContentVisibility={toggleContentVisibility}
        onProviderSearchChange={(providerName) => setProviderName(providerName)}
        onFromDateChange={(newFromDate) => handleOnFromDateChange(newFromDate)}
        onToDateChange={(newToDate) => handleOnToDateChange(newToDate)}
        onViewTypeChange={(viewType) => setViewByType(viewType)}
        onFindSearchChange={(find) => setFind(find)}
        handleOnUpdateFilters={handleOnUpdateFilters}
        onSortByMobileChange={(sortByMobile) => setSortByMobile(sortByMobile)}
        locale={locale}
      />

      {userDataTests.length > 0 && (
        <DNATestKitTable
          data={userDataTests}
          locale={locale}
          orderBy={orderBy}
          setOrderBy={onColumnSortClicked}
        />
      )}

      <div
        className={twMerge(
          `flex flex-col desktop:flex-row justify-between items-center desktop:mt-5 ${
            userDataTests.length === testTotal ? 'justify-end' : ''
          }`,
        )}
      >
        {userDataTests.length < testTotal && (
          <div
            className="flex justify-center items-center mt-5 desktop:justify-start hover:cursor-pointer"
            onClick={handleLoadMore}
          >
            <ButtonComponent
              type="underline"
              className="w-auto uppercase font-bold text-sm"
            >
              {locale?.seeMore}
            </ButtonComponent>
            <DownArrow className="w-3.5 h-3.5 fill-clc-blue ml-2.5" />
          </div>
        )}
      </div>

      {userDataTests.length === 0 && (
        <p className="mt-[30px] font-semibold text-dark-gray">
          {locale?.noMatchesFoundForSearchFilters}
        </p>
      )}
    </div>
  );
};
