import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { useEffect, useRef } from 'react';
import { TabProps, TabsRefs } from '../interfaces/tab.interface';
import { twMerge } from 'tailwind-merge';

export const Tabs: React.FC<TabProps> = ({
  activeTab,
  setActiveTab,
  tabs,
  customTabsProps = '',
  customContainerProps = '',
  activeColor = 'text-clc-blue',
  inactiveColor = 'text-neutral-500',
}) => {
  const isFirstSelected = activeTab === tabs[0].id;
  const isLastSelected = activeTab === tabs[tabs.length - 1].id;
  const handleArrowButtonClick = (direction: -1 | 1) => {
    const currentTabIndex = tabs.findIndex((tab) => tab.id === activeTab);
    const nextTabIndex = currentTabIndex + direction;
    if (nextTabIndex < 0 || nextTabIndex >= tabs.length) return;
    setActiveTab(tabs[nextTabIndex].id);
  };
  const tagRefs: React.MutableRefObject<TabsRefs> = useRef({});
  const divRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

  const centerTab = (tabId: string) => {
    const offsetLeft = tagRefs.current[tabId]?.offsetLeft || 0;
    const halfContainerWidth = Math.round(
      (divRef.current?.clientWidth || 0) / 2,
    );
    const halfTabWidth = Math.round(
      (tagRefs.current[tabId]?.clientWidth || 0) / 2,
    );
    const newOffsetLeft = offsetLeft - halfContainerWidth + halfTabWidth;
    divRef.current?.scrollTo({
      left: newOffsetLeft,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    centerTab(activeTab);
  }, [activeTab]);

  return (
    <div className="flex flex-row w-full justify-between gap-3">
      <div
        className={`
            flex justify-center items-center w-7 desktop:hidden
            transition-opacity duration-300 ease-in-out
            ${isFirstSelected ? 'opacity-0' : 'opacity-100'}
            ${isFirstSelected ? 'cursor-auto' : 'cursor-pointer'}
        `}
        tabIndex={2}
        onClick={() => handleArrowButtonClick(-1)}
      >
        <ChevronLeftIcon className="h-4 w-7" />
      </div>
      <div
        className="grow flex flex-row relative overflow-x-auto animate-[tabNavigationScrollKeyframe_linear] no-scrollbar"
        style={{
          scrollTimelineName: '--tab-scroll',
          scrollTimelineAxis: 'inline',
          animationTimeline: '--tab-scroll',
          mask: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) var(--scrollable-percentage-left), rgba(0,0,0,1) var(--scrollable-percentage-right), rgba(0,0,0,0) 100%)',
        }}
        ref={divRef}
      >
        <div
          className={twMerge(
            'flex flex-row w-max gap-[30px] desktop:w-full desktop:justify-between',
            customContainerProps,
          )}
        >
          {tabs.map((tab) => {
            const isActive = activeTab === tab.id;
            return (
              <div
                key={tab.id}
                className={`
                border-b-4 border-t-4 border-clc-blue border-t-transparent
                justify-center items-center w-max cursor-pointer py-2
                transition-all duration-400 ease-in-out
                ${isActive ? 'border-clc-blue' : 'border-transparent'}
              `}
                onFocus={() => centerTab(tab.id)}
                tabIndex={1}
                onClick={() => {
                  setActiveTab(tab.id);
                }}
                onKeyDown={(e) => {
                  const enterKey = 'Enter';
                  if (e.key === enterKey) {
                    setActiveTab(tab.id);
                  }
                }}
                ref={(el) => (tagRefs.current[tab.id] = el)}
              >
                <div
                  className={twMerge(
                    `
                    text-center text-clc-blue text-sm font-bold w-max
                    transition-all duration-400 ease-in-out
                    flex flex-row justify-center items-center gap-2
                    ${isActive ? activeColor : inactiveColor}
                `,
                    customTabsProps,
                  )}
                >
                  {tab.Icon && <tab.Icon />}
                  {tab.display}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={`
            flex justify-center items-center w-7 desktop:hidden
            transition-opacity duration-100 ease-in-out
            ${isLastSelected ? 'opacity-0' : 'opacity-100'}
            ${isLastSelected ? 'cursor-auto' : 'cursor-pointer'}
        `}
        onClick={() => handleArrowButtonClick(1)}
        tabIndex={2}
      >
        <ChevronRightIcon className="h-4 w-7" />
      </div>
    </div>
  );
};
