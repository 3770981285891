import ErrorMessageWithIcon from 'components/errorMessageWithIcon';
import InputComponent from 'components/inputComponent';

export interface AppointmentPreworkRadioListProps {
  selected: string | undefined;
  setSelected: (selected: string) => void;
  items: string[];
  errorState?: string | null;
}

const AppointmentPreworkRadioList: React.FC<
  AppointmentPreworkRadioListProps
> = ({ items, selected, setSelected, errorState }) => {
  const onRadioClick = (selected: string) => () => {
    setSelected(selected);
  };
  return (
    <div className="flex flex-col">
      {items.map((item) => (
        <InputComponent
          key={item}
          type="radio"
          radioInputProps={{
            radioLabelTextWeight: 'font-semibold text-med-gray',
            radioError: errorState ?? null,
            radioInputValue: item,
            radioInputLabel: item,
            radioInputCheckedValue: selected,
            onRadioClick: onRadioClick(item),
          }}
          testID={`appointment-prework-radio-${item}`}
        />
      ))}
      {!!errorState && <ErrorMessageWithIcon message={errorState} />}
    </div>
  );
};

export default AppointmentPreworkRadioList;
