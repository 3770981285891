interface CarouselArrowProps {
  onClick: () => void;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label?: string;
  iconPosition?: 'left' | 'right';
}

const CarouselArrow: React.FC<CarouselArrowProps> = ({
  onClick,
  icon: Icon,
  label,
  iconPosition = 'left',
}) => (
  <div
    className="flex flex-row items-center p-0 cursor-pointer"
    onClick={onClick}
  >
    {iconPosition === 'left' && (
      <Icon className="w-[15px] h-[25px] fill-current" />
    )}

    {label && (
      <span className="text-sm ml-2 uppercase text-clc-blue font-bold">
        {label}
      </span>
    )}

    {iconPosition === 'right' && (
      <Icon className="w-[15px] h-[25px] fill-current ml-2" />
    )}
  </div>
);

export default CarouselArrow;
