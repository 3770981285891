interface NotificationItemProps {
  body: string;
  date: string;
  isRead: boolean;
  isSelect?: boolean;
  textColor?: string;
  onClick?: () => void;
  isWidget?: boolean;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
  body,
  date,
  isRead = false,
  isSelect = false,
  textColor = 'text-med-gray',
  onClick,
  isWidget = false,
}) => {
  const containerClasses = isWidget
    ? 'cursor-pointer flex flex-col items-start w-full gap-3 py-4'
    : 'cursor-pointer flex flex-col items-start w-full gap-3 p-4 desktop:hover:bg-light-blue';

  return (
    <div className={containerClasses} onClick={onClick}>
      <div className="flex flex-row gap-4 group">
        <p
          className={`${
            isRead
              ? isSelect
                ? textColor
                : 'text-med-gray'
              : isSelect
              ? textColor
              : 'font-bold'
          } ${isWidget ? (isSelect ? null : 'hover:text-midnight-blue') : null}
              `}
        >
          {body}
        </p>
        <div className={isRead ? 'hidden' : 'w-2'} data-testid="is-read-badge">
          <div
            className={`h-3 w-3 rounded-full mt-2 ${
              isRead || isSelect ? '' : 'bg-clc-blue'
            }`}
          />
        </div>
      </div>
      <div>
        <p className="text-med-gray text-sm">{date}</p>
      </div>
    </div>
  );
};
