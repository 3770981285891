import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Modal from '../../../components/modal/modalComponent';
import { ModalButtonProps } from '../../../components/modal/modal.interfaces';
import { genericActionsIds, pageIds } from '../../../utilities/constants';
import { useGetGenericActions } from '../../../hooks/useGetGenericActions';
import { useGetPage } from '../../../hooks/useGetPage';
import InputComponent from '../../../components/inputComponent';
import ButtonComponent from '../../../components/button/buttonComponent';
import { onlyDigitsPattern } from '../../../utilities/variables';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { AuthContext } from '../../context/AuthContext';
import { FirebaseLocale } from '../../../firebase/interfaces';
import { useFirebaseSmsMfa } from '../../../firebase/hooks';
import Alert from '../../../components/alertComponent';
import { ReactComponent as CloseIcon } from 'assets/icons/notificationClose.svg';
import { useTimer } from '../../../hooks/useTimer';
import { SECONDS_IN_MIN } from '../../../utilities/constants/time';
import { AUTH_MFA_PROTECT } from '../../../utilities/routes';
import { useNavigate } from 'react-router-dom';
import StorageService from '../../../utilities/storageService';
import { HASURA_USER_STORAGE_KEY } from '../../../firebase/constants';

interface IMultiFactorAuthVerifyModal {
  isOpen: boolean;
  onClose: () => void;
  recaptchaContainerWrapper: React.RefObject<HTMLDivElement>;
}

const hasuraUserStorage = new StorageService(HASURA_USER_STORAGE_KEY);

const MultiFactorAuthVerifyModal: React.FC<IMultiFactorAuthVerifyModal> = ({
  isOpen,
  recaptchaContainerWrapper,
  onClose,
}) => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MULTI_FACTOR_AUTH,
  });

  const { data: firebaseData, loading: firebaseLocaleLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.FIREBASE_ERRORS,
  });

  const [code, setCode] = useState<string>('');
  const [isSubmittingCode, setIsSubmittingCode] = useState(false);

  const {
    timeLeft,
    temporalyDisableResendActions,
    setTimeLeft,
    setTemporalyDisableResendActions,
  } = useTimer();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const firstRendered = useRef(false);

  const firebaseLocale: FirebaseLocale = firebaseData;

  const {
    isCaptchaResolved,
    isVerified,
    handleSendVerificationCode,
    handleVerifyCode,
    errorMsg,
  } = useFirebaseSmsMfa(firebaseLocale);

  const [isShowDescriptions, setShowDescription] = useState<boolean>(true);
  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCode(e.target.value);

  const handleSendCode = useCallback(
    async (recaptchaContainerWrapper: HTMLElement) => {
      if (!recaptchaContainerWrapper || !user?.phoneNumber || !user?.country) {
        return;
      }
      const callingCode = user.country.replace(onlyDigitsPattern, '');
      const formattedPhoneNumber = parsePhoneNumberFromString(
        user.phoneNumber,
        { defaultCallingCode: callingCode },
      );

      if (formattedPhoneNumber) {
        hasuraUserStorage.addData({ phone: formattedPhoneNumber.number });
        await handleSendVerificationCode(
          formattedPhoneNumber.number,
          'recaptcha-container',
        );
      }
    },
    [handleSendVerificationCode, user],
  );

  const handleResendCode = () => {
    if (!recaptchaContainerWrapper.current) {
      return;
    }
    setTemporalyDisableResendActions(true);
    setTimeLeft(SECONDS_IN_MIN);
    handleSendCode(recaptchaContainerWrapper.current);
  };

  const handleSubmitCode = async () => {
    setIsSubmittingCode(true);
    try {
      await handleVerifyCode(code);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSubmittingCode(false);
    }
  };

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CONFIRM, genericActionsIds.CANCEL],
    });

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.CONFIRM]?.confirm,
      onClick: handleSubmitCode,
      disabled: !isCaptchaResolved || isSubmittingCode || !code,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL]?.cancel,
      type: 'underline',
      onClick: onClose,
    },
  ];

  useEffect(() => {
    if (
      recaptchaContainerWrapper.current &&
      !firstRendered.current &&
      ((!loading && locale) || (!firebaseLocaleLoading && firebaseLocale))
    ) {
      firstRendered.current = true;
      handleSendCode(recaptchaContainerWrapper.current);
    }
  }, [
    handleSendCode,
    loading,
    locale,
    firebaseLocaleLoading,
    firebaseLocale,
    recaptchaContainerWrapper,
  ]);

  useEffect(() => {
    if (isVerified) navigate(AUTH_MFA_PROTECT);
  }, [isVerified, navigate]);

  if ((loading && !locale) || (genericActionLoading && !genericAction)) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={locale?.mfaVerifyModal?.title}
      buttons={Buttons}
      modalDesktopWidth="desktop:max-w-[600px]"
      titleCustomClass="desktop:!text-h3"
    >
      <div className="flex flex-col gap-[30px] pt-[60px] desktop:py-2">
        {isShowDescriptions && (
          <Alert
            type="info-new"
            desktopItemsAlign="desktop:items-start"
            customContent={
              <>
                <CloseIcon
                  className={
                    'absolute stroke-med-gray w-5 h-5 top-4 right-4 cursor-pointer'
                  }
                  onClick={() => setShowDescription(false)}
                />
                <p className="text-med-gray desktop:w-[350px]">
                  {locale?.mfaVerifyModal?.descriptions}
                </p>
              </>
            }
          />
        )}
        {errorMsg && (
          <div>
            <Alert type="warning" text={errorMsg} />
          </div>
        )}
        <div className="flex flex-col gap-2.5 w-full desktop:max-w-[320px] desktop:mx-auto">
          <label
            className="font-base font-semibold text-dark-gray"
            htmlFor="Verification code"
          >
            {locale?.mfaVerifyModal?.inputLabel}
          </label>

          <InputComponent
            testID="verification-code-input"
            type="text"
            name="Verification Code"
            onChange={handleOnInputChange}
            value={code}
          />
        </div>
        <div className="flex w-full justify-center desktop:mx-auto">
          <span className="text-med-gray mr-2">
            {locale?.mfaVerifyModal?.resendLabel}
          </span>
          <ButtonComponent
            onClick={handleResendCode}
            type="underline"
            disabled={temporalyDisableResendActions}
            noUpperCase
          >
            {locale?.mfaVerifyModal?.resendButton}
          </ButtonComponent>
          {temporalyDisableResendActions && (
            <span className="ml-2">{timeLeft}</span>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MultiFactorAuthVerifyModal;
