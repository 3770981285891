import { ChangeEvent, FormEvent, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useConfirmPasswordReset } from '../../../firebase/hooks';
import { ValidationRules } from 'hooks/useFormValidation';
import { pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import ButtonComponent from 'components/button/buttonComponent';
import InputComponent from 'components/inputComponent';
import useFormValidation from 'hooks/useFormValidation';
import { AUTH_LOGIN } from 'utilities/routes';
import { passwordPattern } from 'utilities/variables';

export const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.RESET_PASSWORD,
  });

  const validationRules: (password: string) => ValidationRules = (
    password: string,
  ) => ({
    password: [
      {
        validator: (value) => value.length > 0,
        message: locale?.passwordRequiredError,
      },
      {
        validator: (value) => new RegExp(passwordPattern).test(value),
        message: locale?.passwordNotValid,
      },
    ],
    confirmedPassword: [
      {
        validator: (value) => value.length > 0,
        message: locale?.passwordRequiredError,
      },
      {
        validator: (value) => value === password,
        message: locale?.confirmPasswordMismatch,
      },
      {
        validator: (value) => new RegExp(passwordPattern).test(value),
        message: locale?.passwordNotValid,
      },
    ],
  });

  const queryParams = new URLSearchParams(location.search);
  const oobCode = queryParams.get('oobCode');
  const [formData, setFormData] = useState({
    password: '',
    confirmedPassword: '',
  });
  const [errorMsg, handleConfirmPasswordReset] = useConfirmPasswordReset();
  const { errors, validateField, validateForm } = useFormValidation(
    validationRules(formData.password),
  );

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm(formData) && oobCode) {
      const result = await handleConfirmPasswordReset(
        oobCode,
        formData.password,
      );
      if (result) {
        navigate(`${AUTH_LOGIN}?resetPassword=true`, { replace: true });
      }
    }
  };

  if (loading && !locale) return null;

  return (
    <div className="flex flex-col items-center desktop:justify-center px-[20px] py-[30px] desktop:px-[160px] desktop:py-[40px] gap-[30px] rounded-10 bg-white">
      <div className="flex flex-col items-start desktop:items-center px-[10px] desktop:p-0 gap-2.5 desktop:gap-[5px]">
        <p className="text-center text-h2 desktop:text-h1 text-dark-gray">
          {locale?.resetPasswordTitle}
        </p>
        <p className="text-center text-base font-medium text-med-gray">
          {locale?.instructionText}
        </p>
      </div>

      <form className="flex flex-col w-full gap-[30px]" onSubmit={handleSubmit}>
        <div className="flex flex-col w-full items-start p-0 gap-[20px]">
          <div className="flex flex-col w-full items-start p-0 gap-[5px]">
            <label
              className="text-base text-dark-gray font-semibold"
              htmlFor="password"
            >
              {locale?.passwordLabel}
            </label>
            <InputComponent
              testID="password-input"
              type="password"
              name="password"
              onChange={handleOnInputChange}
              errorMsg={errors.password}
              maxLengthValue={64}
              noMarginBottom
            />
          </div>

          <div className="flex flex-col w-full items-start p-0 gap-[5px]">
            <label
              className="text-base text-dark-gray font-semibold"
              htmlFor="confirmedPassword"
            >
              {locale?.confirmPasswordLabel}
            </label>
            <InputComponent
              testID="confirm-password-input"
              type="password"
              name="confirmedPassword"
              onChange={handleOnInputChange}
              errorMsg={errors.confirmedPassword}
              maxLengthValue={64}
              noMarginBottom
            />
          </div>
        </div>

        <div className="flex flex-row items-center justify-center">
          <ButtonComponent
            testID="reset-button"
            type="submit"
            paddingX="px-10"
            paddingY="py-[9.5px] desktop:py-[17px]"
            disabled={false}
          >
            {locale?.resetPasswordButton}
          </ButtonComponent>
        </div>
        {errorMsg && <p className="text-red text-center">{errorMsg}</p>}
      </form>

      <div className="flex flex-row items-center" data-testid="link-container">
        <Link
          className="text-clc-blue text-base font-bold underline text-center"
          to={'/auth/login'}
        >
          {locale?.returnToLoginLink}
        </Link>
      </div>
    </div>
  );
};
