import {
  CustomClassNamesProps,
  KeyMapProps,
} from 'components/codexTable/interfaces/keymap.interfaces';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import {
  AppointmentTypeEnum,
  InputMaybe,
  Order_By,
} from 'graphql/generated/hasura';
import {
  appointmentRenderInfo,
  AppointmentHistoryRowProps,
} from '../../interfaces';
import { useMyAppointmentsLocale } from 'app/my-appointments/pages/MyAppointments';
import ButtonComponent from 'components/button/buttonComponent';
import { useContext } from 'react';
import { AuthContext, useAuth } from 'auth/context/AuthContext';
import { Roles } from '../../../../firebase/interfaces';
import { useNavigate } from 'react-router-dom';
import {
  MY_APPOINTMENTS_PATIENT_MYVISIT_SUMMARY_MODAL,
  MY_APPOINTMENTS_PROVIDER_MYVISIT_SUMMARY_MODAL,
  MY_APPOINTMENTS_PROVIDER_OVERVIEW,
  MY_PATIENTS,
  MY_PATIENTS_BIO_INFO,
} from 'utilities/routes';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import CompleteMyVisitSummaryModal from 'app/my-appointments/components/CompleteMyVisitSummaryModal';
import { useModal } from 'layout/useModal';

const columns = [
  'date',
  'provider',
  'type',
  'billed',
  'status',
  'log',
] as const;
type Historic_Appointments_Order_By = Partial<
  Record<(typeof columns)[number], InputMaybe<Order_By>>
>;

export const keyMap: KeyMapProps = {
  date: {
    column: 'date',
    isActive: (orderBy: Record_Order_By) => (orderBy.date ? true : false),
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.date || Order_By.Desc,
  },
  name: {
    column: 'name',
    isActive: (orderBy: Record_Order_By) => (orderBy.provider ? true : false),
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.provider || Order_By.Desc,
    FormatValue: ({ value, entry }) => {
      const navigate = useNavigate();
      const patientId = (entry as AppointmentHistoryRowProps).patientCodexId;
      const { user } = useAuth();
      const handleOnClick = () => {
        if (user?.role !== Roles.PROVIDER) return;
        const path = `${MY_PATIENTS}/${patientId}/${MY_PATIENTS_BIO_INFO}`;
        navigate(path);
      };
      return (
        <p
          className={`text-black underline underline-offset-4 ${
            user?.role === Roles.PROVIDER ? 'cursor-pointer' : ''
          }`}
          onClick={handleOnClick}
        >
          {value}
        </p>
      );
    },
  },
  type: {
    column: 'type',
    isActive: (orderBy: Record_Order_By) => (orderBy.type ? true : false),
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.type || Order_By.Desc,
    FormatValue: ({ value }) => {
      const Icon = appointmentRenderInfo[value as AppointmentTypeEnum].icon;
      return <Icon className="inline" />;
    },
  },
  logs: {
    column: 'log',
    isActive: (orderBy: Record_Order_By) => (orderBy.log ? true : false),
    direction: (orderBy: Historic_Appointments_Order_By) =>
      orderBy.log || Order_By.Desc,
    FormatValue: ({ value }) => {
      if (!Array.isArray(value)) return <>{value}</>;
      return (
        <ul className="list-disc list-inside px-2">
          {value.map((log) => (
            <li key={log} className="text-base">
              {log}
            </li>
          ))}
        </ul>
      );
    },
  },
};

export const customClassesDesktop: CustomClassNamesProps = {
  date: {
    customRowClassName: 'text-left pt-3.5 align-top',
    spanClass: 'pl-0',
  },
  name: {
    customRowClassName: 'text-left pt-3.5 align-top',
    spanClass: 'pl-0',
  },
  subject: {
    customRowClassName: 'text-left pt-3.5 align-top',
    spanClass: 'pl-0',
  },
  type: {
    customRowClassName: 'text-center pt-3.5 align-top pl-0',
    spanClass: 'pl-0',
    flexContainerClass: 'justify-center',
  },
  logs: {
    customRowClassName: 'text-left pt-3.5 align-top pl-0',
    spanClass: 'pl-0',
  },
  actions: {
    customRowClassName: 'pt-3.5 align-top',
  },
};

export const HistoricAppointmentsActionElement: (
  element: AppointmentHistoryRowProps,
) => JSX.Element = (element) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { locale: localeHook } = useMyAppointmentsLocale();
  const locale = { ...element.locale, ...localeHook };
  const [openMyVisitSummaryModal] = useModal(CompleteMyVisitSummaryModal);

  const handleNavigateCompleteSummaryModal = () => {
    openMyVisitSummaryModal({ appointmentId: element.id });
  };

  const handleNavigateMySummaryModal = () => {
    if (user?.role === Roles.PROVIDER) {
      navigate(MY_APPOINTMENTS_PROVIDER_MYVISIT_SUMMARY_MODAL, {
        state: { appointmentId: element.id },
      });
    } else {
      navigate(MY_APPOINTMENTS_PATIENT_MYVISIT_SUMMARY_MODAL, {
        state: { appointmentId: element.id },
      });
    }
  };

  const handleNavigateAppoinmetnOverview = () => {
    if (user?.role === Roles.PROVIDER) {
      navigate(MY_APPOINTMENTS_PROVIDER_OVERVIEW, {
        state: { appointmentId: element.id },
      });
    } else {
      navigate(
        `.?${PARAM_MODALS_IDENTIFIERS.VIEW_APPOINTMENT_DETAILS_MODAL_PATIENT}=true`,
        {
          state: { appointmentId: element.id },
        },
      );
    }
  };

  return (
    <div className="flex flex-col items-end min-h-full">
      <div className="flex flex-col items-end gap-5">
        {!element.summaryCompleted && user?.role === Roles.PROVIDER && (
          <ButtonComponent
            type="outlined"
            onClick={handleNavigateCompleteSummaryModal}
            className=" text-sm font-bold"
          >
            {locale.completeYourSummary}
          </ButtonComponent>
        )}
        <ul className="list-disc list-inside px-2 w-fit">
          <li
            key={locale.appointmentOverview}
            onClick={handleNavigateAppoinmetnOverview}
            className="text-base cursor-pointer text-clc-blue font-bold underline uppercase"
          >
            {locale.appointmentOverview}
          </li>
          {element.summaryCompleted && user?.role === Roles.PROVIDER && (
            <li
              key={locale.getFHIRProviderbyCodexIdArgument}
              onClick={handleNavigateMySummaryModal}
              className="text-base cursor-pointer text-clc-blue font-bold underline uppercase"
            >
              {locale.getFHIRProviderbyCodexIdArgument}
            </li>
          )}
          {element.summaryCompleted && user?.role === Roles.PATIENT && (
            <li
              key={locale.appointmentMyVisitSummary}
              onClick={handleNavigateMySummaryModal}
              className="text-base cursor-pointer text-clc-blue font-bold underline uppercase"
            >
              {locale.appointmentMyVisitSummary}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
