import { ChangeEvent, FormEvent, useEffect, useState, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Enum_Legaldocument_Legaldocumenttype } from 'graphql/generated/strapi';
import { useGetLegalDocuments } from 'hooks/legalDocuments/useGetLegalDocuments';
import { useGoogleSignupWithCredentials } from '../../../firebase/hooks';
import {
  emailPattern,
  min7OnlyDigitsPattern,
  passwordPattern,
} from 'utilities/variables';
import { DEFAULT_COUNTRY_CODE_NUMBER, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useGetCountriesQuery } from 'graphql/generated/hasura';

import { UserData } from './SignUp.interface';
import ButtonComponent from 'components/button/buttonComponent';
import InputComponent, {
  SelectWithFlagsValues,
} from 'components/inputComponent';
import LegalTerms from 'components/LegalTerms';
import ErrorMessageWithIcon from 'components/errorMessageWithIcon';
import AlertComponent from 'components/alertComponent';
import useFormValidation, { ValidationRules } from 'hooks/useFormValidation';
import {
  CountriesSelectValue,
  allowOnlyLettersWithAllowedCharacters,
  allowOnlyNumbers,
  normalizePhoneNumber,
  parseMappedCountriesIntoSelectValues,
} from 'utilities/functions';
import { AUTH_SEND_VERIFICATION_EMAIL } from 'utilities/routes';

export const SignUp = () => {
  const navigate = useNavigate();

  const { data } = useGetCountriesQuery({});

  const mappedCountriesValues = useMemo(() => {
    return data?.countries
      ? [...data.countries]
          .sort((a, b) => {
            const codea = parseInt(a.code);
            const codeb = parseInt(b.code);

            if (codea < codeb) {
              return -1;
            } else if (codea > codeb) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((country) => {
            return { code: country.code, flag: country.flag };
          })
      : [];
  }, [data?.countries]);

  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.SIGN_UP,
  });

  const validationRules: ValidationRules = {
    firstName: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
    ],
    lastName: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
    ],
    email: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(emailPattern).test(value),
        message: locale?.invalidEmail,
      },
    ],
    phoneNumber: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(min7OnlyDigitsPattern).test(value),
        message: locale?.fieldRequired,
      },
    ],
    password: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(passwordPattern).test(value),
        message: locale?.passwordNotValid,
      },
    ],
    confirmPassword: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
      {
        validator: (value) => new RegExp(passwordPattern).test(value),
        message: locale?.passwordNotValid,
      },
      {
        validator: (value, formData) => value === formData?.password,
        message: locale?.confirmPasswordMismatch,
      },
    ],
  };

  const requiredValidationRules: ValidationRules = {
    ...validationRules,
    email: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
    ],
    phoneNumber: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
    ],
    password: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
    ],
    confirmPassword: [
      {
        validator: (value) => !!value.length,
        message: locale?.fieldRequired,
      },
    ],
  };

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { errors, validateForm } = useFormValidation(validationRules);
  const { getIsFormValid } = useFormValidation(requiredValidationRules);

  const [userData, setUserData] = useState<UserData>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    extension: '',
    country: mappedCountriesValues[0]?.code ?? DEFAULT_COUNTRY_CODE_NUMBER,
    email: '',
    password: '',
    confirmPassword: '',
    isChecked: false,
  });

  const { legalDocuments } = useGetLegalDocuments({
    locale: 'en',
    legalDocumentTypes: [
      Enum_Legaldocument_Legaldocumenttype.TermsOfService,
      Enum_Legaldocument_Legaldocumenttype.PrivacyPolicy,
    ],
  });

  const [registeredUser, firebaseError, handleGoogleSignupWithCredentials] =
    useGoogleSignupWithCredentials();

  const [formattedNumber, setFormattedNumber] = useState('');

  const handleValidation = (userData: UserData) => {
    const formData = JSON.parse(JSON.stringify(userData));
    delete formData.isChecked;
    return validateForm(formData);
  };

  const isFormValid = (userData: UserData) => {
    const formData = JSON.parse(JSON.stringify(userData));
    delete formData.isChecked;
    return getIsFormValid(formData);
  };

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const fieldName = e.target.name;

    if (fieldName === 'phoneNumber') {
      setFormattedNumber(normalizePhoneNumber(value));
      value = normalizePhoneNumber(value);
    }

    const nextData = { ...userData, [fieldName]: value };

    let nextProcessedData = { ...userData };

    if (
      (fieldName === 'firstName' || fieldName === 'lastName') &&
      allowOnlyLettersWithAllowedCharacters(value)
    ) {
      nextProcessedData = { ...nextProcessedData, [fieldName]: value };
    } else if (
      fieldName === 'phoneNumberExtension' &&
      allowOnlyNumbers(value)
    ) {
      nextProcessedData = { ...nextProcessedData, extension: value };
    } else {
      nextProcessedData = { ...nextProcessedData, [fieldName]: value };
    }

    nextProcessedData = { ...nextProcessedData, [fieldName]: value };

    setUserData(nextProcessedData);

    if (isCheckboxChecked) {
      handleValidation(nextData);
    }
  };

  const handleCheckboxChange = (checked: boolean) => {
    setIsCheckboxChecked(checked);
  };

  const handleCountryChange = (e: SelectWithFlagsValues) => {
    setUserData({ ...userData, country: e.value });
  };

  const handleSignup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isCheckboxChecked) {
      return;
    }

    if (!handleValidation(userData)) {
      return;
    }

    const phoneNumberWithExtension =
      userData.phoneNumber +
      (userData.extension ? `ext${userData.extension} ` : '');

    handleGoogleSignupWithCredentials(
      userData.email,
      userData.password,
      legalDocuments || [],
      {
        firstname: userData.firstName,
        lastname: userData.lastName,
        phone: phoneNumberWithExtension,
        country: userData.country,
      },
    );
  };

  const getMemoizedDefaultParsedValue = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues)[0],
    [mappedCountriesValues],
  );

  const getMemoizedParsedCountries = useMemo(
    () => parseMappedCountriesIntoSelectValues(mappedCountriesValues),
    [mappedCountriesValues],
  );

  useEffect(() => {
    if ('token' in registeredUser && registeredUser.token) {
      navigate(AUTH_SEND_VERIFICATION_EMAIL, { replace: true });
    }
  }, [registeredUser, firebaseError, navigate]);

  if (loading && !locale) return null;

  return (
    <div className="bg-transparent overflow-hidden shadow desktop:rounded-lg desktop:mb-0 desktop:p-0 desktop:w-full desktop:max-w-screen-xl desktop:mx-auto">
      <form onSubmit={handleSignup} noValidate>
        <div className="w-full bg-white bg-border rounded-t-lg desktop:basis-3/5">
          <div className="flex flex-col gap-2.5 desktop:flex-row desktop:items-center desktop:justify-between px-5 py-5 desktop:px-[60px] desktop:pt-[30px]">
            <h3 className="text-h4  desktop:text-h3 text-dark-gray font-semibold">
              {locale?.title}
            </h3>
            <p className="text-sm text-clc-red font-bold">
              {locale?.requiredText}
            </p>
          </div>

          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />

          <div className="flex flex-col gap-2.5 px-5 py-[30px] desktop:px-[0.8vw] desktop:pt-[30px]">
            <div className="flex flex-col desktop:flex-row items-start desktop:items-center">
              <label
                className="w-full desktop:w-3/12 text-base desktop:text-right text-dark-gray font-semibold pr-2.5 mb-2 desktop:mb-0"
                htmlFor="firstName"
              >
                {locale?.firstNameLabel}{' '}
                <span className="text-base font-bold text-clc-red">
                  {locale?.required}
                </span>
              </label>
              <div className="w-full desktop:w-9/12">
                <InputComponent
                  testID="firstName-input"
                  type="text"
                  name="firstName"
                  value={userData.firstName}
                  onChange={handleOnInputChange}
                  errorStatus={!!errors.firstName}
                  errorMsgWithIcon={errors.firstName}
                  maxLengthValue={100}
                  noMarginBottom
                />
              </div>
            </div>

            <div className="flex flex-col desktop:flex-row items-start desktop:items-center">
              <label
                className="w-full desktop:w-3/12 text-base desktop:text-right text-dark-gray font-semibold pr-2.5 mb-2 desktop:mb-0"
                htmlFor="lastName"
              >
                {locale?.lastNameLabel}
                <span className="text-base font-bold text-clc-red">
                  {locale?.required}
                </span>
              </label>
              <div className="w-full desktop:w-9/12">
                <InputComponent
                  testID="lastName-input"
                  type="text"
                  name="lastName"
                  value={userData.lastName}
                  onChange={handleOnInputChange}
                  errorStatus={!!errors.lastName}
                  errorMsgWithIcon={errors.lastName}
                  maxLengthValue={100}
                  noMarginBottom
                />
              </div>
            </div>

            <div className="flex flex-col desktop:flex-row items-start desktop:items-center">
              <label
                className="w-full desktop:w-3/12 text-base desktop:text-right text-dark-gray font-semibold pr-2.5 mb-2 desktop:mb-0"
                htmlFor="email"
              >
                {locale?.emailLabel}
                <span className="text-base font-bold text-clc-red">
                  {locale?.required}
                </span>
              </label>
              <div className="w-full desktop:w-9/12">
                <InputComponent
                  testID="email-input"
                  type="email"
                  name="email"
                  onChange={handleOnInputChange}
                  errorStatus={!!errors.email}
                  errorMsgWithIcon={errors.email}
                  noMarginBottom
                />
              </div>
            </div>

            <div className="flex flex-col desktop:flex-row items-start desktop:items-center">
              <label
                className="w-full desktop:w-3/12 text-base desktop:text-right text-dark-gray font-semibold mb-2 pr-2.5"
                htmlFor="phoneNumber"
              >
                {locale?.phoneNumberLabel}
                <span className="text-base font-bold text-clc-red">
                  {locale?.required}
                </span>
              </label>
              <div className="w-full flex flex-col desktop:w-9/12 desktop:flex-row">
                <div className="flex flex-col w-auto items-start grow shrink">
                  <div className="flex w-full">
                    <InputComponent
                      testID="phoneNumber-country-select"
                      type="select-with-country-icons"
                      name="country"
                      borderRadiusRight={true}
                      selectInputWithFlagsProps={{
                        defaultValue:
                          getMemoizedDefaultParsedValue as SelectWithFlagsValues,
                        options:
                          getMemoizedParsedCountries as SelectWithFlagsValues[],
                        onSelectChange: (e) =>
                          handleCountryChange(e as CountriesSelectValue),
                      }}
                    />

                    <InputComponent
                      testID="phoneNumber-input"
                      type="tel"
                      name="phoneNumber"
                      onChange={handleOnInputChange}
                      flexGrow={1}
                      borderRadiusLeft={true}
                      value={formattedNumber}
                      errorStatus={!!errors.phoneNumber}
                    />
                  </div>
                  {errors.phoneNumber && (
                    <ErrorMessageWithIcon message={errors.phoneNumber} />
                  )}
                </div>

                <div className="flex flex-row items-start ml-3">
                  <label
                    className="mt-3  mb-[10px] mr-[10px] text-base text-dark-gray font-bold desktop:w-2/12 desktop:mb-2"
                    htmlFor="phoneNumberExtension"
                  >
                    {locale?.extLabel}
                  </label>

                  <InputComponent
                    type="ext"
                    name="phoneNumberExtension"
                    value={userData.extension}
                    onChange={handleOnInputChange}
                    maxLengthValue={4}
                    inputWidth="!desktop:w-[100px] w-full"
                    disableTelWidth
                  />
                </div>
              </div>
            </div>

            <hr className="flex flex-row w-full items-center h-px bg-black-blur mt-2.5 mb-3.5" />

            <div className="flex flex-col desktop:flex-row items-start desktop:items-center">
              <label
                className="w-full desktop:w-3/12 text-base desktop:text-right text-dark-gray font-semibold pr-2.5 mb-2 desktop:mb-0"
                htmlFor="password"
              >
                {locale?.passwordLabel}
                <span className="text-base font-bold text-clc-red">
                  {locale?.required}
                </span>
              </label>
              <div className="w-full desktop:w-9/12">
                <InputComponent
                  testID="password-input"
                  type="password"
                  name="password"
                  onChange={handleOnInputChange}
                  errorStatus={!!errors.password}
                  errorMsgWithIcon={errors.password}
                  maxLengthValue={64}
                  noMarginBottom
                />
              </div>
            </div>
            <div className="flex flex-col desktop:flex-row items-start desktop:items-center">
              <label
                className="w-full desktop:w-3/12 text-base desktop:text-right text-dark-gray font-semibold pr-2.5 mb-2 desktop:mb-0"
                htmlFor="password"
              >
                {locale?.confirmPasswordLabel}
                <span className="text-base font-bold text-clc-red">
                  {locale?.required}
                </span>
              </label>
              <div className="w-full desktop:w-9/12">
                <InputComponent
                  testID="confirm-password-input"
                  type="password"
                  name="confirmPassword"
                  onChange={handleOnInputChange}
                  errorStatus={!!errors.confirmPassword}
                  errorMsgWithIcon={errors.confirmPassword}
                  maxLengthValue={64}
                  noMarginBottom
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />

        <div className="w-full bg-white bg-border desktop:basis-3/5">
          <div className="flex flex-col gap-[30px] px-5 py-5 desktop:px-[60px] desktop:pt-5 desktop:pb-10">
            <div className="flex flex-row gap-2.5 items-start">
              <InputComponent
                testID="term-and-conditions-input"
                type="checkbox"
                name="agreementsCheckbox"
                checkboxProps={{
                  checked: isCheckboxChecked,
                  onCheckboxChange: handleCheckboxChange,
                }}
              />
              <label
                className="text-med-gray text-sm font-semibold"
                htmlFor="agreementsCheckbox"
              >
                <LegalTerms
                  linkClasses="text-clc-blue"
                  legalDocuments={legalDocuments || []}
                  openingLabel={locale?.termsConditionsLabel}
                  separatorLabel={locale?.termsConditionsLabel2}
                  closingLabel={locale?.termsConditionsLabel3}
                />
              </label>
            </div>

            <div className="flex justify-center">
              <ButtonComponent
                testID="signup-button"
                type="submit"
                paddingX="px-10"
                paddingY="py-[9.5px] desktop:py-[17px]"
                disabled={!isCheckboxChecked || !isFormValid(userData)}
              >
                {locale?.signupButton}
              </ButtonComponent>
            </div>
            {firebaseError && (
              <AlertComponent type="warning" text={firebaseError} />
            )}
          </div>
        </div>
      </form>
      <div className="bg-clc-blue/75 rounded-b-lg">
        <h5 className="text-h5 desktop:text-h4 text-center px-10 py-[17px] text-white font-bold">
          {locale?.alreadyAccountLabel}{' '}
          <Link className="underline" to="/auth/login">
            {locale?.signInHereLink}
          </Link>
        </h5>
      </div>
    </div>
  );
};
