import {
  useMemo,
  useState,
  DetailedHTMLProps,
  InputHTMLAttributes,
} from 'react';

type ElementProps<Element extends HTMLElement> = DetailedHTMLProps<
  InputHTMLAttributes<Element>,
  Element
>;

export default function useHover<Element extends HTMLElement>() {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const hoverProps: Partial<ElementProps<Element>> = useMemo(() => {
    return {
      onMouseEnter: () => setIsHovered(true),
      onMouseLeave: () => setIsHovered(false),
    };
  }, [setIsHovered]);

  return [isHovered, hoverProps] as const;
}
