import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import InputComponent from 'components/inputComponent';
import Loader from 'components/loaderComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetPage } from 'hooks/useGetPage';
import AppointmentPreworkSymptoms from '../components/appointment-prework/AppointmentPreworkSymptoms';
import BodySymptomsPlacer from 'app/my-skin/components/BodySymtomsLocation';
import AppointmentPreworkRadioList from '../components/appointment-prework/AppointmentPreworkRadioList';
import { useModalParams } from 'components/modal/useModalManager';
import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkMini.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import {
  AppointmentMediaInsert,
  CreateFhirAppointmentInputWithoutPatientCodexId,
  useCreateFhirAppointmentMutation,
  useGetAppointmentByCodexAppointmentIdQuery,
  usePatchFhirAppointmentMutation,
} from 'graphql/generated/remote-schema-hasura';
import { AlertState, FhirAppointmentStatuses } from '../interfaces';
import { scrollToTop } from 'utilities/functions';
import { useLocation, useNavigate } from 'react-router-dom';
import { MY_APPOINTMENTS_SELECT_A_PROVIDER } from 'utilities/routes';
import { AcuityModal } from '../components/AcuityModal';
import { ResourcesTypes } from 'utilities/interfaces';
import { AppointmentPreworkPreCaptureModal } from '../components/appointment-prework/AppointmentPreworkPreCaptureModal';
import { useGetFrontAndBackDots } from 'app/my-skin/components/BodySymtomsLocation/hooks';
import {
  Dot,
  ImageObject,
} from 'app/my-skin/components/BodySymtomsLocation/interfaces';
import { useModal } from 'layout/useModal';
import { Buffer } from 'buffer';
import UserNotMatchPreworkModal from '../components/UserNotMatchPreworkModal';
import { AuthContext } from 'auth/context/AuthContext';
import {
  PARAM_MODALS_IDENTIFIERS,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';

const AppointmentPreworkMobile: React.FC = () => {
  const location = useLocation();
  const { user: loggedUser } = useContext(AuthContext);
  const codexProviderId = location?.state?.codexProviderId;
  const calendarId = location?.state?.calendarId;
  const ownerId = location?.state?.ownerId;
  const diagnosticReportId = location?.state?.diagnosticReportId;
  const [mediaPerBodyLocation, setMediaPerBodyLocation] = useState<
    Map<string, ImageObject>
  >(new Map());
  const [openModal, closeModal] = useModal(UserNotMatchPreworkModal);

  const [openPreworkCaptureModal] = useModal(AppointmentPreworkPreCaptureModal);

  const { data: locale, loading: localeLoading } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });
  const { data: genericActions, loading: genericActionsLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.BACK, genericActionsIds.CONTINUE],
    });

  const [createFhirAppointment, { loading: createAppointmentLoading }] =
    useCreateFhirAppointmentMutation();
  const [patchFhirAppointment, { loading: patchAppointmentLoading }] =
    usePatchFhirAppointmentMutation();
  const editAppointmentId = location.state?.toEditAppointmentCodexId;
  const { data: editAppointmentData, loading: editAppointmentLoading } =
    useGetAppointmentByCodexAppointmentIdQuery({
      variables: {
        appointmentCodexId: editAppointmentId,
      },
      skip: !editAppointmentId,
    });
  const navigate = useNavigate();

  const { backDots, frontDots, setBackDots, setFrontDots } =
    useGetFrontAndBackDots();

  const [alert, setAlert] = useState<AlertState>();
  const [symptoms, setSymptoms] = useState<string[]>([]);
  const [anythingElse, setAnythingElse] = useState<string>('');
  const [howLong, setHowLong] = useState<string>();
  const howLongItems = useMemo<string[]>(
    () => Object.values(locale?.howLong || {}),
    [locale],
  );
  const [severity, setSeverity] = useState<string>();
  const [subjectOfAppointment, setSubjectOfAppointment] = useState<string>();
  const severityItems = useMemo<string[]>(
    () =>
      (Object.values(locale?.severity || {}) as string[]).sort((a, b) =>
        a.localeCompare(b),
      ) as string[],
    [locale],
  );
  const lastStartDate = useRef<Date>();
  const lastAppointmentCodexId = useRef<string>();
  const { isOpen: isAcuityModal } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.ACUITY_CALENDAR_MODAL,
  );

  const lastAppointmentStatus = useRef<FhirAppointmentStatuses>();

  const createAppointmentInput = useMemo<
    CreateFhirAppointmentInputWithoutPatientCodexId | undefined
  >(() => {
    const symptomsLocation = [...frontDots, ...backDots]
      .filter((dot) => dot.location && dot.selected)
      .map((dot) => dot.location as string);
    if (!subjectOfAppointment) {
      return;
    }
    const status: FhirAppointmentStatuses =
      lastAppointmentStatus.current ?? 'pending';
    const mediaToInsert = [
      ...mediaPerBodyLocation.entries(),
    ].map<AppointmentMediaInsert>(([bodySite, value]) => ({
      mediaId: value.image,
      description: value.description,
      bodySite,
    }));
    return {
      appointmentNotes: {
        anythingElseQuestion: anythingElse || '',
        howLongQuestion: howLong || '',
        severityQuestion: severity || '',
        symptomsTypes: symptoms || '',
      },
      symptomsLocation,
      mediaToInsert,
      startAt: (lastStartDate.current || new Date()).toISOString(),
      subjectOfAppointment,
      status,
      ...(diagnosticReportId && {
        supportingInfo: [
          `${ResourcesTypes.DIAGNOSTIC_REPORT}/${diagnosticReportId}`,
        ],
      }),
    };
  }, [
    frontDots,
    backDots,
    subjectOfAppointment,
    mediaPerBodyLocation,
    anythingElse,
    howLong,
    severity,
    symptoms,
    diagnosticReportId,
  ]);

  const onBodyPartClick = useCallback(
    async (label: string, direction: 'front' | 'back', bodyId: number) => {
      const dot = (direction === 'front' ? frontDots : backDots).find(
        (dot) => dot.id === bodyId,
      );
      if (!dot?.location) return;
      let image: string | undefined;
      let mediaId: string | undefined;
      let description: string | undefined;
      if (!dot.selected) {
        const modalResult = await openPreworkCaptureModal({
          bodyPartLocation: label,
        });
        mediaId = modalResult?.mediaId;
        image = modalResult?.image;
        description = modalResult?.description;
      }
      if (image || dot.selected) {
        let updateStateFunction = setFrontDots;
        if (direction === 'back') {
          updateStateFunction = setBackDots;
        }
        updateStateFunction((prev) =>
          prev.map<Dot>((prevDot) => {
            if (prevDot.id === dot.id) {
              return { ...prevDot, selected: !prevDot.selected };
            }
            return prevDot;
          }),
        );
        setMediaPerBodyLocation((prev) => {
          if (image && mediaId) {
            prev.set(dot.location as string, { image, description, mediaId });
          } else {
            prev.delete(dot.location as string);
          }
          return new Map(prev);
        });
      }
    },
    [backDots, frontDots, openPreworkCaptureModal, setBackDots, setFrontDots],
  );

  const onAnythingElseChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setAnythingElse(e.target.value);
  const onSubjectOfAppointmentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setSubjectOfAppointment(e.target.value);
  const onContinue = async () => {
    scrollToTop();
    if (!createAppointmentInput) {
      setAlert({
        message: locale?.fillRequredError,
        type: 'negative',
      });
      return;
    }
    try {
      let appointmentCodexId: string | undefined;
      if (lastAppointmentCodexId.current) {
        await patchFhirAppointment({
          variables: {
            appointmentInformation: {
              appointmentCodexId: lastAppointmentCodexId.current,
              ...createAppointmentInput,
            },
          },
        });
        appointmentCodexId = lastAppointmentCodexId.current;
      } else {
        const result = await createFhirAppointment({
          variables: {
            appointmentPrework: createAppointmentInput,
          },
        });
        appointmentCodexId =
          result.data?.createFHIRAppointmentForCurrentUser.appointment
            .appointmentCodexId;
      }
      if (
        appointmentCodexId &&
        codexProviderId &&
        calendarId &&
        ownerId &&
        diagnosticReportId
      ) {
        // flow comes from zrt test kit registration where provider was already selected
        navigate(`.?${PARAM_MODALS_IDENTIFIERS.ACUITY_CALENDAR_MODAL}=true`, {
          state: {
            codexProviderId,
            appointmentCodexId,
            calendarId,
            ownerId,
          },
        });
      } else if (
        appointmentCodexId &&
        codexProviderId &&
        calendarId &&
        ownerId
      ) {
        // flow comes from my providers
        navigate(`.?${PARAM_MODALS_IDENTIFIERS.ACUITY_CALENDAR_MODAL}=true`, {
          state: {
            codexProviderId,
            appointmentCodexId,
            calendarId,
            ownerId,
          },
        });
      } else if (editAppointmentData) {
        // Flow comes from edit appointment
        navigate(-1);
      } else {
        navigate(MY_APPOINTMENTS_SELECT_A_PROVIDER, {
          state: { appointmentCodexId },
        });
      }
    } catch (error) {
      setAlert({
        message: locale?.errorSavingAppointment,
        type: 'negative',
      });
    }
  };

  useEffect(() => {
    if (
      editAppointmentData?.getFHIRAppointmentByCodexId?.appointment &&
      frontDots?.length &&
      backDots?.length &&
      !lastAppointmentCodexId.current
    ) {
      const lastAppointment =
        editAppointmentData.getFHIRAppointmentByCodexId.appointment;
      lastAppointmentCodexId.current = lastAppointment.appointmentCodexId;
      lastStartDate.current = new Date(lastAppointment.start || 0);
      lastAppointment.subjectOfAppointment &&
        setSubjectOfAppointment(lastAppointment.subjectOfAppointment);
      lastAppointment.appointmentNotes?.anythingElseQuestion &&
        setAnythingElse(lastAppointment.appointmentNotes?.anythingElseQuestion);
      lastAppointment.appointmentNotes?.howLongQuestion &&
        setHowLong(lastAppointment.appointmentNotes?.howLongQuestion);
      lastAppointment.appointmentNotes?.severityQuestion &&
        setSeverity(lastAppointment.appointmentNotes?.severityQuestion);
      lastAppointment.appointmentNotes?.symptomsTypes &&
        setSymptoms(lastAppointment.appointmentNotes?.symptomsTypes);
      lastAppointmentStatus.current =
        lastAppointment.status as FhirAppointmentStatuses;
      if (lastAppointment.symptomsLocation && lastAppointment.media?.length) {
        const mediaMap = lastAppointment.media.reduce<Map<string, string>>(
          (acc, media) => {
            if (media.bodySite && media.fileId) {
              acc.set(media.bodySite, media.fileId);
            }
            return acc;
          },
          new Map(),
        );
        const symptomsLocationSet = new Set(
          lastAppointment.symptomsLocation.filter((location) =>
            mediaMap.has(location),
          ),
        );
        if (!symptomsLocationSet.size || !mediaMap.size) {
          return;
        }
      }
    }
  }, [
    editAppointmentData?.getFHIRAppointmentByCodexId.appointment,
    frontDots?.length,
    backDots?.length,
    setFrontDots,
    setBackDots,
  ]);

  useEffect(() => {
    // validates if mobile user is the same from desktop
    const validateUserMatch = async () => {
      if (location.search) {
        const decodedString = Buffer.from(
          decodeURIComponent(location.search),
          'base64',
        ).toString('utf-8');
        if (
          decodedString.split('=')[1] &&
          loggedUser?.uuid !== decodedString.split('=')[1]
        ) {
          await openModal(undefined, false);
        }
      }
    };
    validateUserMatch().catch(console.error);
    return () => {
      closeModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser?.uuid, location.search]);

  if (
    !locale ||
    localeLoading ||
    !genericActions ||
    genericActionsLoading ||
    editAppointmentLoading
  ) {
    return <Loader />;
  }
  const isAppointmentSaving =
    createAppointmentLoading || patchAppointmentLoading;

  return (
    <>
      {isAcuityModal && <AcuityModal />}
      <div className="px-7 pt-[30px] desktop:pt-0">
        <div className="flex flex-col gap-4 desktop:gap-0">
          <div className="flex flex-col desktop:flex-row w-full justify-between items-start p-0 gap-2.5 desktop:gap-[30px] desktop:mb-[34px]">
            <div className="flex flex-1 flex-col items-start gap-4">
              <ButtonComponent
                type="underline"
                Icon={ChevronLeftIcon}
                iconPosition="left"
                iconWidth="w-2.5"
                iconHeight="h-[18px]"
                fullWidthClassName=""
                onClick={() => navigate(-1)}
              >
                <p className="uppercase">
                  {genericActions?.[genericActionsIds.BACK].back}
                </p>
              </ButtonComponent>
              {alert && (
                <div
                  className={`self-stretch flex flex-row items-center px-3 py-2 rounded-10 gap-3 bg-${alert.type}`}
                >
                  {alert.type === 'positive' ? (
                    <CheckIcon className="w-4 h-2.5" />
                  ) : (
                    <WarningIcon className="w-6 h-6 fill-alert-negative" />
                  )}
                  <p
                    className={`font-semibold text-alert-${alert.type} text-base`}
                  >
                    {alert.message}
                  </p>
                </div>
              )}
              <p className="text-h2 text-dark-gray font-medium desktop:text-h1">
                {locale.title}
              </p>
            </div>
          </div>
          <div
            className={`bg-white flex flex-col rounded-10 desktop:mx-10 px-12 py-6 text-dark-gray font-semibold text-base gap-8 ${
              isAppointmentSaving ? 'opacity-50 pointer-events-none' : ''
            }`}
          >
            <p>{locale.preworkDescription}</p>
            <div className="flex flex-col desktop:flex-row gap-2 desktop:gap-4">
              <div className="flex flex-col items-start">
                <span className="text-dark-gray font-semibold text-base">
                  {locale.subjectOfAppointment}
                  <span className="text-clc-red">*</span>
                </span>
                <span className="text-med-gray font-semibold text-sm desktop:w-max">
                  {locale.subjectOfAppointmentSubtitle}
                </span>
              </div>
              <InputComponent
                type="text"
                value={subjectOfAppointment}
                onChange={onSubjectOfAppointmentChange}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col desktop:flex-row desktop:items-end desktop:gap-2">
                <span className="text-dark-gray font-semibold text-base">
                  {locale.symptomTypes}
                </span>
                <span className="text-med-gray font-semibold text-sm desktop:w-max">
                  {locale.selectAllThatApply}
                </span>
              </div>
              <AppointmentPreworkSymptoms
                setSymptoms={setSymptoms}
                symptoms={symptoms}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-end gap-2">
                <span className="text-dark-gray font-semibold text-base">
                  {locale.symptomTypes}
                </span>
              </div>
              <AppointmentPreworkRadioList
                selected={howLong}
                setSelected={setHowLong}
                items={howLongItems}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-end gap-2">
                <span className="text-dark-gray font-semibold text-base">
                  {locale.severityQuestion}
                </span>
              </div>
              <AppointmentPreworkRadioList
                selected={severity}
                setSelected={setSeverity}
                items={severityItems}
              />
            </div>
            <BodySymptomsPlacer
              noForm
              backDots={backDots}
              frontDots={frontDots}
              setBackDots={setBackDots}
              setFrontDots={setFrontDots}
              mediaPerBodyLocation={mediaPerBodyLocation}
              onClick={onBodyPartClick}
            />
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-end gap-2">
                <span className="text-dark-gray font-semibold text-base">
                  {locale.anythingElseQuestion}
                </span>
              </div>
              <InputComponent
                name="summaryDiagnosis"
                textAreaProps={{
                  onTextAreaChange: onAnythingElseChange,
                }}
                type="text-area"
                value={anythingElse}
              />
            </div>
            <div className="flex flex-row justify-end items-center pt-5 border-t-2 border-card-border-color">
              <ButtonComponent onClick={onContinue} type="filled">
                {genericActions[genericActionsIds.CONTINUE].continue}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentPreworkMobile;
