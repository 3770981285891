import { ReactComponent as PdfIcon } from 'assets/icons/pdf.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import {
  TestKitResult,
  TestKitsResultsModalNavigationState,
} from '../interfaces/testKits.interfaces';
import { formatDateToCustomFormat } from 'utilities/functions';
import { useNavigate, useParams } from 'react-router-dom';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'auth/context/AuthContext';
import {
  useGetProviderPatientSignedUrlMutation,
  useGetSignedUrlFromStorageMutation,
} from 'graphql/generated/hasura';
import { Roles } from '../../../firebase/interfaces';
import ButtonComponent from '../../../components/button/buttonComponent';

export const TestKitResultRow: React.FC<TestKitResult> = ({
  date,
  name,
  patientName,
  patientProfileImageSrc,
  resultSrc,
  buttonText,
}) => {
  const { user: loggedUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [pdfSrc, setPdfSrc] = useState<string>();
  const [getSignedUrlFromStorage] = useGetSignedUrlFromStorageMutation({});
  const [getProviderPatientsSignedUrl] = useGetProviderPatientSignedUrlMutation(
    {},
  );

  useEffect(() => {
    (async () => {
      if (resultSrc) {
        try {
          if (loggedUser?.role === Roles.PROVIDER && patientId) {
            const url = await getProviderPatientsSignedUrl({
              variables: {
                fileId: resultSrc,
                patientCodexId: patientId,
              },
            });
            if (url.data?.GetProviderPatientSignedUrl?.url) {
              setPdfSrc(url.data?.GetProviderPatientSignedUrl.url);
            }
          } else {
            const url = await getSignedUrlFromStorage({
              variables: { fileId: resultSrc },
            });
            if (url.data?.GetSignUrlFormStorage?.url) {
              setPdfSrc(url.data?.GetSignUrlFormStorage?.url);
            }
          }
        } catch (error) {
          console.error('Error getting pdf signed url', error);
        }
      }
    })();
  }, [
    resultSrc,
    patientId,
    loggedUser?.role,
    getSignedUrlFromStorage,
    getProviderPatientsSignedUrl,
  ]);

  const onEyeIconClick = () => {
    if (!pdfSrc) return;
    const state: TestKitsResultsModalNavigationState = {
      patientName,
      patientProfileImageSrc,
      testPdfSrc: pdfSrc,
      testDate: new Date(date),
      testName: name,
    };
    navigate(`.?${PARAM_MODALS_IDENTIFIERS.TEST_KIT_RESULT_MODAL_ID}=true`, {
      state,
    });
  };
  return (
    <div className="flex items-start justify-between gap-[15px]">
      <div className="w-6 h-6 inline-flex justify-center items-center">
        <PdfIcon className="text-med-gray" />
      </div>
      <div className="text-med-gray text-base font-normal leading-snug flex-1 text-left">
        {name} - {formatDateToCustomFormat(date, true)}
      </div>
      <ButtonComponent
        type="underline"
        onClick={onEyeIconClick}
        className="uppercase font-bold text-sm"
        containerClassName="!gap-1.5"
        disabled={!resultSrc}
      >
        <EyeIcon onClick={onEyeIconClick} />
        <span>{buttonText}</span>
      </ButtonComponent>
    </div>
  );
};
