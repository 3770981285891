import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetPage } from 'hooks/useGetPage';
import { useNavigate } from 'react-router-dom';
import { useGetAcuityCalendarMutation } from 'graphql/generated/hasura';
import { pageIds } from 'utilities/constants';

import { MY_APPOINTMENTS } from 'utilities/routes';
import Modal from 'components/modal/modalComponent';
import Loader from 'components/loaderComponent';
import { getAcuityConfig } from 'utilities/config';

const buildAcuityUrl = (
  calendarId: number,
  ownerId: string,
  appointmentCodexId: string,
) => {
  const { acuityCalendarBaseUrl, appointmentCodexIdFormId } = getAcuityConfig();
  return `${acuityCalendarBaseUrl}?owner=${ownerId}&calendarID=${calendarId}&field:${appointmentCodexIdFormId}=${appointmentCodexId}`;
};

interface AcuityCalendar {
  calendar_id: number | null;
  owner_id: string | null;
}

export const AcuityModal = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_APPOINTMENTS,
  });
  const navigate = useNavigate();
  const [online, setOnline] = useState(navigator.onLine);
  const [iframeKey, setIframeKey] = useState(0);
  const [acuityCalendar, setAcuityCalendar] = useState<AcuityCalendar>();

  const location = useLocation();
  const { codexProviderId, appointmentCodexId, calendarId, ownerId } =
    location.state ?? {};

  const [getAcuityCalendar, { loading: acuityLoading }] =
    useGetAcuityCalendarMutation({
      variables: {
        providerId: codexProviderId ?? '',
      },
      onCompleted: (data) => {
        setAcuityCalendar({
          calendar_id: data.GetAcuityCalendarInformation?.calendar_id ?? null,
          owner_id: data.GetAcuityCalendarInformation?.owner_id ?? null,
        });
      },
    });

  useEffect(() => {
    if (!codexProviderId || (calendarId && ownerId)) return;
    getAcuityCalendar();
  }, [getAcuityCalendar, codexProviderId, calendarId, ownerId]);

  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);
      setIframeKey((prevKey) => prevKey + 1);
    };
    const handleOffline = () => setOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!codexProviderId) return null;

  const acuityCalendarId = calendarId ?? acuityCalendar?.calendar_id;
  const acuityOwnerId = ownerId ?? acuityCalendar?.owner_id;

  const isLoading =
    loading ||
    !locale ||
    !acuityCalendarId ||
    !acuityOwnerId ||
    acuityLoading ||
    !online;

  const handleSubmit = () => {
    navigate(MY_APPOINTMENTS);
  };

  return (
    <Modal
      isOpen={true}
      onClose={handleSubmit}
      title={locale?.acuity?.title}
      modalDesktopWidth="desktop:max-w-[960px]"
      modalDesktopHeight="desktop:max-h-[900px] desktop:h-[700px]"
      testID="acuity-modal"
    >
      {isLoading ? (
        <Loader />
      ) : (
        <iframe
          key={iframeKey}
          src={buildAcuityUrl(
            acuityCalendarId ?? 0,
            acuityOwnerId ?? '',
            appointmentCodexId,
          )}
          title={locale?.acuity.title}
          className="w-full h-full"
          width="100%"
          height="650"
          referrerPolicy="no-referrer"
        />
      )}
    </Modal>
  );
};
