import { formatDateToCustomFormat } from 'utilities/functions';
import { SharedMedia } from '../interfaces/interfaces';
import SharedMediaActions from './SharedMediaActions';
import useLoadModalAppointmentInfo from 'app/my-account/pages/my-media-library/hooks/useLoadModalAppointmentInfo';
import { appointmentRenderInfo } from 'app/my-appointments/interfaces';

export interface SharedMediaRecentResultProps {
  media: SharedMedia;
  locale: Record<string, string>;
}

export const SharedMediaRecentResult: React.FC<
  SharedMediaRecentResultProps
> = ({ locale, media }) => {
  const { loading, appointmentTypeByState, providerNameByState } =
    useLoadModalAppointmentInfo(media.appointmentId);

  const AppointmentIcon =
    appointmentTypeByState !== undefined &&
    appointmentRenderInfo[appointmentTypeByState]?.icon;
  return (
    <div
      key={media.id}
      className="desktop:w-1/3 flex flex-col desktop:flex-row desktop:gap-4"
    >
      <div className="flex items-center justify-center desktop:block">
        <div className="overflow-hidden rounded-10 desktop:w-44 desktop:h-full h-[350px] bg-cover bg-center">
          <img
            src={media.img}
            className="object-cover w-full h-full"
            alt={locale.sharedMediaAltText}
          />
        </div>
      </div>
      <div className="flex grow flex-col gap-1.5 w-fit px-7 py-5 desktop:px-0 desktop:py-0 justify-start desktop:items-stretch">
        {!loading && (
          <>
            <div className="text-charcoal-gray text-base font-semibold w-fit">
              {locale?.inAppointmentShare}
            </div>
            <div className="text-med-gray-3 text-base font-normal leading-snug">
              {formatDateToCustomFormat(media.date.toISOString())}
            </div>
            <div className="justify-start items-center gap-2.5 inline-flex">
              {AppointmentIcon && (
                <div className="justify-center items-center w-6 h-6 flex">
                  <AppointmentIcon />
                </div>
              )}
              <div className="grow shrink basis-0 text-zinc-800 break-all text-sm font-bold leading-snug">
                {providerNameByState}
              </div>
            </div>
            <div className="flex flex-col justify-around desktop:justify-start items-start">
              <SharedMediaActions media={media} hidePrint />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
