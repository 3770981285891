import Loader from 'components/loaderComponent';
import { useModalParams } from 'components/modal/useModalManager';
import { MyAppointmentsModal } from './MyAppointmentsModal';
import AppointmentDetailModalProvider from 'components/appointmentDetailModal/AppointmentDetailModalProvider';
import { SharedMediaModal } from 'app/my-patients/components/SharedMediaModal';
import CancelAppointmentModalProvider from 'app/appointments/components/CancelAppointmentModalProvider';
import { AppointmentTypeEnum } from 'graphql/generated/remote-schema-hasura';
import { PARAM_MODALS_IDENTIFIERS, pageIds } from 'utilities/constants';
import { useGetPage } from 'hooks/useGetPage';
import { useSearchParams } from 'react-router-dom';
import MyVisitSummaryModal from './MyVisitSummaryModal';
import { NoAppointmentHistory } from 'app/my-appointments/components/NoAppointmentHistory';
import { AppointmentHistory } from 'app/my-appointments/components/AppointmentHistory';
import { UpcomingAppointments } from 'app/my-appointments/components/UpcomingAppointments';

export const ProviderAppointmentsMainPage = () => {
  const { isOpen: isAppointmentVideoModal } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.VIDEO_APPOINTMENT_MODAL,
  );
  const { isOpen: isAppointmentChatModal } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.CHAT_APPOINTMENT_MODAL,
  );
  const { isOpen: isMyVisitSummaryOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.MY_VISIT_SUMMARY_MODAL_PROVIDER,
  );

  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get('appointment-id');
  const { isOpen: isMediaModalOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.MEDIA_LIBRARY_MODAL,
  );
  const { isOpen: isAppointmentsDetailsOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.APPOINTMENT_DETAILS_MODAL_PROVIDER_ID,
  );
  const { isOpen: isCancelAppointmentOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.CANCEL_APPOINTMENT_MODAL_PROVIDER_ID,
  );

  const { data: locale, loading: localeLoading } = useGetPage({
    locale: 'en',
    pageId: pageIds.MY_APPOINTMENTS,
  });

  if (localeLoading || !locale) {
    return <Loader />;
  }

  if (isAppointmentChatModal) {
    return (
      <MyAppointmentsModal
        appointmentType={AppointmentTypeEnum.Chat}
        appointmentId={appointmentId}
        isPatient={false}
      />
    );
  }
  if (isMediaModalOpen) {
    return <SharedMediaModal />;
  }

  if (isAppointmentVideoModal) {
    return (
      <MyAppointmentsModal
        appointmentType={AppointmentTypeEnum.Video}
        appointmentId={appointmentId}
        isPatient={false}
      />
    );
  }

  if (isMediaModalOpen) {
    return <SharedMediaModal />;
  }

  if (isMyVisitSummaryOpen) {
    return <MyVisitSummaryModal />;
  }

  return (
    <>
      {isAppointmentsDetailsOpen && <AppointmentDetailModalProvider />}
      {isCancelAppointmentOpen && <CancelAppointmentModalProvider />}
      <div className="px-7 pt-[30px] desktop:pt-0">
        <div className="flex flex-col gap-[30px]">
          <div className="flex flex-col desktop:flex-row w-full justify-between items-start p-0 gap-2.5 desktop:gap-[30px] desktop:mb-[34px]">
            <div
              className="flex flex-1 flex-col items-start"
              data-testid="my-ppointments-page"
            >
              <p className="text-h2 text-dark-gray font-medium desktop:text-h1">
                {locale.myAppointmentsTabName}
              </p>
              <NoAppointmentHistory
                noAppointmentsText={locale.noAppointmentsText}
              />
            </div>
          </div>
          <>
            <UpcomingAppointments />
            <AppointmentHistory />
          </>
        </div>
      </div>
    </>
  );
};
