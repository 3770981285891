import { useGetPage } from 'hooks/useGetPage';
import { genericActionsIds, pageIds } from 'utilities/constants';
import { ProviderSideBarFilter } from '../components/ProviderSideBarFilter';

import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as SearchIcon } from 'assets/icons/searchIconBlue.svg';
import { ReactComponent as ArrowLoadMoreIcon } from 'assets/icons/arrowLoadMore.svg';
import InputComponent from 'components/inputComponent';
import { ProviderCard } from '../components/ProviderCard';
import TabsComponent from 'components/tabs/tabsComponent';
import { Tab } from 'components/tabs/tabs.interface';
import {
  PROVIDERS,
  PROVIDERS_FIND_A_PROVIDER,
  PROVIDERS_PAST_PROVIDERS,
} from 'utilities/routes';
import { useRef, useState } from 'react';
import {
  ProviderOutput,
  parseServiceType,
} from 'app/my-appointments/components/select-a-provider/ProviderCardProps';
import {
  useGetFhirProvidersQuery,
  useGetLastViewedProvidersByUserQuery,
} from 'graphql/generated/remote-schema-hasura';
import { FindAProviderSelectionFilterType } from '../components/ProviderSideBarFilterInterfaces';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import Loader from 'components/loaderComponent';
import useDropdown from 'components/dropdown/useDropdown';
import LastVisitedUsersComponent from 'components/LastVisitedUsersComponent/LastVisitedUsersComponent';
import LastVisitedUserItem from 'components/LastVisitedUsersComponent/LastVisitedUserItem';
import { Roles } from '../../../firebase/interfaces';

const DESKTOP_COlUMNS = 1;
const DROPDOWN_ID = 'find-provider';

type FindAProviderFilterType = Record<
  string,
  string | number | string[] | undefined
>;

const findAProviderBatchSize = 10;

const initialFilter = {
  limit: findAProviderBatchSize,
  offset: 0,
};

export const FindAProvider = () => {
  const { data: locale, loading } = useGetPage({
    locale: 'en',
    pageId: pageIds.PROVIDERS_SELECTION,
  });
  const { data: genericActions, loading: actionsLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.SEE_MORE, genericActionsIds.VIEW_ALL],
    });

  const { data, loading: isDataLoading } = useGetLastViewedProvidersByUserQuery(
    { fetchPolicy: 'network-only' },
  );

  const lastViewedProviders =
    data?.getLastViewedProvidersByUser?.providers ?? [];

  const isFirstInRow = (index: number, cols: number) => index % cols === 0;
  const [total, setTotal] = useState<number>(0);
  const isFirstLoad = useRef(true);
  const [currentFilter, setCurrentFilter] = useState<FindAProviderFilterType>(
    {},
  );
  const [filter, setFilter] = useState<FindAProviderFilterType>(initialFilter);
  const [providers, setProviders] = useState<ProviderOutput[]>([]);

  const {
    openDropdownId,
    handleDropdownClick,
    registerDropdownRef,
    handleHoverOut,
  } = useDropdown();

  const handleFocus = () => {
    if (!currentFilter?.search) {
      handleDropdownClick(DROPDOWN_ID, true);
    }
  };

  const { loading: loadingProviders } = useGetFhirProvidersQuery({
    variables: {
      ...filter,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const providers = data.getFHIRProviders.providers.map<ProviderOutput>(
        (provider) => {
          const parts =
            provider?.SENSITIVE_profile_picture_id?.split('/') || [];
          const pictureId = parts[parts.length - 1];

          return {
            ...provider,
            isFavorite: false,
            accepted_appointment_types: provider.serviceOfferings.map(
              (serviceOffering) => ({
                price: serviceOffering.price,
                serviceLabel: serviceOffering.serviceType,
                serviceType: parseServiceType(serviceOffering.serviceType),
              }),
            ),
            SENSITIVE_profile_picture_id: pictureId,
          } as ProviderOutput;
        },
      );
      setProviders(providers);
      data.getFHIRProviders.total && setTotal(data.getFHIRProviders.total);
    },
  });

  const renderProviderCards = (items: ProviderOutput[], cols: number) => {
    return items.map((item, index) => (
      <ProviderCard
        key={item.id}
        providerId={item.id}
        name={`${item.SENSITIVE_firstname} ${item.SENSITIVE_lastname}`}
        title={item.SENSITIVE_credentials.titles[0]}
        isFirstItem={isFirstInRow(index, cols)}
        location={`${item?.SENSITIVE_address_information?.address1}, ${item?.SENSITIVE_address_information?.city}, ${item?.SENSITIVE_address_information?.state}`}
        specialties={item.SENSITIVE_credentials.certifications}
        profileImage={item?.SENSITIVE_profile_picture_id ?? ''}
        rateAndServices={item.accepted_appointment_types}
        isStack
        signUrl
      />
    ));
  };

  const onSearchClick = () => {
    setFilter((prev) => ({
      ...currentFilter,
      ...initialFilter,
      limit: prev.limit,
    }));
  };

  const onSeeMoreClick = () => {
    setFilter((prev) => ({
      ...prev,
      limit: ((prev.limit as number) || 0) + findAProviderBatchSize,
    }));
  };

  const tabs: Tab[] = [
    {
      name: locale?.myProviders?.title,
      url: PROVIDERS,
    },
    {
      name: locale?.pastProviders?.title,
      url: PROVIDERS_PAST_PROVIDERS,
    },
    {
      name: locale?.findAProvider?.title,
      url: PROVIDERS_FIND_A_PROVIDER,
    },
  ];

  if (loading || !locale || actionsLoading || isDataLoading) return null;

  const canSeeMore = total > providers.length;
  const noProviderFound = providers.length === 0 && !loadingProviders;
  isFirstLoad.current = false;

  return (
    <div className="px-7 pt-[30px] desktop:pt-0">
      <TabsComponent tabs={tabs} />
      <div className="flex flex-col desktop:flex-row w-full justify-between items-center p-0 mb-[34px] gap-4">
        <div className="flex flex-row w-full desktop:w-auto justify-between items-center gap-[7px]">
          <h1
            className="text-left text-h1 text-dark-gray font-medium"
            data-testid="find-provider-page"
          >
            {locale?.findAProvider?.title}
          </h1>
        </div>
        <div className="hidden flex-row gap-4 items-center w-full desktop:w-1/4">
          <p className="text-dark-gray font-semibold text-h6">
            {locale?.findAProvider?.filters?.sort}
          </p>
          <div className="w-full">
            <InputComponent
              type="select"
              name="sort"
              selectInputProps={{ fullWidth: true }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full px-5 pt-5 pb-[30px] desktop:p-[30px] gap-8 bg-white rounded-10">
        <div className="flex flex-col desktop:flex-row w-full gap-5 items-center justify-between">
          <div className="flex flex-col desktop:flex-row gap-4 desktop:items-center w-full desktop:w-full">
            <p className="text-dark-gray font-semibold text-h6">
              {locale?.findAProvider?.filters?.search}
            </p>
            <div
              ref={(ref) => registerDropdownRef(DROPDOWN_ID, ref)}
              className="w-full relative"
            >
              <InputComponent
                noMarginBottom
                type="text"
                value={(currentFilter?.search || '') as string}
                onChange={(e) => {
                  setCurrentFilter((prev) => ({
                    ...prev,
                    search: e.target.value,
                  }));

                  if (!e.target.value) {
                    handleDropdownClick(DROPDOWN_ID);
                  } else {
                    handleHoverOut();
                  }
                }}
                name="search"
                maxLengthValue={35}
                onFocus={handleFocus}
                disableAutocomplete
              />

              {openDropdownId === DROPDOWN_ID && (
                <LastVisitedUsersComponent
                  isEmpty={!lastViewedProviders.length}
                  emptyStateTitle={locale?.noLastViewedProviders}
                >
                  {lastViewedProviders.map((provider) => {
                    return (
                      <LastVisitedUserItem
                        key={provider.id}
                        userId={provider.id}
                        name={`${provider.SENSITIVE_firstname} ${provider.SENSITIVE_lastname}`}
                        title={provider.SENSITIVE_credentials.titles[0]}
                        profileImage={
                          provider?.SENSITIVE_profile_picture_id ?? ''
                        }
                        buttonText={locale?.viewProviderProfile}
                        role={Roles.PROVIDER}
                        signUrl
                      />
                    );
                  })}
                </LastVisitedUsersComponent>
              )}
            </div>
          </div>
          <div className="hidden flex-col desktop:flex-row gap-4 desktop:items-center w-full desktop:w-[35%]">
            <p className="text-dark-gray font-semibold text-h6">
              {locale?.findAProvider?.filters?.location}
            </p>
            <div className="w-full">
              <InputComponent
                noMarginBottom
                type="text"
                name="location"
                maxLengthValue={35}
              />
            </div>
          </div>
          <div className="flex flex-row w-full desktop:w-[15%] items-center justify-center">
            <ButtonComponent
              type="outlined"
              Icon={SearchIcon}
              iconPosition="left"
              iconWidth="w-[24px]"
              iconHeight="h-[25px]"
              onClick={onSearchClick}
            >
              {locale?.findAProvider?.filters?.search}
            </ButtonComponent>
          </div>
        </div>
        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
        <div className="flex flex-col desktop:flex-row w-full gap-[30px]">
          <div className="flex w-full desktop:w-1/4">
            <ProviderSideBarFilter
              selectedFilters={
                currentFilter as FindAProviderSelectionFilterType
              }
              setSelectedFilters={
                setCurrentFilter as React.Dispatch<
                  React.SetStateAction<FindAProviderSelectionFilterType>
                >
              }
            />
          </div>
          <div className="flex flex-col w-full desktop:w-3/4 gap-4">
            <div className="hidden desktop:flex-row desktop:w-full">
              <div className="flex flex-row gap-4 items-center justify-end w-full">
                <p className="text-dark-gray font-semibold text-h6">
                  {locale?.findAProvider?.filters?.sort}
                </p>
                <div className="w-1/4">
                  <InputComponent
                    type="select"
                    name="sort"
                    selectInputProps={{ fullWidth: true }}
                  />
                </div>
              </div>
            </div>
            {loadingProviders && (
              <div className="flex items-center justify-center">
                <Loader />
              </div>
            )}
            {!loadingProviders && (
              <div
                className="flex flex-col w-full border-t border-gray-opacity-15"
                data-testid="find-provider-list"
              >
                {renderProviderCards(providers, DESKTOP_COlUMNS)}
              </div>
            )}
            {canSeeMore && !noProviderFound && (
              <div className="flex items-center">
                <ButtonComponent
                  type="underline"
                  iconPosition="right"
                  Icon={ArrowLoadMoreIcon}
                  onClick={onSeeMoreClick}
                >
                  {genericActions?.[genericActionsIds.SEE_MORE].seeMore}
                </ButtonComponent>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
