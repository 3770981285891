import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SgbnetIcon } from 'assets/icons/sgbnet.svg';
import { AuthContext } from 'auth/context/AuthContext';
import { UnAuthenticatedNavbar } from './UnAuthenticatedNavbar';
import { AuthenticatedNavbar } from './AuthenticatedNavbar';
import { DASHBOARD } from 'utilities/routes';
import { Roles } from '../../firebase/interfaces';
import { ProviderAuthenticatedNavbar } from './ProviderAuthenticatedNavbar';
import { ProviderAuthenticatedNotApprovedNavbar } from 'components/navbar/ProviderAuthenticatedNotApprovedNavbar';
import { SuperAdminAuthenticatedNavbar } from 'components/navbar/SuperAdminAuthenticatedNavbar';

interface NavbarProps {
  openNavbarMobile: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ openNavbarMobile }) => {
  const { isLoggedIn, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const userRole = user?.role;

  const getAuthenticatedNavbarBasedOnUserRole = () => {
    switch (userRole) {
      case Roles.PATIENT:
        return <AuthenticatedNavbar openNavbarMobile={openNavbarMobile} />;
      case Roles.PROVIDER:
        return user?.providerEnabled ? (
          <ProviderAuthenticatedNavbar openNavbarMobile={openNavbarMobile} />
        ) : (
          <ProviderAuthenticatedNotApprovedNavbar
            openNavbarMobile={openNavbarMobile}
          />
        );
      case Roles.SUPERADMIN:
        return (
          <SuperAdminAuthenticatedNavbar openNavbarMobile={openNavbarMobile} />
        );
      default:
        return <AuthenticatedNavbar openNavbarMobile={openNavbarMobile} />;
    }
  };

  return (
    <div
      className={
        'w-full desktop:min-w-[1300px] h-[80px] desktop:h-[100px] absolute left-0 top-0 box-border bg-white border-b shrink-0 p-0'
      }
    >
      <div className="w-full h-2.5 bg-clc-blue" />
      <div
        className="w-full h-[70px] desktop:h-[90px] px-[27.5px] desktop:px-[135px] flex justify-between items-center gap-4"
        data-testid="navbar"
      >
        <div>
          <SgbnetIcon
            className="w-[117px] h-[27px] desktop:w-[162px] desktop:h-9 cursor-pointer"
            onClick={() => navigate(DASHBOARD)}
          />
        </div>

        {!isLoggedIn && <UnAuthenticatedNavbar />}
        {isLoggedIn && getAuthenticatedNavbarBasedOnUserRole()}
      </div>
    </div>
  );
};
export default Navbar;
