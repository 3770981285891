import Numball from 'components/numballComponent';
import { useGetComponent } from 'hooks/useGetComponent';
import React, { useEffect, useState } from 'react';
import { componentIds, PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import {
  formatCurrentDateToYYYYMMDD,
  formatDateToCustomFormat,
  formatDateToMonthAndDay,
  formatDateWithLocales,
} from 'utilities/functions';
import { ReactComponent as LeftArrowIcon } from 'assets/icons/blue-arrow-left.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/blue-arrow-right.svg';
import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as CalendarIcon } from 'assets/icons/cancelCalendar.svg';
import { ReactComponent as GoToMessageAppointmentIcon } from 'assets/icons/goToMessageAppointment.svg';
import { ReactComponent as PwEyeShowIcon } from 'assets/icons/pwEyeShow.svg';
import {
  AppointmentTypeEnum,
  useGetAcuityProviderTimeSlotsLazyQuery,
} from 'graphql/generated/hasura';
import { ReactComponent as CameraIcon } from 'assets/icons/videoIcons/videoAppointment.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/appointmentsIcons/chatIcon.svg';
import { useNavigate } from 'react-router-dom';
import {
  MY_APPOINTMENTS,
  MY_APPOINTMENTS_CHAT_CONSULTATION,
  MY_APPOINTMENTS_PROVIDER_OVERVIEW,
  MY_APPOINTMENTS_VIDEO_CONSULTATION,
  MY_PATIENTS,
  MY_PATIENTS_BIO_INFO,
} from 'utilities/routes';
import {
  AppointmentListProps,
  FilterDirection,
  ProviderDashboardUpcomingAppointments,
  WorkingHoursProps,
} from './interfaces/upcomingAppointments.interfaces';
import {
  useGetAppointmentsByProviderLazyQuery,
  useGetFhirProviderByRequestIdQuery,
} from 'graphql/generated/remote-schema-hasura';
import Loader from 'components/loaderComponent';
import {
  createAvailabilityTimeFromWorkingHours,
  parseRawWorkingHours,
} from './functions';
import { useIsJoinable } from 'hooks/useIsJoinable';
import { upcomingStatuses } from 'app/my-appointments/components/UpcomingAppointments';
import { FHIR_APPOINTMENT_STATUS } from 'utilities/interfaces';

const AppointmentList = ({
  list,
  unavailable,
  locale,
}: AppointmentListProps) => {
  const navigate = useNavigate();

  return (
    <div className="w-full desktop:pl-10">
      {unavailable && (
        <p className="text-base text-med-gray py-10 desktop:pb-20 desktop:py-0">
          {locale.unavailableDontHaveAppointments}
        </p>
      )}
      {list.length === 0 && !unavailable && (
        <p
          className="text-base text-med-gray pb-10"
          data-testid="empty-upcoming-appointments"
        >
          {locale.youDoNotHaveAppointments}
        </p>
      )}
      {list.length > 0 && !unavailable && (
        <div className="w-full items-center py-[25px]">
          {list.map((item, index) => (
            <React.Fragment key={index}>
              <AppointmentRow
                key={index}
                appointmentCodexId={item.appointmentCodexId}
                appointmentType={item.appointmentType}
                date={item.date}
                end={item.end}
                patientName={item.patientName}
                patientCodexId={item.patientCodexId}
                locale={locale}
                status={item.status}
              />
              {index !== list.length - 1 && <hr className="w-full" />}
            </React.Fragment>
          ))}
        </div>
      )}
      <hr className="w-full" />
      <div className="mt-2">
        <ButtonComponent
          type="underline"
          onClick={() => navigate(MY_APPOINTMENTS)}
        >
          {locale.viewAllAppointments}
        </ButtonComponent>
      </div>
    </div>
  );
};

const WorkingHours = ({
  date,
  workingHoursList,
  unavailable,
  locale,
}: WorkingHoursProps) => {
  return (
    <div className="flex w-full justify-start flex-col desktop:max-w-[240px]">
      <p className="text-h7 font-bold uppercase mb-[15px]">
        {locale.workingHoursFor} {date}
      </p>
      <div>
        {unavailable && (
          <p className="text-base text-med-gray  mb-[15px]">
            {locale.unavaibleWorkingHours}
          </p>
        )}
        {!unavailable &&
          workingHoursList.map((workingHour, idx) => (
            <p className="text-base text-med-gray" key={idx}>
              {workingHour === 'not available'
                ? locale.unavailable
                : workingHour}
            </p>
          ))}
      </div>
      <hr className="w-full my-2" />
      <div>
        <ButtonComponent
          type="underline"
          onClick={() =>
            window.open('https://secure.acuityscheduling.com/admin/calendars')
          }
        >
          {locale.editWorkingHours}
        </ButtonComponent>
      </div>
    </div>
  );
};

const IconFactory = {
  [AppointmentTypeEnum.Chat]: <ChatIcon className="h-6 w-6" />,
  [AppointmentTypeEnum.Video]: <CameraIcon className="h-6 w-4" />,
  [AppointmentTypeEnum.Dnavisit]: null,
  [AppointmentTypeEnum.Email]: null,
};

const appointmentTypeFactory = (type: AppointmentTypeEnum) => {
  return IconFactory[type] || null;
};

const AppointmentRow: React.FC<
  ProviderDashboardUpcomingAppointments & { locale: Record<string, string> }
> = ({
  appointmentCodexId,
  appointmentType,
  date,
  end,
  patientName,
  patientCodexId,
  locale,
  status,
}) => {
  const navigate = useNavigate();
  const isJoinable = useIsJoinable({ startDate: date, endDate: end, status });

  const handleAppointmentDetailsClick = (appointmentId: string) => {
    navigate(MY_APPOINTMENTS_PROVIDER_OVERVIEW, { state: { appointmentId } });
  };
  const handleCancelAppointmentClick = (appointmentId: string) => {
    navigate(
      `${MY_APPOINTMENTS}?${PARAM_MODALS_IDENTIFIERS.CANCEL_APPOINTMENT_MODAL_PROVIDER_ID}=true`,
      { state: { appointmentId } },
    );
  };
  const handleMessagingAppoinment = () => {
    // we can add the email if desired: 'mailto:email@example.com?subject=Subject&body=Body%20goes%20here'
    window.open('mailto:subject=Appointment');
  };
  const handleJoinAppointment = () => {
    const base =
      appointmentType === AppointmentTypeEnum.Chat
        ? MY_APPOINTMENTS_CHAT_CONSULTATION
        : MY_APPOINTMENTS_VIDEO_CONSULTATION;
    navigate(`${base}&appointment-id=${appointmentCodexId}`);
  };
  const handlePatientClick = (patientCodexId: string) => {
    navigate(`${MY_PATIENTS}/${patientCodexId}/${MY_PATIENTS_BIO_INFO}`);
  };

  return (
    <div
      className={`w-full flex flex-col desktop:grid desktop:items-center py-[25px] ${
        isJoinable
          ? 'desktop:grid-cols-[1fr,1fr,1fr,1fr,1fr]'
          : 'desktop:grid-cols-[1fr,1fr,1fr,1fr]'
      }`}
      data-testid="upcoming-appointment-row"
    >
      <p
        className="text-base text-med-gray flex-1 "
        data-testid="upcoming-appointment-date"
      >
        {formatDateToCustomFormat(date)}
      </p>
      <div>
        <p
          className="text-base font-bold underline hover:cursor-pointer"
          onClick={() => handlePatientClick(patientCodexId)}
          data-testid="upcoming-appointment-patient"
        >
          {patientName}
        </p>
      </div>
      <div
        className="flex flex-row gap-2 flex-1 items-center justify-center"
        data-testid={`upcoming-appointment-type-${appointmentType}`}
      >
        {appointmentTypeFactory(appointmentType)}
      </div>
      <div className="flex flex-row flex-1 items-center justify-around mt-5">
        <PwEyeShowIcon
          className="cursor-pointer"
          onClick={() => handleAppointmentDetailsClick(appointmentCodexId)}
          data-testid="upcoming-appointment-details"
        />
        <CalendarIcon
          className="cursor-pointer fill-clc-blue"
          onClick={() => handleCancelAppointmentClick(appointmentCodexId)}
          data-testid="upcoming-appointment-cancel"
        />
        <GoToMessageAppointmentIcon
          className="cursor-pointer"
          onClick={handleMessagingAppoinment}
        />
      </div>
      {status === FHIR_APPOINTMENT_STATUS.PROPOSED && (
        <>{locale.pedingStatus}</>
      )}
      {isJoinable && (
        <div className="w-full">
          <ButtonComponent
            className="max-h-[45px] w-full mt-4 desktop:mt-0"
            desktopFullWidth
            onClick={() => handleJoinAppointment()}
          >
            {locale.joinNow}
          </ButtonComponent>
        </div>
      )}
    </div>
  );
};

export const UpcomingAppointments = () => {
  const [workingHours, setWorkingHours] = useState<string[]>([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    ProviderDashboardUpcomingAppointments[]
  >([]);
  const todayDate = Date.now();
  const [date, setDate] = useState(todayDate);
  const [isCurrentDateAvailable] = useState(false);
  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.DATEPICKER,
  });

  const { data: localeAppointments, loading: loadingAppointments } =
    useGetComponent({
      locale: 'en',
      componentId: componentIds.UPCOMING_APPOINTMENTS_PROVIDER,
    });

  const changeDate = async (direction: FilterDirection) => {
    const currentDate = new Date(date);

    if (direction === FilterDirection.ADD) {
      currentDate.setDate(currentDate.getDate() + 1);
    } else if (direction === FilterDirection.SUBTRACT) {
      currentDate.setDate(currentDate.getDate() - 1);
    }

    setDate(currentDate.getTime());

    if (providerData?.getFHIRProviderByRequestId.providers) {
      getAcuityProviderTimeSlots({
        variables: {
          date: formatCurrentDateToYYYYMMDD(new Date(currentDate)),
          providerCalendarId:
            providerData.getFHIRProviderByRequestId.providers[0]
              .acuityCalendarId || '',
        },
      });
    }
  };
  const [getProviderUpcomingAppoinments] =
    useGetAppointmentsByProviderLazyQuery({
      onCompleted: (data) => {
        if (data.getFHIRAppointmentByRequestProviderId) {
          const mapped: ProviderDashboardUpcomingAppointments[] =
            data.getFHIRAppointmentByRequestProviderId.appointment.map(
              (app) => ({
                date: formatDateToCustomFormat(app.start || ''),
                end: formatDateToCustomFormat(app.end || ''),
                patientName: `${app.patientFirstName} ${app.patientLastName}`,
                appointmentType:
                  app.appointmentType || AppointmentTypeEnum.Video,
                appointmentCodexId: app.appointmentCodexId,
                patientCodexId: app.codexPatientId,
                status: app.status as FHIR_APPOINTMENT_STATUS,
              }),
            );
          setUpcomingAppointments(mapped);
        }
      },
    });

  const { data: providerData, loading: providerDataLoading } =
    useGetFhirProviderByRequestIdQuery({
      onCompleted: (data) => {
        if (data.getFHIRProviderByRequestId.providers.length > 0) {
          getAcuityProviderTimeSlots({
            variables: {
              providerCalendarId:
                data.getFHIRProviderByRequestId.providers[0].acuityCalendarId ||
                '',
              date: formatCurrentDateToYYYYMMDD(new Date()),
            },
          });
        }
      },
    });

  const [
    getAcuityProviderTimeSlots,
    {
      loading: providerAcuityTimeSlotsLoading,
      error: providerAcuityTimeSlotsError,
    },
  ] = useGetAcuityProviderTimeSlotsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data?.GetProviderTimeSlots) {
        const rawWorkinghours = createAvailabilityTimeFromWorkingHours(
          data.GetProviderTimeSlots.providerWorkingHours,
          data.GetProviderTimeSlots.blocks,
          date,
        );
        setWorkingHours(parseRawWorkingHours(rawWorkinghours));
      }
    },
  });

  useEffect(() => {
    const startDate = new Date(date);
    const endDate = new Date(date);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    getProviderUpcomingAppoinments({
      variables: {
        from: startDate.toString(),
        to: endDate.toString(),
        appointmentStatus: upcomingStatuses,
      },
    });
  }, [date, getProviderUpcomingAppoinments]);

  if (
    loading ||
    !locale ||
    !localeAppointments ||
    loadingAppointments ||
    !providerData ||
    providerDataLoading ||
    providerAcuityTimeSlotsLoading
  ) {
    return <Loader testID="upcoming-appoint-loader" />;
  }

  return (
    <div
      className="flex flex-col items-center px-5 pt-5 pb-[30px] desktop:p-[30px] gap-5 bg-white rounded-10"
      data-testid="upcoming-appoint"
    >
      <div className="flex flex-col desktop:flex-row w-full p-0 gap-2.5 items-center">
        <div className="text-h4 font-semibold flex">
          {localeAppointments?.upcomingAppointmentsFor}
          {formatDateWithLocales(locale, date)}
          <div className="ml-3">
            <Numball
              count={isCurrentDateAvailable ? 0 : upcomingAppointments.length}
              alternateBackground={true}
            />
          </div>
        </div>

        <div className="flex gap-x-6 items-center desktop:ml-auto">
          <div
            className="p-3 desktop:p-1 cursor-pointer"
            onClick={() => changeDate(0)}
          >
            <LeftArrowIcon />
          </div>
          <div
            className="p-3 desktop:p-1 cursor-pointer"
            onClick={() => changeDate(1)}
          >
            <RightArrowIcon />
          </div>
        </div>
      </div>
      <hr className="w-full" />
      <div className="flex w-full flex-col desktop:flex-row desktop:pb-[30px]">
        {providerAcuityTimeSlotsError ? (
          <p>{localeAppointments.unavaibleWorkingHours}</p>
        ) : (
          <WorkingHours
            date={formatDateToMonthAndDay(date)}
            workingHoursList={workingHours}
            // TODO: COD-2754
            unavailable={isCurrentDateAvailable}
            locale={localeAppointments}
          />
        )}
        <AppointmentList
          // TODO: COD-2754
          list={upcomingAppointments}
          unavailable={isCurrentDateAvailable}
          locale={localeAppointments}
        />
      </div>
    </div>
  );
};
