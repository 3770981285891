import React from 'react';
import { DotBodyGroup, IBodySection, SvgImageTag } from '../interfaces';
import DotPlacer from '../DotPlacer';
import CheckboxGroup from './CheckboxGroup';
import { useGetComponent } from 'hooks/useGetComponent';
import { componentIds } from 'utilities/constants';
import { useGroupByDots } from '../hooks';

const FrontBodySection: React.FC<IBodySection> = ({
  dots,
  handleBodyParsing,
  mediaPerBodyLocation,
}) => {
  const { data: locale, loading } = useGetComponent({
    locale: 'en',
    componentId: componentIds.APPOINTMENT_PREWORK,
  });

  const {
    [DotBodyGroup.BODY_LEFT]: bodyLeft,
    [DotBodyGroup.BODY_RIGHT]: bodyRight,
    [DotBodyGroup.UPPER_EXTREMITIES_RIGHT]: upperRight,
    [DotBodyGroup.LOWER_EXTREMITIES_RIGHT]: lowerRight,
    [DotBodyGroup.UPPER_EXTREMITIES_LEFT]: upperLeft,
    [DotBodyGroup.LOWER_EXTREMITIES_LEFT]: lowerLeft,
  } = useGroupByDots(dots);

  const onClick = (id: number, isDetails?: boolean) =>
    handleBodyParsing('front', id, isDetails);

  if (loading || !locale) return null;
  return (
    <>
      <h3 className="font-bold text-title mt-8 mb-4">{locale?.frontTitle}</h3>
      <section className="grid grid-cols-4 pb-8 border border-transparent border-b-gray-opacity-15">
        <div className="grid grid-cols-1 gap-4">
          <CheckboxGroup
            label={locale?.bodySectionTitle}
            dots={bodyRight}
            medias={mediaPerBodyLocation}
            handleBodyParsing={onClick}
            className="min-h-[190px]"
          />
          <CheckboxGroup
            label={locale?.upperExtremitiesRightSectionTitle}
            dots={upperRight}
            medias={mediaPerBodyLocation}
            handleBodyParsing={onClick}
          />
          <CheckboxGroup
            label={locale?.lowerExtremitiesRightSectionTitle}
            dots={lowerRight}
            medias={mediaPerBodyLocation}
            handleBodyParsing={onClick}
          />
        </div>
        <div className="col-span-2">
          <div className="flex w-100 justify-center">
            <DotPlacer
              dots={[
                ...(bodyRight || []),
                ...(upperRight || []),
                ...(lowerRight || []),
                ...(bodyLeft || []),
                ...(upperLeft || []),
                ...(lowerLeft || []),
              ]}
              onDotSelected={onClick}
              type={SvgImageTag.BODY_FRONT_SVG}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <CheckboxGroup
            label={locale?.bodySectionTitle}
            dots={bodyLeft}
            medias={mediaPerBodyLocation}
            handleBodyParsing={onClick}
            className="min-h-[190px]"
          />
          <CheckboxGroup
            label={locale?.upperExtremitiesLeftSectionTitle}
            dots={upperLeft}
            medias={mediaPerBodyLocation}
            handleBodyParsing={onClick}
          />
          <CheckboxGroup
            label={locale?.lowerExtremitiesLeftSectionTitle}
            dots={lowerLeft}
            medias={mediaPerBodyLocation}
            handleBodyParsing={onClick}
          />
        </div>
      </section>
    </>
  );
};

export default FrontBodySection;
