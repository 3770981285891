import { appointmentRenderInfo } from 'app/my-appointments/interfaces';
import Loader from 'components/loaderComponent';
import { ModalButtonProps } from 'components/modal/modal.interfaces';
import Modal from 'components/modal/modalComponent';
import {
  useCancelAppointmentMutation,
  useGetAppointmentByCodexAppointmentIdQuery,
  useGetProviderPatientInfoQuery,
} from 'graphql/generated/remote-schema-hasura';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetSignedUrlFromStorage } from 'hooks/useGetSignedUrlFromStorage';
import { useLocation, useNavigate } from 'react-router-dom';
import { componentIds, genericActionsIds } from 'utilities/constants';
import {
  formatDateForAppointment,
  fromFhirAppointmentTypeToLocal,
} from 'utilities/functions';
import InputComponent from 'components/inputComponent';
import { useState } from 'react';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { useGetComponent } from 'hooks/useGetComponent';

const CancelAppointmentModalProvider: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appointmentId = location.state?.appointmentId;
  const { data: appointmentData, loading: appointmentLoading } =
    useGetAppointmentByCodexAppointmentIdQuery({
      variables: { appointmentCodexId: appointmentId },
      skip: !appointmentId,
    });
  const { data: locale } = useGetComponent({
    componentId: componentIds.CANCEL_APPOINTMENT_MODAL_PROVIDER,
    locale: 'en',
  });
  const [cancelationReason, setCancelationReason] = useState<string>();
  const [errorCheck, setErrorCheck] = useState<boolean>(false);
  const [cancelationError, setCancelationError] = useState<string>();
  const handleClose = () => {
    if (cancelAppointmentLoading) return;
    navigate(-1);
  };
  const [cancelAppointment, { loading: cancelAppointmentLoading }] =
    useCancelAppointmentMutation({
      onCompleted: (data) => {
        if (data.cancelFHIRAppointment.appointment.appointmentId) {
          navigate(-1);
        }
      },
      onError() {
        setCancelationError(locale.errorCancelingAppointment);
      },
    });

  const { data: genericAction, loading: genericActionLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [genericActionsIds.CANCEL, genericActionsIds.SUBMIT],
    });

  const { loading: patientLoading, data: patientData } =
    useGetProviderPatientInfoQuery({
      variables: {
        patientCodexId:
          appointmentData?.getFHIRAppointmentByCodexId?.appointment
            ?.codexPatientId || '',
      },
      skip: !appointmentData?.getFHIRAppointmentByCodexId?.appointment
        ?.codexPatientId,
    });

  const appointmentType = fromFhirAppointmentTypeToLocal(
    appointmentData?.getFHIRAppointmentByCodexId?.appointment
      ?.appointmentType || undefined,
  );
  const { icon: AppointmentIcon, text: appointmentLocaleEntry } =
    appointmentRenderInfo[appointmentType];
  const formattedDate =
    appointmentData?.getFHIRAppointmentByCodexId?.appointment?.start &&
    formatDateForAppointment(
      new Date(
        appointmentData?.getFHIRAppointmentByCodexId?.appointment?.start,
      ),
    );

  const signedProfileUrl = useGetSignedUrlFromStorage(
    patientData?.getFHIRPatientbyCodexIDParameter?.users?.[0]
      ?.SENSITIVE_profile_picture_id || undefined,
    appointmentData?.getFHIRAppointmentByCodexId?.appointment?.codexPatientId,
  );

  const handleOnSubmit = async () => {
    if (cancelAppointmentLoading) return;
    if (!cancelationReason) {
      setErrorCheck(true);
      return;
    }
    await cancelAppointment({
      variables: {
        codexAppointmentId: appointmentId,
        cancelationReason: cancelationReason,
      },
    });
  };

  const Buttons: ModalButtonProps[] = [
    {
      label: genericAction?.[genericActionsIds.SUBMIT].submit,
      disabled: false,
      onClick: handleOnSubmit,
    },
    {
      label: genericAction?.[genericActionsIds.CANCEL].cancel,
      disabled: false,
      onClick: handleClose,
      type: 'underline',
    },
  ];
  const loadingData = patientLoading || appointmentLoading || !signedProfileUrl;
  const handleOnInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (errorCheck) setErrorCheck(false);
    setCancelationReason(e.target.value);
  };
  if (genericActionLoading || !appointmentId || !locale) return null;
  return (
    <Modal
      isOpen={true}
      onClose={handleClose}
      buttons={Buttons}
      title={locale.title}
    >
      {loadingData ? (
        <Loader />
      ) : (
        <>
          {cancelationError && (
            <div className="self-stretch flex flex-row items-center px-3 py-2 rounded-10 gap-3 bg-negative">
              <WarningIcon className="w-6 h-6 fill-alert-negative" />
              <p className="font-semibold text-alert-negative text-base">
                {cancelationError}
              </p>
            </div>
          )}
          <div
            className={`flex flex-col gap-10 ${
              cancelAppointmentLoading ? 'opacity-50 pointer-events-none' : ''
            }`}
          >
            <div className="rounded-10 justify-around bg-white flex desktop:flex-row flex-col gap-4 desktop:gap-0 p-4 desktop:px-0 desktop:py-10">
              <div className=" font-semibold text-base text-charcoal-gray">
                {locale.aboutYorAppointment}
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-3">
                  <AppointmentIcon className="h-6 w-4" />
                  <div className="text-charcoal-gray font-bold text-sm">
                    {locale[appointmentLocaleEntry as keyof typeof locale]}
                  </div>
                </div>
                <p className="font-semibold text-lg text-charcoal-gray">
                  {formattedDate}
                </p>
                <div className="flex items-center gap-3 my-2">
                  <img
                    src={signedProfileUrl}
                    alt="Provider"
                    className="h-11 w-11 rounded-full"
                  />
                  <p className="font-semibold text-charcoal-gray text-base">
                    {
                      patientData?.getFHIRPatientbyCodexIDParameter?.users?.[0]
                        ?.SENSITIVE_firstname
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <label className="text-charcoal-gray font-semibold text-base">
                {locale.provideAReason}
                <span className="text-clc-red font-bold">*</span>
              </label>
              <InputComponent
                type="text-area"
                name="cancelationReason"
                errorStatus={errorCheck && !cancelationReason}
                value={cancelationReason}
                textAreaProps={{
                  height: 'h-24',
                  onTextAreaChange: handleOnInputChange,
                }}
              />
            </div>
          </div>
        </>
      )}
      {cancelAppointmentLoading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
          <Loader />
        </div>
      )}
    </Modal>
  );
};

export default CancelAppointmentModalProvider;
