import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import {
  UpcomingAppointmentAction,
  UpcomingAppointmentsRowActionProps,
  appointmentActionRenderInfo,
} from '../interfaces/upcomingAppointments.interface';
import { useNavigate } from 'react-router-dom';
import { MY_APPOINTMENTS } from 'utilities/routes';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import ButtonComponent from '../../../components/button/buttonComponent';

export const UpcomingAppointmentsRowAction: React.FC<
  UpcomingAppointmentsRowActionProps
> = ({ action, appointmentId }) => {
  const navigate = useNavigate();
  const renderInfo = appointmentActionRenderInfo[action];

  const { data: genericAction } = useGetGenericActions({
    locale: 'en',
    genericActionId: [renderInfo.genericActionId],
  });

  if (!renderInfo) {
    console.error(`No render info for appointment action ${action}`);
    return null;
  }

  const Icon = renderInfo.icon;
  const text =
    genericAction &&
    genericAction[renderInfo.genericActionId][
      renderInfo.genericActionTextField
    ];

  const handleViewAppointmentDetails = () => {
    navigate(
      `${MY_APPOINTMENTS}?${PARAM_MODALS_IDENTIFIERS.APPOINTMENT_DETAILS_MODAL_PROVIDER_ID}=true`,
      {
        state: { appointmentId },
      },
    );
  };
  const handleCancelAppointment = () => {
    navigate(
      `${MY_APPOINTMENTS}?${PARAM_MODALS_IDENTIFIERS.CANCEL_APPOINTMENT_MODAL_PROVIDER_ID}=true`,
      {
        state: { appointmentId },
      },
    );
  };

  const handleOnClick = () => {
    switch (action) {
      case UpcomingAppointmentAction.View:
        handleViewAppointmentDetails();
        break;
      case UpcomingAppointmentAction.Cancel:
        handleCancelAppointment();
        break;
      default:
        console.error(`No action handler for appointment action ${action}`);
    }
  };

  return (
    <ButtonComponent
      type="underline"
      key={action}
      onClick={handleOnClick}
      className="uppercase font-bold text-sm py-2 justify-center items-center cursor-pointer"
      containerClassName="!gap-0 !justify-start"
    >
      <Icon className="min-w-[20px]" />
      <span className="ml-1.5">{text}</span>
    </ButtonComponent>
  );
};
