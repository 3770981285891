import { TestKitsTableData } from 'app/manage-test-kits/components/DNATestKitTable/interfaces';
import TableContent from 'components/codexTable/TableContent';
import {
  CustomClassNamesProps,
  KeyMapProps,
} from 'components/codexTable/interfaces/keymap.interfaces';
import { Record_Order_By } from 'components/codexTable/interfaces/record.interfaces';
import {
  Order_By,
  useGetProviderPatientSignedUrlMutation,
  useGetSignedUrlFromStorageMutation,
} from 'graphql/generated/hasura';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import TableContentMobile from 'components/codexTable/TableContentMobile';
import {
  DNATestKitTableProps,
  TestKitsResultsModalNavigationState,
  TestKitsTableDataWithPatientInfo,
} from '../interfaces/testKits.interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import { usePdfDownload } from 'hooks/usePdfDownload';
import { useGetFileFromStorage } from 'hooks/useGetFileFromStorage';
import { printPdf } from 'utilities/functions';
import {
  genericActionsIds,
  PARAM_MODALS_IDENTIFIERS,
} from 'utilities/constants';
import { AuthContext } from 'auth/context/AuthContext';
import { Roles } from '../../../firebase/interfaces';
import ButtonComponent from '../../../components/button/buttonComponent';
import { useGetGenericActions } from '../../../hooks/useGetGenericActions';

const keyMap: KeyMapProps = {
  date: {
    column: 'date',
    isActive: (orderBy: Record_Order_By) => !!orderBy.date,
    direction: (orderBy: Record_Order_By) =>
      (orderBy.date as Order_By) || Order_By.Desc,
  },
  'test type': {
    column: 'test type',
    isActive: (orderBy: Record_Order_By) => !!orderBy['test type'],
    direction: (orderBy: Record_Order_By) =>
      (orderBy['test type'] as Order_By) || Order_By.Desc,
  },
  testID: {
    column: 'testID',
    isActive: (orderBy: Record_Order_By) => !!orderBy.testID,
    direction: (orderBy: Record_Order_By) =>
      (orderBy.testID as Order_By) || Order_By.Desc,
  },
  provider: {
    column: 'provider',
    isActive: (orderBy: Record_Order_By) => !!orderBy.provider,
    direction: (orderBy: Record_Order_By) =>
      (orderBy.provider as Order_By) || Order_By.Desc,
  },
};

const customClasses: CustomClassNamesProps = {
  date: {
    customRowClassName: 'text-left pl-2.5 min-w-16 font-medium text-med-gray-3',
  },
  'test type': {
    customRowClassName: 'text-left pl-2.5 min-w-16 font-medium text-med-gray-3',
  },
  testID: {
    customRowClassName: 'text-left pl-2.5 min-w-16 font-medium text-med-gray-3',
  },
  provider: {
    customRowClassName: 'text-left pl-2.5 min-w-16 font-medium text-med-gray-3',
  },
  actions: {
    customRowClassName: 'text-left pl-2.5 w-10',
  },
};

const customClassesMobile: CustomClassNamesProps = {
  date: {
    spanClass: 'text-med-gray-3',
  },
  'test type': {
    spanClass: 'text-med-gray-3',
  },
  testID: {
    spanClass: 'text-med-gray-3',
  },
  provider: {
    spanClass: 'text-med-gray-3',
  },
};

const ActionsComponent: React.FC<TestKitsTableDataWithPatientInfo> = (
  current,
) => {
  const { user: loggedUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [pdfSrc, setPdfSrc] = useState<string>();
  const [getSignedUrlFromStorage] = useGetSignedUrlFromStorageMutation({});

  const { data: genericAction } = useGetGenericActions({
    locale: 'en',
    genericActionId: [
      genericActionsIds.VIEW,
      genericActionsIds.DOWNLOAD,
      genericActionsIds.PRINT,
    ],
  });

  const { fetchFileFromStorage, loading: printLoading } =
    useGetFileFromStorage();

  const [getProviderPatientsSignedUrl] = useGetProviderPatientSignedUrlMutation(
    {},
  );

  const { downloadPdf } = usePdfDownload();

  useEffect(() => {
    (async () => {
      if (current.patientPdfId) {
        try {
          if (loggedUser?.role === Roles.PROVIDER && patientId) {
            const url = await getProviderPatientsSignedUrl({
              variables: {
                fileId: current.patientPdfId,
                patientCodexId: patientId,
              },
            });
            if (url.data?.GetProviderPatientSignedUrl?.url) {
              setPdfSrc(url.data?.GetProviderPatientSignedUrl.url);
            }
          } else {
            const url = await getSignedUrlFromStorage({
              variables: { fileId: current.patientPdfId },
            });
            if (url.data?.GetSignUrlFormStorage?.url) {
              setPdfSrc(url.data?.GetSignUrlFormStorage?.url);
            }
          }
        } catch (error) {
          console.error('Error getting pdf signed url', error);
        }
      }
    })();
  }, [
    current.patientPdfId,
    patientId,
    loggedUser?.role,
    getSignedUrlFromStorage,
    getProviderPatientsSignedUrl,
  ]);

  const onEyeIconClick = () => {
    if (!pdfSrc) return;
    const state: TestKitsResultsModalNavigationState = {
      patientName: current.patientName,
      patientProfileImageSrc: current.patientProfileImageSrc,
      testPdfSrc: pdfSrc,
      testDate: new Date(current.date),
      testName: current['test type'],
    };
    navigate(`.?${PARAM_MODALS_IDENTIFIERS.TEST_KIT_RESULT_MODAL_ID}=true`, {
      state,
    });
  };
  const handleOnPrint = async () => {
    if (!current.patientPdfId || printLoading) return;
    try {
      const file = await fetchFileFromStorage(current.patientPdfId);
      printPdf(file);
    } catch (error) {
      console.error('Error getting pdf', error);
    }
  };
  const handleOnDownload = async () => {
    if (!current.patientPdfId) return;
    downloadPdf(current.patientPdfId);
  };

  return (
    <div className="flex max-mobile:!block items-center justify-around gap-2.5 desktop:justify-end">
      <ButtonComponent
        className="text-sm font-bold"
        type="underline"
        Icon={EyeIcon}
        iconPosition="left"
        containerClassName="!gap-1.5 max-mobile:justify-start"
        stroke="stroke-transparent"
        disabled={!pdfSrc}
        onClick={onEyeIconClick}
      >
        {genericAction?.[genericActionsIds.VIEW]?.view}
      </ButtonComponent>
      <ButtonComponent
        className="text-sm font-bold"
        type="underline"
        containerClassName="!gap-1.5 max-mobile:justify-start"
        Icon={DownloadIcon}
        stroke="stroke-transparent"
        iconPosition="left"
        disabled={!current.patientPdfId}
        onClick={handleOnDownload}
      >
        {genericAction?.[genericActionsIds.DOWNLOAD]?.download}
      </ButtonComponent>
      <ButtonComponent
        className="text-sm font-bold"
        containerClassName="!gap-1.5 max-mobile:justify-start"
        type="underline"
        Icon={PrintIcon}
        iconPosition="left"
        stroke="stroke-transparent"
        disabled={!current.patientPdfId || printLoading}
        onClick={handleOnPrint}
      >
        {genericAction?.[genericActionsIds.PRINT]?.print}
      </ButtonComponent>
    </div>
  );
};

const columnKeys: (keyof TestKitsTableData)[] = [
  'date',
  'test type',
  'testID',
  'provider',
];

export const DNATestKitTable: React.FC<DNATestKitTableProps> = ({
  data,
  locale,
  orderBy,
  setOrderBy,
}) => {
  const tableData = useMemo(
    () => data.map((d) => ({ ...d, id: d.id })),
    [data],
  );
  return (
    <>
      <div className="mt-[30px] desktop:flex desktop:flex-col">
        <TableContentMobile
          keyMap={keyMap}
          columnKeys={columnKeys}
          data={tableData}
          actions={(record: TestKitsTableDataWithPatientInfo) => (
            <ActionsComponent {...record} />
          )}
          customClasses={customClassesMobile}
          locale={locale}
          vertical
        />
        <TableContent
          columnKeys={columnKeys}
          data={tableData}
          keyMap={keyMap}
          orderBy={orderBy}
          onColumnSortChange={setOrderBy}
          customClasses={customClasses}
          actions={(record: TestKitsTableDataWithPatientInfo) => (
            <ActionsComponent {...record} />
          )}
          locale={locale}
          bottomBorder
        />
      </div>
    </>
  );
};

export default DNATestKitTable;
