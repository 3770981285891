import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import InputComponent from 'components/inputComponent';
import Loader from 'components/loaderComponent';
import { useGetGenericActions } from 'hooks/useGetGenericActions';
import { useGetPage } from 'hooks/useGetPage';
import {
  PARAM_MODALS_IDENTIFIERS,
  genericActionsIds,
  pageIds,
} from 'utilities/constants';
import AppointmentPreworkSymptoms from '../components/appointment-prework/AppointmentPreworkSymptoms';
import BodySymptomsPlacer from 'app/my-skin/components/BodySymtomsLocation';
import AppointmentPreworkRadioList from '../components/appointment-prework/AppointmentPreworkRadioList';
import { useModalParams } from 'components/modal/useModalManager';
import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/checkMini.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import {
  AppointmentMediaInsert,
  CreateFhirAppointmentInputWithoutPatientCodexId,
  useCreateFhirAppointmentMutation,
  useGetAppointmentByCodexAppointmentIdQuery,
  usePatchFhirAppointmentMutation,
} from 'graphql/generated/remote-schema-hasura';

import { AlertState, FhirAppointmentStatuses } from '../interfaces';
import { scrollToTop } from 'utilities/functions';
import { useLocation, useNavigate } from 'react-router-dom';
import { MY_APPOINTMENTS_SELECT_A_PROVIDER, QR_ACTION } from 'utilities/routes';
import { AcuityModal } from '../components/AcuityModal';
import { ResourcesTypes } from 'utilities/interfaces';
import Modal from 'components/modal/modalComponent';
import QRCode from 'react-qr-code';
import { useIsMobile } from 'hooks/useIsMobile';
import { ImageObject } from '../../my-skin/components/BodySymtomsLocation/interfaces';
import ModalUploadPhoto from '../../my-skin/components/ModalUploadPhoto';
import ModalPhotoLibrary from '../../my-skin/components/ModalPhotoLibrary';
import { getDirections } from '../../my-skin/components/BodySymtomsLocation/bodySections/HeadBodySection';
import { useGetFrontAndBackDots } from '../../my-skin/components/BodySymtomsLocation/hooks';
import { useGenerateCustomToken } from '../../../firebase/hooks';
import { useRootConfig } from '../../../utilities/rootConfig';

const AppointmentPreworkDesktop: React.FC = () => {
  const location = useLocation();
  const codexProviderId = location?.state?.codexProviderId;
  const calendarId = location?.state?.calendarId;
  const ownerId = location?.state?.ownerId;
  const diagnosticReportId = location?.state?.diagnosticReportId;
  const isMobile = useIsMobile();
  const { handleGenerateCustomToken, data } = useGenerateCustomToken();
  const { baseUrl } = useRootConfig();

  const [showMobileQrCode, setShowMobileQrCode] = useState<boolean>(false);
  const [isShowAddsPhotoModal, setIsShowAddsPhotoModal] =
    useState<boolean>(false);
  const [bodySymptomsPlacerLabel, setBodySymptomsPlacerLabel] =
    useState<string>('');
  const [isShowLibraryModal, setIsShowLibraryModal] = useState<boolean>(false);

  const handleMobileQrCodeClose = () => setShowMobileQrCode(false);

  const { data: locale, loading: localeLoading } = useGetPage({
    pageId: pageIds.APPOINTMENT_PREWORK,
    locale: 'en',
  });
  const { data: genericActions, loading: genericActionsLoading } =
    useGetGenericActions({
      locale: 'en',
      genericActionId: [
        genericActionsIds.BACK,
        genericActionsIds.CONTINUE,
        genericActionsIds.CANCEL,
      ],
    });

  const [createFhirAppointment, { loading: createAppointmentLoading }] =
    useCreateFhirAppointmentMutation({});
  const [patchFhirAppointment, { loading: patchAppointmentLoading }] =
    usePatchFhirAppointmentMutation();
  const editAppointmentId = location.state?.toEditAppointmentCodexId;
  const { data: editAppointmentData, loading: editAppointmentLoading } =
    useGetAppointmentByCodexAppointmentIdQuery({
      variables: {
        appointmentCodexId: editAppointmentId,
      },
      skip: !editAppointmentId,
    });
  const navigate = useNavigate();
  const [alert, setAlert] = useState<AlertState>();
  const [isContinuePressed, setIsContinuePressed] = useState<boolean>(false);
  const { backDots, frontDots, setBackDots, setFrontDots } =
    useGetFrontAndBackDots();
  const [symptoms, setSymptoms] = useState<string[]>([]);
  const [anythingElse, setAnythingElse] = useState<string>('');
  const [howLong, setHowLong] = useState<string>();
  const howLongItems = useMemo<string[]>(
    () => Object.values(locale?.howLong || {}),
    [locale],
  );
  const [severity, setSeverity] = useState<string>();
  const [subjectOfAppointment, setSubjectOfAppointment] = useState<string>();
  const [hasSymptonsValidationError, setHasSymptonsValidationError] =
    useState<boolean>(false);
  const [selectetdBodyPlace, setSelectedBodyPlace] = useState<number | null>(
    null,
  );
  const [mediaPerBodyLocation, setMediaPerBodyLocation] = useState<
    Map<string, ImageObject>
  >(new Map());

  const severityItems = useMemo<string[]>(
    () =>
      (Object.values(locale?.severity || {}) as string[]).sort((a, b) =>
        a.localeCompare(b),
      ) as string[],
    [locale],
  );
  const lastStartDate = useRef<Date>();
  const lastAppointmentCodexId = useRef<string>();
  const { isOpen: isAcuityModal } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.ACUITY_CALENDAR_MODAL,
  );
  const lastAppointmentStatus = useRef<FhirAppointmentStatuses>();

  const createAppointmentInput = useMemo<
    CreateFhirAppointmentInputWithoutPatientCodexId | undefined
  >(() => {
    const symptomsLocation = [...frontDots, ...backDots]
      .filter((dot) => dot.location && dot.selected)
      .map((dot) => dot.location as string);
    if (
      !subjectOfAppointment ||
      !howLong ||
      !severity ||
      hasSymptonsValidationError
    ) {
      return;
    }
    const status: FhirAppointmentStatuses =
      lastAppointmentStatus.current ?? 'pending';
    const mediaToInsert = [
      ...mediaPerBodyLocation.entries(),
    ].map<AppointmentMediaInsert>(([bodySite, { mediaId, description }]) => ({
      mediaId,
      description,
      bodySite,
    }));
    return {
      appointmentNotes: {
        anythingElseQuestion: anythingElse || '',
        howLongQuestion: howLong || '',
        severityQuestion: severity || '',
        symptomsTypes: symptoms || '',
      },
      symptomsLocation,
      mediaToInsert,
      startAt: (lastStartDate.current || new Date()).toISOString(),
      subjectOfAppointment,
      status,
      ...(diagnosticReportId && {
        supportingInfo: [
          `${ResourcesTypes.DIAGNOSTIC_REPORT}/${diagnosticReportId}`,
        ],
      }),
    };
  }, [
    anythingElse,
    howLong,
    severity,
    symptoms,
    frontDots,
    backDots,
    mediaPerBodyLocation,
    subjectOfAppointment,
    diagnosticReportId,
    hasSymptonsValidationError,
  ]);

  const token = useMemo(() => data?.GenerateCustomToken?.token, [data]);

  const switchToMobileModalButtons = [
    {
      label: genericActions?.[genericActionsIds.CANCEL].cancel,
      disabled: false,
      onClick: handleMobileQrCodeClose,
      type: 'outlined',
      testID: 'cancel-switch-to-mobile',
    },
  ];

  const onBodySymptomsPlacerClick = (
    label: string,
    _direction: string,
    bodyId: number,
  ) => {
    setBodySymptomsPlacerLabel(label);
    setSelectedBodyPlace(bodyId);
    setIsShowAddsPhotoModal(true);
  };

  const onConfirmUpload = useCallback(
    (url: string, id: string, description?: string) => {
      const direction = getDirections(frontDots, selectetdBodyPlace as number);
      const newBackDots = backDots.map((dot) => ({
        ...dot,
        selected: dot.id === selectetdBodyPlace || dot.selected,
      }));
      const newFrontDots = frontDots.map((dot) => ({
        ...dot,
        selected: dot.id === selectetdBodyPlace || dot.selected,
      }));

      if (direction === 'back') {
        setBackDots(newBackDots);
      } else {
        setFrontDots(newFrontDots);
      }

      setMediaPerBodyLocation((prev) => {
        const newMap = new Map(prev);
        if (selectetdBodyPlace) {
          newMap.set(selectetdBodyPlace.toString(), {
            image: url,
            mediaId: id,
            description: description || '',
          });
        }
        return newMap;
      });
      setIsShowLibraryModal(false);
    },
    [backDots, frontDots, selectetdBodyPlace, setBackDots, setFrontDots],
  );

  const addPhotoModalOnClose = () => {
    setIsShowAddsPhotoModal(false);
  };

  const onShowLibraryModal = () => {
    addPhotoModalOnClose();
    setIsShowLibraryModal(true);
  };

  const onAnythingElseChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setAnythingElse(e.target.value);
  const onSubjectOfAppointmentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setSubjectOfAppointment(e.target.value);
  const onContinue = async () => {
    scrollToTop();
    if (!createAppointmentInput) {
      setIsContinuePressed(true);
      setAlert({
        message: locale?.fillRequredError,
        type: 'negative',
      });
      return;
    }
    try {
      let appointmentCodexId: string | undefined;
      if (lastAppointmentCodexId.current) {
        await patchFhirAppointment({
          variables: {
            appointmentInformation: {
              appointmentCodexId: lastAppointmentCodexId.current,
              ...createAppointmentInput,
            },
          },
        });
        appointmentCodexId = lastAppointmentCodexId.current;
      } else {
        const result = await createFhirAppointment({
          variables: {
            appointmentPrework: createAppointmentInput,
          },
        });
        appointmentCodexId =
          result.data?.createFHIRAppointmentForCurrentUser.appointment
            .appointmentCodexId;
      }
      if (
        appointmentCodexId &&
        codexProviderId &&
        calendarId &&
        ownerId &&
        diagnosticReportId
      ) {
        // flow comes from zrt test kit registration where provider was already selected
        navigate(`.?${PARAM_MODALS_IDENTIFIERS.ACUITY_CALENDAR_MODAL}=true`, {
          state: {
            codexProviderId,
            appointmentCodexId,
            calendarId,
            ownerId,
          },
        });
      } else if (
        appointmentCodexId &&
        codexProviderId &&
        calendarId &&
        ownerId
      ) {
        // flow comes from my providers
        navigate(`.?${PARAM_MODALS_IDENTIFIERS.ACUITY_CALENDAR_MODAL}=true`, {
          state: {
            codexProviderId,
            appointmentCodexId,
            calendarId,
            ownerId,
          },
        });
      } else if (editAppointmentData) {
        // Flow comes from edit appointment
        navigate(-1);
      } else {
        navigate(MY_APPOINTMENTS_SELECT_A_PROVIDER, {
          state: { appointmentCodexId },
        });
      }
    } catch (error) {
      setAlert({
        message: locale?.errorSavingAppointment,
        type: 'negative',
      });
    }
  };

  useEffect(() => {
    if (
      editAppointmentData?.getFHIRAppointmentByCodexId?.appointment &&
      frontDots?.length &&
      backDots?.length &&
      !lastAppointmentCodexId.current
    ) {
      const lastAppointment =
        editAppointmentData.getFHIRAppointmentByCodexId.appointment;
      lastAppointmentCodexId.current = lastAppointment.appointmentCodexId;
      lastStartDate.current = new Date(lastAppointment.start || 0);
      lastAppointment.subjectOfAppointment &&
        setSubjectOfAppointment(lastAppointment.subjectOfAppointment);
      lastAppointment.appointmentNotes?.anythingElseQuestion &&
        setAnythingElse(lastAppointment.appointmentNotes?.anythingElseQuestion);
      lastAppointment.appointmentNotes?.howLongQuestion &&
        setHowLong(lastAppointment.appointmentNotes?.howLongQuestion);
      lastAppointment.appointmentNotes?.severityQuestion &&
        setSeverity(lastAppointment.appointmentNotes?.severityQuestion);
      lastAppointment.appointmentNotes?.symptomsTypes &&
        setSymptoms(lastAppointment.appointmentNotes?.symptomsTypes);
      lastAppointmentStatus.current =
        lastAppointment.status as FhirAppointmentStatuses;
      if (lastAppointment.symptomsLocation && lastAppointment.media?.length) {
        const mediaMap = lastAppointment.media.reduce<Map<string, ImageObject>>(
          (acc, media) => {
            if (media.bodySite && media.fileId) {
              acc.set(media.bodySite, {
                image: media.url,
                mediaId: media.fileId,
              });
            }
            return acc;
          },
          new Map(),
        );
        const symptomsLocationSet = new Set(
          lastAppointment.symptomsLocation.filter((location) =>
            mediaMap.has(location),
          ),
        );
        if (!symptomsLocationSet.size || !mediaMap.size) {
          return;
        }
      }
    }
  }, [
    editAppointmentData?.getFHIRAppointmentByCodexId.appointment,
    frontDots.length,
    backDots.length,
    setFrontDots,
    setBackDots,
  ]);

  const handleBackButton = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!isMobile) {
      setShowMobileQrCode(true);
    }
  }, [isMobile]);

  useEffect(() => {
    handleGenerateCustomToken();
    const interval = setInterval(() => {
      handleGenerateCustomToken();
    }, 55 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [handleGenerateCustomToken]);

  if (
    !locale ||
    localeLoading ||
    !genericActions ||
    genericActionsLoading ||
    editAppointmentLoading
  ) {
    return <Loader />;
  }

  const isAppointmentSaving =
    createAppointmentLoading || patchAppointmentLoading;
  return (
    <>
      {isAcuityModal && <AcuityModal />}

      <Modal
        isOpen={showMobileQrCode}
        title={locale.thisExperienceIsBetterInMobileTitle}
        onClose={handleMobileQrCodeClose}
        buttons={switchToMobileModalButtons}
      >
        <div
          className="flex flex-col gap-3 justify-center items-center px-32"
          data-testid="switch-to-mobile-modal"
        >
          <p className="text-base font-medium text-med-gray text-center">
            {locale.thisExperienceIsBetterInMobile}
          </p>
          <QRCode
            size={300}
            className="self-center"
            value={`${baseUrl}${QR_ACTION}?action=scanAppointmentsPage&token=${token}`}
          />
        </div>
      </Modal>

      <ModalUploadPhoto
        isOpen={isShowAddsPhotoModal}
        onClose={addPhotoModalOnClose}
        bodySymptom={bodySymptomsPlacerLabel}
        qrCode={`${baseUrl}${QR_ACTION}?action=scanAppointmentsPage&token=${token}`}
        onShowLibraryModal={onShowLibraryModal}
        onUploadPhoto={onConfirmUpload}
      />

      <ModalPhotoLibrary
        isOpen={isShowLibraryModal}
        onClose={() => setIsShowLibraryModal(false)}
        bodySymptom={bodySymptomsPlacerLabel}
        onConfirm={onConfirmUpload}
      />

      <div className="px-7 pt-[30px] desktop:pt-0">
        <div className="flex flex-col gap-4 desktop:gap-0">
          <div className="flex flex-col desktop:flex-row w-full justify-between items-start p-0 gap-2.5 desktop:gap-[30px] desktop:mb-[34px]">
            <div className="flex flex-1 flex-col items-start gap-4">
              <ButtonComponent
                type="underline"
                Icon={ChevronLeftIcon}
                iconPosition="left"
                iconWidth="w-2.5"
                iconHeight="h-[18px]"
                fullWidthClassName=""
                onClick={handleBackButton}
              >
                <p className="uppercase">
                  {genericActions?.[genericActionsIds.BACK].back}
                </p>
              </ButtonComponent>
              {alert && (
                <div
                  className={`self-stretch flex flex-row items-center px-3 py-2 rounded-10 gap-3 bg-${alert.type}`}
                >
                  {alert.type === 'positive' ? (
                    <CheckIcon className="w-4 h-2.5" />
                  ) : (
                    <WarningIcon className="w-6 h-6 fill-alert-negative" />
                  )}
                  <p
                    className={`font-semibold text-alert-${alert.type} text-base`}
                  >
                    {alert.message}
                  </p>
                </div>
              )}
              <p
                className="text-h2 text-dark-gray font-medium desktop:text-h1"
                data-testid="appointment-per-work-page"
              >
                {locale.title}
              </p>
            </div>
          </div>
          <div
            className={`bg-white flex flex-col rounded-10 desktop:mx-10 px-12 py-6 text-dark-gray font-semibold text-base gap-8 ${
              isAppointmentSaving ? 'opacity-50 pointer-events-none' : ''
            }`}
          >
            <p>{locale.preworkDescription}</p>
            <div className="flex flex-col desktop:flex-row gap-2 desktop:gap-4">
              <div className="flex flex-col items-start">
                <span className="text-dark-gray font-semibold text-base">
                  {locale.subjectOfAppointment}
                  <span className="text-clc-red">*</span>
                </span>
                <span className="text-med-gray font-semibold text-sm desktop:w-max">
                  {locale.subjectOfAppointmentSubtitle}
                </span>
              </div>
              <div className="w-full">
                <InputComponent
                  type="text"
                  value={subjectOfAppointment}
                  onChange={onSubjectOfAppointmentChange}
                  errorMsgWithIcon={
                    !subjectOfAppointment && isContinuePressed
                      ? locale?.fillRequredError
                      : null
                  }
                  errorStatus={!subjectOfAppointment && isContinuePressed}
                  testID="subject-of-appointment-input"
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col desktop:flex-row desktop:items-end desktop:gap-2">
                <span className="text-dark-gray font-semibold text-base">
                  {locale.symptomTypes}
                </span>
                <span className="text-med-gray font-semibold text-sm desktop:w-max">
                  {locale.selectAllThatApply}
                </span>
              </div>
              <AppointmentPreworkSymptoms
                setSymptoms={setSymptoms}
                symptoms={symptoms}
                isContinueButtonTriggered={isContinuePressed}
                setError={setHasSymptonsValidationError}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-end gap-2">
                <span className="text-dark-gray font-semibold text-base">
                  {locale.howLongTitle}
                  <span className="text-clc-red">*</span>
                </span>
              </div>
              <AppointmentPreworkRadioList
                selected={howLong}
                setSelected={setHowLong}
                items={howLongItems}
                errorState={
                  !howLong && isContinuePressed
                    ? locale?.mustChooseOneOption
                    : null
                }
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-end gap-2">
                <span className="text-dark-gray font-semibold text-base">
                  {locale.severityQuestion}
                  <span className="text-clc-red">*</span>
                </span>
              </div>
              <AppointmentPreworkRadioList
                selected={severity}
                setSelected={setSeverity}
                items={severityItems}
                errorState={
                  !severity && isContinuePressed
                    ? locale?.mustChooseOneOption
                    : null
                }
              />
            </div>
            <BodySymptomsPlacer
              noForm
              backDots={backDots}
              frontDots={frontDots}
              mediaPerBodyLocation={mediaPerBodyLocation}
              setBackDots={setBackDots}
              setFrontDots={setFrontDots}
              onClick={onBodySymptomsPlacerClick}
            />
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-end gap-2">
                <span className="text-dark-gray font-semibold text-base">
                  {locale.anythingElseQuestion}
                </span>
              </div>
              <InputComponent
                name="summaryDiagnosis"
                textAreaProps={{
                  onTextAreaChange: onAnythingElseChange,
                }}
                type="text-area"
                value={anythingElse}
                testID="anything-else-input"
              />
            </div>
            <div className="flex flex-row justify-end items-center pt-5 border-t-2 border-card-border-color">
              <ButtonComponent
                onClick={onContinue}
                type="filled"
                testID="symptoms-confirm-button"
              >
                {genericActions[genericActionsIds.CONTINUE].continue}
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentPreworkDesktop;
