import { ZRT_TEST_KIT_MAX_LENGTH } from 'app/my-skin/pages/constants';
import {
  Swab_Code_Statuses_Enum,
  Test_Types_Enum,
  useGetSwabCodeValidBySwabcodeLazyQuery,
} from 'graphql/generated/hasura';
import { useCallback, useEffect, useState } from 'react';
import {
  REMOVE_WHITE_SPACES_REGEX,
  TEST_SKIN_VALIDATION_REGEX,
  TEST_VITAMIN_VALIDATION_REGEX,
  alphanumericsDashAndSpace,
  validCOSkinSwabCodesRegex,
  validCOVitaminSwabCodesRegex,
} from 'utilities/variables';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { CodexFeatureFlags } from 'utilities/interfaces';

interface Props {
  testType: Test_Types_Enum;
  testKitIdNumber: string;
}

const testSkinValidationRegex = new RegExp(TEST_SKIN_VALIDATION_REGEX, 'i');
const testVitaminValidationRegex = new RegExp(
  TEST_VITAMIN_VALIDATION_REGEX,
  'i',
);
const removeWhiteSpacesRegex = new RegExp(REMOVE_WHITE_SPACES_REGEX, 'g');

interface FormatedFields {
  newTestType: Test_Types_Enum;
  formatedValue: string;
}

export const useFormatAndValidateDNATestKitsIDNumberByType = ({
  testType,
  testKitIdNumber,
}: Props) => {
  const [inputErrors, setInputErrors] = useState({
    testKitIdNumber: false,
    testKitIdNumberStatus: '',
  });
  const { zrtTestKitsFlowDisabled } = useFlags<CodexFeatureFlags>();

  const [getValidSwabcodeBySwabcode] = useGetSwabCodeValidBySwabcodeLazyQuery({
    fetchPolicy: 'network-only',
  });

  const validateNumbersFormatByType = async (
    value: string,
    name: string,
  ): Promise<FormatedFields> => {
    if (name === 'testKitIdNumber') {
      let newTestType: Test_Types_Enum = testType;
      const uppercaseValue = value.toUpperCase();
      if (alphanumericsDashAndSpace.test(value)) {
        if (uppercaseValue.includes('CS')) {
          newTestType = Test_Types_Enum.DnaSkin;
        } else if (uppercaseValue.includes('CV')) {
          newTestType = Test_Types_Enum.DnaVitamin;
        } else if (
          uppercaseValue.includes('C0') ||
          uppercaseValue.includes('CO')
        ) {
          const validCOSwabCode = uppercaseValue.match(
            validCOSkinSwabCodesRegex,
          );
          if (validCOSwabCode) {
            newTestType = Test_Types_Enum.DnaSkin;
          }
          const validCOVitaminSwabCode = uppercaseValue.match(
            validCOVitaminSwabCodesRegex,
          );
          if (validCOVitaminSwabCode) {
            newTestType = Test_Types_Enum.DnaVitamin;
          }
        }
        /* DNA skin type */
        const skinTypeMatch = testSkinValidationRegex.exec(value)?.groups;
        if (skinTypeMatch) {
          const { CO, CS, C0 } = skinTypeMatch;
          setInputErrors((prevState) => ({
            ...prevState,
            [name]: false,
          }));
          if (CS) {
            return {
              formatedValue: uppercaseValue,
              newTestType,
            };
          }
          if (CO || C0) {
            uppercaseValue.replace('0', 'O');
            const validCOSwabCode = uppercaseValue.match(
              validCOSkinSwabCodesRegex,
            );
            if (!validCOSwabCode) {
              setInputErrors((prevState) => ({
                ...prevState,
                [name]: true,
              }));
            }
            return {
              formatedValue: uppercaseValue,
              newTestType,
            };
          }
        }

        const vitaminTypeMatch =
          testVitaminValidationRegex.exec(uppercaseValue)?.groups;
        if (vitaminTypeMatch) {
          const { CO, CV, C0 } = vitaminTypeMatch;
          setInputErrors((prevState) => ({
            ...prevState,
            [name]: false,
          }));
          if (CV) {
            return {
              formatedValue: uppercaseValue,
              newTestType,
            };
          }
          if (CO || C0) {
            uppercaseValue.replace('0', 'O');
            const validCOSwabCode = uppercaseValue.match(
              validCOVitaminSwabCodesRegex,
            );
            if (!validCOSwabCode) {
              setInputErrors((prevState) => ({
                ...prevState,
                [name]: true,
              }));
            }
            return {
              formatedValue: uppercaseValue,
              newTestType,
            };
          }
        }

        const zrtTestKitWithoutSpaces = uppercaseValue.replace(
          removeWhiteSpacesRegex,
          '',
        );
        if (
          zrtTestKitWithoutSpaces.length === ZRT_TEST_KIT_MAX_LENGTH &&
          !zrtTestKitWithoutSpaces.startsWith('CS') &&
          !zrtTestKitWithoutSpaces.startsWith('CV') &&
          !zrtTestKitWithoutSpaces.startsWith('CO') &&
          !zrtTestKitWithoutSpaces.startsWith('C0') &&
          !zrtTestKitsFlowDisabled
        ) {
          const { data } = await getValidSwabcodeBySwabcode({
            variables: {
              swabcode: zrtTestKitWithoutSpaces,
            },
          });
          if (data && data.GetSwabCodeValidBySwabcode?.isValid) {
            setInputErrors((prevState) => ({
              ...prevState,
              [name]: false,
            }));
            return {
              formatedValue: zrtTestKitWithoutSpaces,
              newTestType: data.GetSwabCodeValidBySwabcode
                .test_type as Test_Types_Enum,
            };
          }
          setInputErrors((prevState) => ({
            ...prevState,
            [name]: true,
          }));
          return {
            formatedValue: zrtTestKitWithoutSpaces,
            newTestType: data?.GetSwabCodeValidBySwabcode
              ?.test_type as Test_Types_Enum,
          };
        }

        setInputErrors((prevState) => ({
          ...prevState,
          [name]: true,
        }));
        return {
          formatedValue: uppercaseValue,
          newTestType,
        };
      } else {
        return {
          formatedValue: testKitIdNumber,
          newTestType,
        };
      }
    }
    return {
      formatedValue: value,
      newTestType: testType,
    };
  };

  const revalidateFormat = useCallback(async () => {
    switch (testType) {
      case Test_Types_Enum.DnaSkin: {
        if (
          testKitIdNumber.startsWith('CO') ||
          testKitIdNumber.startsWith('C0')
        ) {
          const validCOSwabCode = testKitIdNumber.match(
            validCOSkinSwabCodesRegex,
          );
          if (!validCOSwabCode) {
            return setInputErrors((prevState) => ({
              ...prevState,
              testKitIdNumber: true,
              testKitIdNumberStatus: '',
            }));
          }
        }
        const matchSkin = testSkinValidationRegex.exec(testKitIdNumber)?.groups;
        if (matchSkin) {
          return setInputErrors((prevState) => ({
            ...prevState,
            testKitIdNumber: false,
            testKitIdNumberStatus: '',
          }));
        }

        return setInputErrors((prevState) => ({
          ...prevState,
          testKitIdNumber: true,
          testKitIdNumberStatus: '',
        }));
      }

      case Test_Types_Enum.DnaVitamin: {
        if (
          testKitIdNumber.startsWith('CO') ||
          testKitIdNumber.startsWith('C0')
        ) {
          const validCOSwabCode = testKitIdNumber.match(
            validCOVitaminSwabCodesRegex,
          );
          if (!validCOSwabCode) {
            return setInputErrors((prevState) => ({
              ...prevState,
              testKitIdNumber: true,
              testKitIdNumberStatus: '',
            }));
          }
        }
        const matchVitamin =
          testVitaminValidationRegex.exec(testKitIdNumber)?.groups;
        if (matchVitamin) {
          return setInputErrors((prevState) => ({
            ...prevState,
            testKitIdNumber: false,
            testKitIdNumberStatus: '',
          }));
        }

        return setInputErrors((prevState) => ({
          ...prevState,
          testKitIdNumber: true,
          testKitIdNumberStatus: '',
        }));
      }

      case Test_Types_Enum.ZrtHeavyMetals:
      case Test_Types_Enum.ZrtHormone:
      case Test_Types_Enum.ZrtInflammatory:
      case Test_Types_Enum.ZrtNeurotransmitters: {
        if (!testKitIdNumber) break;
        const { data } = await getValidSwabcodeBySwabcode({
          variables: {
            swabcode: testKitIdNumber,
          },
        });
        if (data && data.GetSwabCodeValidBySwabcode?.isValid) {
          const databaseTestKitType = data.GetSwabCodeValidBySwabcode
            .test_type as Test_Types_Enum;
          if (databaseTestKitType === testType) {
            return setInputErrors((prevState) => ({
              ...prevState,
              testKitIdNumber: false,
              testKitIdNumberStatus: '',
            }));
          }
        }

        if (
          data &&
          data.GetSwabCodeValidBySwabcode?.status ===
            Swab_Code_Statuses_Enum.Used
        ) {
          return setInputErrors((prevState) => ({
            ...prevState,
            testKitIdNumber: true,
            testKitIdNumberStatus: Swab_Code_Statuses_Enum.Used,
          }));
        }

        return setInputErrors((prevState) => ({
          ...prevState,
          testKitIdNumber: true,
          testKitIdNumberStatus: '',
        }));
      }

      default:
        console.log(`Unexpected test type: ${testType}`);
        break;
    }
  }, [testType, testKitIdNumber, setInputErrors, getValidSwabcodeBySwabcode]);

  useEffect(() => {
    revalidateFormat().catch((error) =>
      console.log('something went wrong revalidating the format', error),
    );
  }, [revalidateFormat]);

  return { inputErrors, setInputErrors, validateNumbersFormatByType };
};
